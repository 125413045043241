import { Link } from "react-router-dom";
import { ThirdButton } from "./Buttons/Buttons";
import Icon from "./Icons/Icon";

const Column = ({ title, text, img, link, icon, type, textColor }) => {
  return (
    <div className='columnsOrder'>
      {img && <img src={img} className='columnImg' alt='' />}
      <br />
      {type === "icon" ? (
        <Icon icon={icon} />
      ) : (
        <img src={icon} style={{ width: "45px", height: "45px" }} />
      )}
      <h3
        style={{ margin: "1em 0 0.5em 0", fontWeight: "800", color: textColor }}
      >
        {title}
      </h3>
      <p style={{ marginBottom: "1em", color: textColor }}>{text}</p>
      {link && (
        <Link to={link}>
          <ThirdButton text='Read more' noPadding color='var(--primaryGreen)' />
        </Link>
      )}
    </div>
  );
};

export default Column;
