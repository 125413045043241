import { Link } from "react-router-dom";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";
import { useEffect } from "react";

function NoMatch() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Nav />
      <div style={{ height: "70vh" }}>
        <div
          style={{
            height: "90vh",
            backgroundImage:
              "url(https://images.pexels.com/photos/4488654/pexels-photo-4488654.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            padding: "5em 7vw 0",
          }}
        >
          <div style={{ position: "relative", display: "inline" }}>
            <div
              style={{
                position: "absolute",
                right: "-40px",
                bottom: "-50px",
                width: "50px",
                height: "50px",
                backgroundColor: "var(--primaryGreen)",
                zIndex: "2",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                right: "-90px",
                bottom: "-30px",
                width: "50px",
                height: "50px",
                backgroundColor: "var(--secondaryBlue)",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                right: "-20px",
                bottom: "-10px",
                width: "50px",
                height: "50px",
                backgroundColor: "var(--secondaryBlue)",
              }}
            ></div>

            <h1 className='textBackground' style={{ zIndex: "10" }}>
              Page in
            </h1>
          </div>
          <br />

          <h1 className='textBackground' style={{ zIndex: "10" }}>
            construction
          </h1>
          <div>
            <p className='textBackground' style={{ padding: " 1em 1.5em 1em" }}>
              The page you are looking for is still in development or does not
              exist
            </p>
          </div>
          <div style={{ marginTop: "2em" }}>
            <Link to='/'>
              <PrimaryButton
                text='HOMEPAGE'
                color='var(--primaryGreen)'
                textColor='var(--black)'
              />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default NoMatch;
