import { SecondaryButton } from "../../Components/Buttons/Buttons";
import { Trans } from "react-i18next";

const SingleProject = ({ data }) => {
  const { name, image, image1, text, amount, date, link } = { ...data };
  return (
    <a href={link} target="_blank" rel="noreferrer" className="projectContent">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={`${image}`} style={{ width: "60%" }} />
      </div>
      <div className="SmallMediaText">
        <h3 style={{ marginBottom: "1em" }}>{name}</h3>
        <p style={{ marginBottom: "1em" }}>
          <Trans i18nKey="description.project14" />
          <b>{amount}</b>
        </p>
        <p style={{ margin: "1em 0" }}>
          <Trans i18nKey="description.project15" /> <b>{date}</b>
        </p>
        <p style={{ marginBottom: "1em" }}>{text}</p>
        <div className="SmallMediaTextButton">
          <a href={link} target="_blank" rel="noopener">
            <SecondaryButton
              text={<Trans i18nKey="description.readmore" />}
              color="var(--primaryGreen)"
              textColor="var(--primaryGreen)"
            />
          </a>
        </div>
      </div>
    </a>
  );
};

export default SingleProject;
