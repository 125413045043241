import {useEffect, useState} from 'react';

function IsMobile () {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
           setWidth(window.innerWidth);
       }
    useEffect(() => {
           window.addEventListener('resize', handleWindowSizeChange);
           return () => {
               window.removeEventListener('resize', handleWindowSizeChange);
           }
       }, []);
    //    switch (width){
    //        case (width <= 768):
    //            return mobile
    //         default:
    //             console.log("out of case")
    //    }
       let mobile = (width <= 768)
       return mobile
}

export default IsMobile;