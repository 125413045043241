import { useEffect, useRef } from "react";
const SocialProofTab = ({ companies, bg, id }) => {
  const tab = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (tab.current.scrollLeft === null) {
      } else {
        setInterval(() => {
          if (tab.current === null) {
            return false;
          }
          tab.current.scrollLeft += 1;
          const scrollRemaining = tab.current.scrollWidth - window.innerWidth;
          if (tab.current.scrollLeft === scrollRemaining) {
            setTimeout(() => {
              tab.current.scrollLeft = 0;
            }, 500);
          }
        }, 80);
      }
    }, 1000);
  }, []);

  return (
    <div className="socialProofTab" id="socialProof" style={{ background: bg }}>
      <div className="socialProofTab" id={id} ref={tab}>
        {companies.map((company, key) => {
          return company.link === false ? (
            <img
              key={key}
              src={company.img}
              height="40px"
              style={{ margin: "0 3.5vw" }}
            />
          ) : (
            <a href={company.link} rel="noreferrer" target="_blank" key={key}>
              <img
                key={key}
                src={company.img}
                height="40px"
                style={{ margin: "0 3.5vw" }}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SocialProofTab;
