import { PrimaryButton, ThirdButton } from "../Components/Buttons/Buttons";
import { HashLink as Link } from "react-router-hash-link";
import IsMobile from "../Utils/IsMobile";
import IconTypes from "./Icons/IconTypes";
import { useState } from "react";
import Icon from "./Icons/Icon";
import "./Nav.css";
import { useTranslation, Trans } from "react-i18next";
import LanguageSwitch from "../Translation/LanguageSwitch";

const language = window.localStorage.getItem("i18nextLng");

const fleet_saver =
  language === "en"
    ? "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/FleetSaverENGred.pdf"
    : "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/LeafletPL.pdf";
const MobileNav = () => {
  const [menu, setMenu] = useState(false);

  const lngs = {
    en: { nativeName: "English" },
    pl: { nativeName: "Polski" },
  };

  const toggleMenu = (e) => {
    console.log("toggleMenu:", e.target.attributes);
  };
  const [active, setActive] = useState({
    Products: false,
    Use_Cases: false,
    Features: false,
  });

  function updateActive(tab) {
    if (tab === "Products") {
      setActive({
        ...active,
        Products: !active.Products,
        Use_Cases: false,
        Features: false,
        Company: false,
      });
    }
    if (tab === "Use_Cases") {
      setActive({
        ...active,
        Products: false,
        Use_Cases: true,
        Features: false,
        Company: false,
      });
    }
    if (tab === "Features") {
      setActive({
        ...active,
        Products: false,
        Use_Cases: false,
        Features: true,
        Company: false,
      });
    }
    if (tab === "Company") {
      setActive({
        ...active,
        Products: false,
        Use_Cases: false,
        Features: false,
        Company: true,
      });
    }
  }

  return (
    <header className='nav navDark'>
      <Link to='/'>
        <div className='logo'>
          <img
            src='https://res.cloudinary.com/efer/image/upload/v1661602133/VivaDrive/Logos/VivaDrive/Group_434_mycglk.svg'
            alt='logo'
          />
        </div>
      </Link>

      <div
        onClick={() => setMenu(!menu)}
        className={`burger ${menu && "burger--open"}`}
        style={{
          width: "25px",
          height: "25px",
          display: "grid",
          gridTemplateRows: "1fr 1fr 1fr",
          gap: "5px",
        }}
      >
        <div className='burgerTopLine'>
          <div
            style={{ backgroundColor: "var(--primaryGreen)" }}
            className='burger--lines burger--greenLine'
          >
            {" "}
          </div>
          <div
            style={{ backgroundColor: "var(--white)" }}
            className='burger--lines burger--blackLine--hide'
          >
            {" "}
          </div>
        </div>
        <div
          style={{ backgroundColor: "var(--white)" }}
          className='burger--lines burger--blackLine--hide'
        ></div>
        <div
          style={{ backgroundColor: "var(--white)" }}
          className='burger--lines burger--blackLine'
        ></div>
      </div>

      <div className={`mobileMenu ${menu ? "mobileMenu--active" : ""}`}>
        <div
          style={{
            display: "grid",
            alignContent: "center",
            height: "100%",
            gap: "1em",
            padding: "0 5vw",
          }}
        >
          <div data-tab='Product' onClick={(e) => toggleMenu(e)}>
            <div
              className='hoverItem'
              style={{
                position: "relative",
                borderBottom: ".5px solid var(--primaryGreen)",
              }}
            >
              <p
                style={{ fontSize: "2em", padding: ".2em", color: "white" }}
                className={active.Products ? "activeTab" : undefined}
                onClick={() => updateActive("Products")}
              >
                <Trans i18nKey='description.nav1' />
                <svg
                  style={{
                    transform: active.Products
                      ? "rotateZ(-90deg)"
                      : "rotateZ(90deg)",
                  }}
                  width='20px'
                  height='20px'
                  viewBox='0 0 20 20'
                  version='1.1'
                >
                  <title>Arrow Icon</title>
                  <g
                    id='Artboard'
                    stroke='none'
                    strokeWidth='1'
                    fill='none'
                    fillRule='evenodd'
                    strokeLinecap='round'
                  >
                    <polyline
                      id='arrowTip'
                      stroke={
                        active.Products ? "var(--primaryGreen)" : "var(--white)"
                      }
                      strokeWidth='2'
                      strokeLinejoin='round'
                      points='14 7 19.172087 11.7408396 14 16.3963533'
                    ></polyline>
                    <line
                      x1='16.9237849'
                      y1='11.7480177'
                      x2='8.99999993'
                      y2='11.75'
                      id='arrowLine'
                      strokeLinejoin='round'
                      stroke={
                        active.Products ? "var(--primaryGreen)" : "var(--white)"
                      }
                      strokeWidth='2'
                    ></line>
                  </g>
                </svg>
              </p>
            </div>
            {active.Products && (
              <div style={{ paddingTop: ".5em" }}>
                {SecondMenuContent[0].items.map((item) => {
                  return (
                    <a href={item.link}>
                      <p
                        style={{
                          marginLeft: "1em",
                          padding: ".5em 0",
                          color: "white",
                        }}
                      >
                        {item.name}
                      </p>
                    </a>
                  );
                })}
              </div>
            )}
          </div>

          <div>
            <div
              className='hoverItem'
              style={{
                position: "relative",
                borderBottom: ".5px solid var(--primaryGreen)",
              }}
            >
              <p
                style={{ fontSize: "1.5em", padding: ".2em", color: "white" }}
                className={active.Company ? "activeTab" : undefined}
                onClick={() => updateActive("Company")}
              >
                <Trans i18nKey='description.nav2' />
                <svg
                  style={{
                    transform: active.Company
                      ? "rotateZ(-90deg)"
                      : "rotateZ(90deg)",
                  }}
                  width='20px'
                  height='20px'
                  viewBox='0 0 20 20'
                  version='1.1'
                >
                  <title>Arrow Icon</title>
                  <g
                    id='Artboard'
                    stroke='none'
                    strokeWidth='1'
                    fill='none'
                    fillRule='evenodd'
                    strokeLinecap='round'
                  >
                    <polyline
                      id='arrowTip'
                      stroke={
                        active.Company ? "var(--primaryGreen)" : "var(--white)"
                      }
                      strokeWidth='2'
                      strokeLinejoin='round'
                      points='14 7 19.172087 11.7408396 14 16.3963533'
                    ></polyline>
                    <line
                      x1='16.9237849'
                      y1='11.7480177'
                      x2='8.99999993'
                      y2='11.75'
                      id='arrowLine'
                      strokeLinejoin='round'
                      stroke={
                        active.Company ? "var(--primaryGreen)" : "var(--white)"
                      }
                      strokeWidth='2'
                    ></line>
                  </g>
                </svg>
              </p>
            </div>
            {active.Company && (
              <div style={{ paddingTop: ".5em" }}>
                {SecondMenuContent[3].items.map((item) => {
                  return (
                    <a href={item.link} target={item.target}>
                      <p
                        style={{
                          marginLeft: "1em",
                          padding: ".5em 0",
                          color: "white",
                        }}
                      >
                        {item.name}
                      </p>
                    </a>
                  );
                })}
              </div>
            )}
          </div>

          <Link to='/work-with-us' onClick={() => setMenu(!menu)}>
            <ThirdButton
              text='Contact us'
              noPadding
              color='var(--primaryGreen)'
            />
          </Link>
          <LanguageSwitch />
        </div>
      </div>
    </header>
  );
};

const mountSecondMenu = (tab, setState, setMenuContent, active, setActive) => {
  setMenuContent(SecondMenuContent.find((o) => o.tab === tab));
  setState(true);

  if (tab === "Products") {
    setActive({ ...active, Products: true, Use_Cases: false, Features: false });
  }
  if (tab === "Use_Cases") {
    setActive({ ...active, Products: false, Use_Cases: true, Features: false });
  }
  if (tab === "Features") {
    setActive({ ...active, Products: false, Use_Cases: false, Features: true });
  }
};

const MouseLeave = (active, setActive, setSubMenu) => {
  setSubMenu(false);
  setActive({ ...active, Products: false, Use_Cases: false, Features: false });
};

const DesktopNav = (props) => {
  const { borderBottom } = { ...props };
  const [subMenu, setSubMenu] = useState(false);
  const [MenuContent, setMenuContent] = useState();
  const [active, setActive] = useState({
    Products: false,
    Use_Cases: false,
    Features: false,
  });
  const lngs = {
    en: { nativeName: "EN" },
    pl: { nativeName: "PL" },
  };
  const { t, i18n } = useTranslation();

  return (
    <div style={{ position: "fixed", width: "100%", zIndex: "100" }}>
      <header
        className='nav navLight'
        onMouseLeave={() => MouseLeave(active, setActive, setSubMenu)}
      >
        <Link to='/'>
          <div className='logo'>
            <img
              src='https://res.cloudinary.com/efer/image/upload/v1661598773/VivaDrive/Logos/VivaDrive/Logo_Dark_di3jlm.png'
              alt='logo'
              className='NavLogoImg'
            />
          </div>
        </Link>

        <div className='links'>
          <Link to='/products/evrecommend'>
            <div className='hoverItem' style={{ position: "relative" }}>
              <p
                style={{
                  color: active.Products ? "var(--secondaryGreen)" : null,
                  fontWeight: active.Products ? "700" : null,
                }}
              >
                <Trans i18nKey='description.nav4' />
              </p>
            </div>
          </Link>

          <Link to='/products/digitalfleet'>
            <div className='hoverItem' style={{ position: "relative" }}>
              <p
                style={{
                  color: active.Products ? "var(--secondaryGreen)" : null,
                  fontWeight: active.Products ? "700" : null,
                }}
              >
                <Trans i18nKey='description.nav5' />
              </p>
            </div>
          </Link>

          <div
            style={{ margin: "0 1em " }}
            onMouseEnter={(e) =>
              mountSecondMenu(
                "Company",
                setSubMenu,
                setMenuContent,
                active,
                setActive
              )
            }
          >
            <div className='hoverItem' style={{ position: "relative" }}>
              <p
                style={{
                  color: active.Company ? "var(--primaryGreen)" : null,
                  fontWeight: active.Company ? "500" : null,
                }}
              >
                <Trans i18nKey='description.nav2' />
              </p>
            </div>
          </div>

          {subMenu && <SubMenu MenuContent={MenuContent} />}
        </div>
        <div className='nav-buttons'>
          <LanguageSwitch />
          <Link to='/work-with-us'>
            <PrimaryButton
              text={<Trans i18nKey='description.contact' />}
              noPadding
              color='var(--primaryGreen)'
              textColor='var(--white)'
            />
          </Link>
        </div>
      </header>
    </div>
  );
};

const Nav = (props) => {
  const { borderBottom } = { ...props };

  const Mobile = IsMobile();
  return Mobile ? (
    <MobileNav borderBottom={borderBottom} />
  ) : (
    <DesktopNav borderBottom={borderBottom} />
  );
};

const SubMenu = (MenuContent) => {
  return (
    <center className='secondMenu'>
      {MenuContent.MenuContent.items.map((item, key) => {
        if (item.typeOfLink === "external") {
          return (
            <a
              href={item.link}
              target='_blank'
              rel='noreferrer'
              style={{
                margin: "1em 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className='secondMenuItem'>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <img
                    src={item.img}
                    style={{ width: "60%", padding: "0.3em 0" }}
                  ></img>
                </div>
                <h4
                  style={{
                    color: "var(--black)",
                    padding: "0.3em 0",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </h4>
                <p
                  style={{
                    color: "var(--textColor)",
                    padding: "0.2em 0",
                    textAlign: "left",
                  }}
                >
                  {item.text}
                </p>
              </div>
            </a>
          );
        } else {
          return (
            <a
              href={item.link}
              // to={{ pathname: item.link, state: { reload: item?.reload } }}
              // state={item}
              // to={item.link}
              style={{ margin: "1em 0" }}
            >
              <div style={{ margin: "1em", display: "inline-block" }}>
                {item.icon && <Icon icon={item.icon} />}
              </div>
              <ThirdButton
                text={item.name}
                noPadding
                textColor='var(--black)'
                color='var(--black)'
              />
            </a>
          );
        }
      })}
    </center>
  );
};

const SecondMenuContent = [
  {
    tab: "Products",
    items: [
      {
        name: <Trans i18nKey='description.nav5' />,
        img: "https://res.cloudinary.com/efer/image/upload/v1644240900/VivaDrive/Logos/Products/DFLogo_havoxe_esmf9a.svg",
        text: <Trans i18nKey='description.nav5' />,
        typeOfLink: "external",
        link: "/products/digitalfleet",
      },
      {
        name: <Trans i18nKey='description.nav4' />,
        img: "https://res.cloudinary.com/efer/image/upload/v1643811385/VivaDrive/Logos/Products/EVRLogo_hci8ou.svg",
        text: <Trans i18nKey='description.nav4' />,
        typeOfLink: "external",
        link: `/products/evrecommend`,
      },
    ],
  },
  {
    tab: "Features",
    items: [
      {
        name: "feature-1",
        img: "",
        text: "",
        link: "/features",
      },
      {
        name: "feature-2",
        img: "",
        text: "",
        link: "/features",
      },
    ],
  },
  {
    tab: "Use_Cases",
    items: [
      {
        name: "Monitor Your fleet",
        link: "/usecases#monitorYourFleet",
      },
      {
        name: "Optimise your fleet cost",
        link: "/usecases",
      },
      {
        name: "Improve drivers behaviour",
        link: "/usecases",
      },
      {
        name: "Fleet insurance",
        link: "/usecases",
      },
      {
        name: "Insurance telematics",
        link: "/usecases",
      },
      {
        name: "Make your fleet green",
        link: "/usecases",
      },
      {
        name: "Manage EV",
        link: "#manageEV",
      },
    ],
  },
  {
    tab: "Company",
    items: [
      {
        icon: IconTypes.ABOUT,
        name: <Trans i18nKey='description.about' />,
        link: "/about",
        reload: false,
      },
      // {
      //   name: "Our Partners",
      //   link: "/",
      // },
      // {
      //   icon: IconTypes.BULB,
      //   name: "Blog",
      //   link: "/blog",
      //   reload: true,
      // },
      {
        icon: IconTypes.CAREER,
        name: <Trans i18nKey='description.career' />,
        link: "/careers",
        reload: false,
      },
      {
        icon: IconTypes.STUDENT,
        name: <Trans i18nKey='description.internship' />,
        link: "/internships",
        reload: false,
      },
      {
        icon: IconTypes.MEDIA,
        name: <Trans i18nKey='description.media' />,
        link: "/newsroom",
        reload: true,
      },
      // {
      //   icon: "https://res.cloudinary.com/efer/image/upload/v1656335919/VivaDrive/icons/icons8-flag-of-europe-40_af6ikq.png",
      //   name: <Trans i18nKey='description.euproject' />,
      //   link: "/euprojects",
      // },
      // {
      //   name: "Help Center",
      //   link: "/help",
      // },
    ],
  },
];

export default Nav;
