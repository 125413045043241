import SectionIntro from "./SectionIntro";
import { t } from "i18next";

const accentColor = "var(--primaryGreen)";

const Ecosystem = ({ title, text, intro, id }) => {

  const companies = [
    {
      img: "./images/pilotmaker.png",
      link: "https://www.tauron.pl/tauron/tauron-innowacje/wspolpraca-ze-startupami/pilot-maker",
    },
    {
      img: "./images/brr.png",
      link: "https://www.gov.pl/web/ncbr",
    },
    {
      img: "./images/freshworks.png",
      link: "https://www.freshworks.com/",
    },
    {
      img: "./images/nissan.png",
      link: "https://www.nissanusa.com/",
    },
    {
      img: "./images/kptt.png",
      link: "https://scaleup.kpt.krakow.pl/",
    },
    {
      img: "./images/ovh.png",
      link: "https://www.ovhcloud.com/pl/",
    },
    {
      img: "/images/mit-logo.png",
      link: "https://mitefcee.org/",
    },
    {
      img: "./images/X-Europe-1024x325.png",
      link: "https://www.xeurope.eu/",
    },
    {
      img: "./images/esapng.png",
      link: "http://www.esa.int/",
    },
    {
      img: "./images/mec.png",
      link: "https://www.imec-int.com/en",
    },
    {
      img: "./images/uha.png",
      link: "https://www.uhasselt.be/",
    },
  ];


  return (
    <div id={id} className='section'>
      <SectionIntro data={intro} />
      <div style={{ width: "100%", padding: "0"}}>
        <img
          className='ecosystem'
          style={{ width: "100%", height: "100" }}
          src={t("description.ecosystem")}
        />
        <img
          className='ecosystem-mobile'
          style={{ width: "100%", height: "100" }}
          src={t("description.ecosystemMobile")}
        />
      </div>
    </div>
  );
};

export default Ecosystem;
