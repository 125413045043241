const Student = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40'
      height='40'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <g fill='var(--primaryGreen)'>
          <path d='M86.12317,22.93333c-0.65767,-0.0148 -1.31297,0.08369 -1.93724,0.29115l-68.8,22.93333c-1.94494,0.64761 -3.39649,2.28384 -3.80768,4.2921c-0.41119,2.00826 0.2805,4.08331 1.8144,5.44321c1.5339,1.3599 3.67689,1.79794 5.6214,1.14907l66.98594,-22.32865l66.98594,22.32865c1.94442,0.64852 4.08713,0.21031 5.62081,-1.14952c1.53369,-1.35984 2.22529,-3.43466 1.81425,-5.44274c-0.41105,-2.00808 -1.86229,-3.64427 -3.80694,-4.29211l-68.8,-22.93333c-0.54584,-0.1813 -1.11585,-0.27945 -1.69089,-0.29115zM86.12317,63.06667c-0.65767,-0.0148 -1.31297,0.08369 -1.93724,0.29114l-68.8,22.93333c-2.34528,0.77812 -3.92829,2.97119 -3.92829,5.44219c0,2.471 1.583,4.66407 3.92829,5.44219l68.8,22.93333c1.1775,0.3927 2.45063,0.3927 3.62812,0l61.25261,-20.4138v33.76172c-3.41497,1.98528 -5.73333,5.63994 -5.73333,9.87656c0,6.33533 11.46667,22.93333 11.46667,22.93333c0,0 11.46667,-16.598 11.46667,-22.93333c0,-4.23662 -2.31837,-7.89129 -5.73333,-9.87656v-41.43229c0.00246,-0.09703 0.00246,-0.19411 0,-0.29114c-0.00102,-0.15701 -0.00849,-0.31391 -0.02239,-0.47031c0.0003,-0.03359 0.0003,-0.06719 0,-0.10078c-0.0089,-0.09358 -0.02011,-0.18692 -0.0336,-0.27995c-0.00355,-0.02615 -0.00728,-0.05228 -0.0112,-0.07838c-0.02669,-0.16187 -0.06031,-0.32253 -0.10078,-0.48151c-0.53354,-2.22414 -2.33788,-3.91845 -4.59114,-4.3112l-67.96016,-22.65339c-0.54584,-0.1813 -1.11585,-0.27945 -1.69089,-0.29114zM45.86667,119.41458v18.18542c0,0 9.20773,11.46667 40.13333,11.46667c30.9256,0 40.13333,-11.46667 40.13333,-11.46667v-18.18542l-34.69114,11.56745c-1.76587,0.5848 -3.60752,0.88464 -5.44219,0.88464c-1.83467,0 -3.67632,-0.29984 -5.44219,-0.88464z'></path>
        </g>
      </g>
    </svg>
  );
};

export default Student;
