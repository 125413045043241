import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";
import IconTypes from "../../Components/Icons/IconTypes";
import ColumnsSection from "../../Parts/Sections/ColumnsSection";
import "./AboutUs.css";
import SectionIntro from "../../Parts/Sections/SectionIntro";
import TeamSection from "../../Parts/Team/TeamSection";
import { SecondaryButton } from "../../Components/Buttons/Buttons";
import { Link } from "react-router-dom";
import EUFundsSection from "../EUProjects/EUFundsSection";
import LandingBar from "../../Components/LandingBar";
import SocialProofTab from "../../Parts/SocialProof/socialProofTab";
import { Trans } from "react-i18next";
import MediaSection from "../../Pages/Media/MediaSection";

const accentColor = "var(--primaryGreen)";

const AboutUs = () => {
  const companies = [
    {
      img: "./images/brr.png",
      link: "https://www.gov.pl/web/ncbr",
    },
    {
      img: "/images/mit-logo.png",
      link: "https://mitefcee.org/",
    },
    {
      img: "./images/nissan.png",
      link: "https://www.nissanusa.com/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657547052/VivaDrive/Logos/Partners/FRESHWORKSwhite_udar1k.png",
      link: "https://www.freshworks.com/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657545987/VivaDrive/Logos/Partners/OVHwhite_acf6ah.png",
      link: "https://www.ovhcloud.com/pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657545321/VivaDrive/Logos/Partners/logoPSPAWHITE_fezgaa.png",
      link: "https://pspa.com.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657546628/VivaDrive/Logos/Partners/KPTwhite_h0kgio.png",
      link: "https://scaleup.kpt.krakow.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657546374/VivaDrive/Logos/Partners/XEuropeWhite_r6tv9w.png",
      link: "https://www.xeurope.eu/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657548543/VivaDrive/Logos/Partners/ESAwhite_oev27d.png",
      link: "http://www.esa.int/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657548126/VivaDrive/Logos/Partners/IMECwhite_tzs560.png",
      link: "https://www.imec-int.com/en",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657547738/VivaDrive/Logos/Partners/UHASSELTwhite_l8yzep.png",
      link: "https://www.uhasselt.be/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657547320/VivaDrive/Logos/Partners/PILOTMAKERwhite_e75gdw.png",
      link: "https://www.tauron.pl/tauron/tauron-innowacje/wspolpraca-ze-startupami/pilot-maker",
    },
  ];

  const columnValues = [
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1688556269/VivaDrive/icons/eco-friendly-fabric_pc5fes.png",
      type: "icon",
      title: <Trans i18nKey="description.valueTitle1" />,
      text: <Trans i18nKey="description.valueText1" />,
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1688556269/VivaDrive/icons/chip_1_c0s1qb.png",
      type: "icon",
      title: <Trans i18nKey="description.valueTitle2" />,
      text: <Trans i18nKey="description.valueText2" />,
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1688556269/VivaDrive/icons/profile_xrj02f.png",
      type: "icon",
      title: <Trans i18nKey="description.valueTitle3" />,
      text: <Trans i18nKey="description.valueText3" />,
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1688556269/VivaDrive/icons/sun-simple-yantra-sign_p9ewge.png",
      type: "icon",
      title: <Trans i18nKey="description.valueTitle4" />,
      text: <Trans i18nKey="description.valueText4" />,
    },
  ];

  const introValues = {
    accent: accentColor,
    smallTitle: <Trans i18nKey="description.about13" />,
    title: "",
  };

  const introTeam = {
    accent: accentColor,
    smallTitle: <Trans i18nKey="description.about14" />,
    title: "",
  };

  return (
    <div style={{ backgroundColor: "var(--lightGrey)" }}>
      <Nav />
      <LandingBar
        image={
          "https://res.cloudinary.com/efer/image/upload/v1656329055/VivaDrive/Pictures/architecture-1837176_ccoulm-min_nnexu6-min_mzum23.jpg"
        }
        textBig={<Trans i18nKey="description.aboutNavBig" />}
        textSmall={<Trans i18nKey="description.aboutNavSmall" />}
      />

      <section
        className="section aboutSectionWhat"
        style={{
          margin: "5em 0 -6em 0",
          backgroundColor: "var(--lightGrey)",
        }}
      >
        <div className="whatDrivesUsText" style={{ margin: "0 0 3em 1em" }}>
          <h2 style={{ color: "var(--primaryGreen)", marginBottom: "1em" }}>
            <Trans i18nKey="description.about1" />
          </h2>
          <h1 style={{ fontSize: "2.3em" }}>
            <Trans i18nKey="description.about2" />
          </h1>
          <h4 style={{ padding: "0.6em 0", color: "var(--textColor)" }}>
            <Trans i18nKey="description.about3" />
          </h4>
          <h4 style={{ padding: "0.6em 0", color: "var(--textColor)" }}>
            <Trans i18nKey="description.about4" />
          </h4>
          <h4 style={{ padding: "0.6em 0", color: "var(--textColor)" }}>
            <Trans i18nKey="description.about5" />
          </h4>
        </div>
        <div className="whatDrivesUsImageWrapper">
          <img
            src="https://res.cloudinary.com/efer/image/upload/v1656329054/VivaDrive/Pictures/pexels-gustavo-fring-4173196_spkh72-min_i4laav-min_iudpfu.jpg"
            alt="group of people working"
          />
          <div className="square sTop" style={{ zIndex: "0" }}></div>
          <div className="square sBottom" style={{ zIndex: "0" }}></div>
        </div>
      </section>
      <section>
        <div
          className="sectionAngle"
          style={{ backgroundColor: "var(--white)", zIndex: "999" }}
        >
          <ColumnsSection intro={introValues} columns={columnValues} />
        </div>
      </section>
      <SocialProofTab
        companies={companies}
        id="partners"
        bg="var(--primaryGreen)"
      />
      <section
        className="section aboutSectionJourney"
        style={{
          marginBottom: "-10em",
          backgroundColor: "var(--lightGrey)",
        }}
      >
        <div className="whatDrivesUsText" style={{ marginBottom: "4em" }}>
          <h2 style={{ color: "var(--primaryGreen)" }}>
            <Trans i18nKey="description.about6" />
          </h2>
          <h4 style={{ padding: "0.5em 0", color: "var(--textColor)" }}>
            <Trans i18nKey="description.about7" />
          </h4>
          <h4 style={{ padding: "0.5em 0", color: "var(--textColor)" }}>
            <Trans i18nKey="description.about8" />
          </h4>
          <h4 style={{ padding: "0.5em 0", color: "var(--textColor)" }}>
            <Trans i18nKey="description.about9" />
          </h4>
        </div>

        <div className="whatDrivesUsImageWrapper">
          <img
            src="https://res.cloudinary.com/efer/image/upload/v1656329055/VivaDrive/Pictures/Screenshot_2022-04-06_at_6.28.44_AM_lkkoyp-min_hopt4s-min_fsqy4t.png"
            alt="Headquarters"
          />
          <div className="square sBottom" style={{ zIndex: "0" }}></div>
          <div className="square sTop" style={{ zIndex: "0" }}></div>
        </div>
      </section>
      <section>
        <div
          className="sectionAngle"
          style={{
            backgroundColor: "var(--white)",
            zIndex: "999",
            marginTop: "4em",
          }}
        >
          <SectionIntro />
          <TeamSection intro={introTeam} />
        </div>
      </section>
      {/* <MediaSection /> */}
      <section
        className="section aboutSectionWhat"
        style={{ marginBottom: "-6em", backgroundColor: "var(--lightGrey)" }}
      >
        <div className="whatDrivesUsText" style={{ margin: "0 0 3em 1em" }}>
          <h2 style={{ color: "var(--primaryGreen)" }}>
            <Trans i18nKey="description.about10" />
          </h2>
          <h1 style={{ fontSize: "2.3em", margin: "0.4em 0" }}>
            <Trans i18nKey="description.about11" />
          </h1>
          <h4 style={{ color: "var(--textColor)", margin: "1em 0" }}>
            <Trans i18nKey="description.about12" />
          </h4>
          <Link to="/careers#openPositions">
            <SecondaryButton
              text={<Trans i18nKey="description.careerPositions" />}
              color="var(--primaryGreen)"
              textColor="var(--primaryGreen)"
            />
          </Link>
        </div>
        <div className="whatDrivesUsImageWrapper">
          <img
            src="https://res.cloudinary.com/efer/image/upload/v1656329054/VivaDrive/Pictures/pexels-fauxels-3228688_sawbvh-min_grqk7j-min_hthess.jpg"
            alt="group of people working"
          />
          <div className="square sTop" style={{ zIndex: "0" }}></div>
          <div className="square sBottom" style={{ zIndex: "0" }}></div>
        </div>
      </section>
      <EUFundsSection />
      <Footer />
    </div>
  );
};

export default AboutUs;

//  className="section sectionAngle"
//       style={{ backgroundColor: 'var(--white)', paddingBottom: "0" }}
