import { Link } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import Pack from "./ClassPricings";
import { Trans } from "react-i18next";
import { useState } from "react";
const ItemFeature = ({ isFeature, isActive, color, text }) => {
  return (
    <div className='pricingListItem'>
      {isFeature ? (
        isActive ? (
          <Feature color={color} />
        ) : (
          <FeatureColored color={color} />
        )
      ) : (
        NotFeature
      )}
      <p
        className={isFeature ? "inlineBlock" : "lineThrought inlineBlock"}
        style={{ paddingLeft: "1em" }}
      >
        {text}
      </p>
    </div>
  );
};

const PriceColumn = ({ data, isActive, color, priceUpdate }) => {
  const { type, price, priceInfo, time, features, info } = { ...data };

  return (
    <div
      className={`priceColumn ${isActive && "priceColumn--active"}`}
      style={{
        backgroundColor: isActive && "#10B070",
        border: `1px solid ${color}`,
        padding: "3em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h2
          style={{
            padding: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "800",
          }} /* className={priceUpdate && "flashAnimation"} */
        >
          {type}
        </h2>
        <h3
          style={{
            padding: "0.5em 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "500",
          }} /* className={priceUpdate && "flashAnimation"} */
        >
          {priceInfo}
        </h3>
        <p
          style={{
            padding: "0.5em 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "1em",
          }}
        >
          {time}
        </p>
        <h2
          style={{
            margin: "0.5em 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "800",
          }}
        >
          {price}
        </h2>
        <center style={{ margin: "1em 0 " }}>
          <Link to={`/letswork/DigitalFleet`}>
            {isActive ? (
              <PrimaryButton
                textColor={color}
                color='var(--white)'
                text={<Trans i18nKey='description.selectPlan' />}
              />
            ) : (
              <PrimaryButton
                textColor='var(--white)'
                color='var(--primaryGreen)'
                text={<Trans i18nKey='description.selectPlan' />}
              />
            )}
          </Link>
        </center>
        {features.map((item, key) => {
          return (
            <ItemFeature
              color={color}
              isActive={isActive}
              isFeature={item.isFeature}
              text={item.featureText}
            />
          );
        })}
      </div>
    </div>
  );
};

const NotFeature = (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='11' cy='11' r='11' fill='#2D463B' />
    <path
      d='M6.85547 7.05127L15.3978 15.5936'
      stroke='var(--white)'
      stroke-width='2'
      stroke-linecap='round'
    />
    <path
      d='M15.3978 7.05127L6.85547 15.5936'
      stroke='var(--white)'
      stroke-width='2'
      stroke-linecap='round'
    />
  </svg>
);

const Feature = ({ color }) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='11' cy='11' r='11' fill='var(--white)' />
      <path
        d='M7.00781 10.2396L10.3566 13.7373L15.0078 7.7373'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const FeatureColored = ({ color }) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='11' cy='11' r='11' fill={color} />
      <path
        d='M7.00781 10.2396L10.3566 13.7373L15.0078 7.7373'
        stroke='var(--white)'
        stroke-width='2'
        stroke-linecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const PricingGrid = ({ color }) => {
  const [value, setValue] = useState(50);
  const MIN = 1;
  const MAX = 1001;

  const [basic, setBasic] = useState(240);
  const [standard, setStandard] = useState(300);
  const [longTerm, setLongTerm] = useState(180);
  // const [priceUpdate, setPriceUpdate] = useState(false)

  const handleSlider = (e) => {
    setValue(e.target.value);
    ajustPrices(e.target.value, setBasic);
  };

  function ajustPrices(value, setBasic) {
    switch (true) {
      case value < 100:
        setBasic(10.000);
        setStandard(25.000);
        setLongTerm("Contact sales");
        break;
      case value < 501:
        // if(value === 501){
        //      setPriceUpdate(true)
        // setTimeout(() => {
        //     setPriceUpdate(false)
        // }, 500);
        // }
        setBasic(216);
        setStandard(276);
        setLongTerm(144);
        break;
      case value < 1000:
        setBasic(180);
        setStandard(240);
        setLongTerm(120);
        break;
      case value <= 1001:
        // setPriceUpdate(true)
        // setTimeout(() => {
        //     setPriceUpdate(false)
        // }, 500);
        setBasic(120);
        setStandard(180);
        setLongTerm(96);
        break;
      default:
        console.log(`Sorry, we are out of range`);
    }
  }

  const Basic = new Pack(
    <Trans i18nKey='description.pricing28' />,
    basic,
    <Trans i18nKey='description.pricing31' />,
    [
      {
        featureText: <Trans i18nKey='description.pricing1' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing2' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing3' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing4' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing5' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing6' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing7' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing8' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing9' />,
        isFeature: false,
      },
      {
        featureText: <Trans i18nKey='description.pricing10' />,
        isFeature: false,
      },
      {
        featureText: <Trans i18nKey='description.pricing11' />,
        isFeature: false,
      },
      {
        featureText: <Trans i18nKey='description.pricing12' />,
        isFeature: false,
      },
      {
        featureText: <Trans i18nKey='description.pricing13' />,
        isFeature: false,
      },
    ],
    <Trans i18nKey='description.pricing14' />
  );

  const Standard = new Pack(
    <Trans i18nKey='description.pricing29' />,
    standard,
    <Trans i18nKey='description.pricing15' />,
    [
      {
        featureText: <Trans i18nKey='description.pricing16' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing2' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing3' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing4' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing5' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing6' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing7' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing8' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing9' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing10' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing11' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing12' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing13' />,
        isFeature: true,
      },
    ],
    <Trans i18nKey='description.pricing17' />
  );

  const Premium = new Pack(
    <Trans i18nKey='description.pricing30' />,
    longTerm,
    <Trans i18nKey='description.pricing18' />,
    [
      {
        featureText: <Trans i18nKey='description.pricing20' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing21' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing22' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing23' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing24' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing25' />,
        isFeature: true,
      },
      {
        featureText: <Trans i18nKey='description.pricing26' />,
        isFeature: true,
      },
    ],
    <Trans i18nKey='description.pricing27' />
  );

  return (
    <div className='pricingWrapperGrid'>
      {/* <div style={{position:"relative", left:"-7vw"}}>
                    <div className="rangeSquareTop"></div>
                    <div className="rangeSquareBottom"></div>
                    <div className="rangeWrapper">
                        <h2>How many vehicles do you have?</h2>
                        <input type="number" placeholder="2" min={MIN} max={MAX} value={value} onChange={e => handleSlider(e)}/> <span style={{fontSize:"5em"}}>{value > 1000 ? "+" : null}</span>
                        <input type="range" style={{backgroundSize:`${(value - MIN) * 100 / (MAX - MIN)}% 100%`}}  value={value} min={MIN} max={MAX} onChange={e => handleSlider(e)}/>
                    </div>
                </div> */}
      <div className='PricingGrid'>
        <PriceColumn data={Basic} color={color} />
        <PriceColumn data={Standard} color={color} />
        <PriceColumn data={Premium} isActive color={color} />
      </div>
    </div>
  );
};

export default PricingGrid;
