import { Link } from "react-router-dom";
import { ThirdButton } from "./Buttons/Buttons";
import Icon from "./Icons/Icon";

const ColumnAdvantages = ({ title, text, img, link, icon, type, textColor }) => {
  return (
    <div className='columnsAdvantages'>
      {img && <img src={img} className='columnImgAdvantages' alt='' />}
      <br />
      {type === "icon" ? (
        <Icon icon={icon} />
      ) : (
        <img src={icon} style={{ width: "65px", height: "65px" }} />
      )}
      <h3
        style={{
          margin: "0.5em 0 0.5em 0",
          fontWeight: "800",
          color: textColor,
          fontSize: "2em",
        }}
      >
        {title}
      </h3>
      <p style={{ marginBottom: "1em", color: textColor, fontSize: "1.1em" }}>
        {text}
      </p>
      {link && (
        <Link to={link}>
          <ThirdButton text='Read more' noPadding color='var(--primaryGreen)' />
        </Link>
      )}
    </div>
  );
};

export default ColumnAdvantages;
