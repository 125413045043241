const Person = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        strokeLinejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <path
          d='M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z'
          fill='var(--backgroundGreenDark)'
        ></path>
        <g fill='var(--primaryGreen)'>
          <path d='M86,34.4c-14.26054,0 -25.8,11.53946 -25.8,25.8c0,14.26055 11.53946,25.8 25.8,25.8c14.26055,0 25.8,-11.53945 25.8,-25.8c0,-14.26054 -11.53945,-25.8 -25.8,-25.8zM66.85156,95.07031c-24.36387,7.38222 -32.45156,27.4125 -32.45156,27.4125v15.11719h103.2v-15.11719c0,0 -8.08769,-20.03028 -32.45156,-27.4125c-1.33535,9.39785 -9.39785,16.72969 -19.14844,16.72969c-9.75059,0 -17.81309,-7.33184 -19.14844,-16.72969z'></path>
        </g>
      </g>
    </svg>
  );
};

export default Person;
