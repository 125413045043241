import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { t } from "i18next";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";


const ContactUs = ({ contactUs, isReversed, color }) => {
  const { smallTitle, title, text } = { ...contactUs };

  return (
    <section style={{ backgroundColor: "var(--white)" }}>
      <div style={{ backgroundColor: "var(--white)" }}>
        <div
          style={{ display: "grid", gridTemplateRows: "1fr", height: "100%" }}
        >
          <div className='section'>
            <div className='container'>
              <div className='rectangle'>
                <div className='content'>
                  <div className='text'>
                    <h2>
                      <Trans i18nKey='description.ctatitle' />
                    </h2>
                    <p>
                      <Trans i18nKey='description.ctatext' />
                    </p>
                  </div>
                  <Link to='/work-with-us'>
                    <PrimaryButton
                      text={<Trans i18nKey='description.contact' />}
                      noPadding
                      color='var(--white)'
                      textColor='var(--primaryGreen)'
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
