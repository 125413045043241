export const TeamList = [
  {
    level: "coreTeam",
    name: "Mateusz Maj",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988462/vivadrive/Mateusz.png",
    role: "CEO",
    nationality: "Polish  🇵🇱",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/matmaj/" },
      {
        name: "Email",
        link: "mat@vivadrive.io",
      },
    ],
  },
  {
    level: "coreTeam",
    name: "Paweł Kujda",
    img: "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Pawel1.jpeg",
    role: "CSO",
    nationality: "Poland 🇵🇱",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/paweł-⚡-kujda-msc-eng-873ba124/",
      },
      {
        name: "Email",
        link: "pawel@vivadrive.io",
      },
    ],
  },
  {
    level: "coreTeam",
    name: "Radek Czajka",
    img: "https://res.cloudinary.com/efer/image/upload/v1656334430/VivaDrive/Team/1516781756643_qxwksv.jpg",
    role: "CTO",
    nationality: "Polish  🇵🇱",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/rczajka/" },
      {
        name: "Email",
        link: "rczajka@rczajka.pl",
      },
    ],
  },
  {
    level: "coreTeam",
    name: "Dinesh Kumar",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988462/vivadrive/P1014403.png",
    role: "COO",
    nationality: "India 🇮🇳",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/dkr27/" },
      {
        name: "Email",
        link: "dinesh.nadar518@gmail.com",
      },
    ],
  },
  {
    level: "coreTeam",
    name: "Piotr Ptaszyński",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988462/vivadrive/P1014156.png",
    role: "Fleet Program Director",
    nationality: "Polish  🇵🇱",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/piotr-ptaszy%C5%84ski-82187415a/",
      },
      {
        name: "Email",
        link: "ptaszynski1410@op.pl",
      },
    ],
  },

  {
    level: "coreTeam",
    name: "Gautam Narayan",
    img: "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Gautam.jpeg",
    role: "Frontend Developer",
    nationality: "India 🇮🇳",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/iamrraj/" },
      {
        name: "Email",
        link: "gautam@vivadrive.io",
      },
    ],
  },

  {
    level: "coreTeam",
    name: "Rahul Raj",
    img: "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Rahul.png",
    role: "Full Stack Developer",
    nationality: "India 🇮🇳",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/iamrraj/" },
      {
        name: "Email",
        link: "rahul@vivadrive.io",
      },
    ],
  },
  {
    level: "alumni",
    name: "Alessandro Montanari",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620999530/vivadrive/image_29.png",
    role: "Head of Data Science",
    nationality: "Italy 🇮🇹",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/a-montanari/" },
      {
        name: "Email",
        link: "alessandro.monta@gmail.com",
      },
    ],
  },

  {
    level: "alumni",
    name: "Maja Kolczyńska",
    img: "https://res.cloudinary.com/efer/image/upload/v1645010123/VivaDrive/Team/maja_oysmcx.jpg",
    role: "Data Analyst",
    nationality: "Polish  🇵🇱",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/maja-kolczynska/",
      },
      {
        name: "Email",
        link: "majka.kolczynska@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Firmino Coutinho",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988461/vivadrive/IMG_20190428_204417_1.png",
    role: "UX/CX Lead",
    nationality: "Portuguese  🇵🇹",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/firmino-coutinho/",
      },
      {
        name: "Email",
        link: "firmino.coutinho92@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Stefanos Kypritidis",
    img: "https://res.cloudinary.com/efer/image/upload/v1643368973/VivaDrive/Team/stef_o0yl4k.jpg",
    role: "Data Scientist",
    nationality: "Greece 🇬🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/stefanos-kypritidis-3700bb173/",
      },
      {
        name: "Email",
        link: "stef4k@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Giulia Ciaramella",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988460/vivadrive/image_11.png",
    role: "Data Scientist",
    nationality: "Italy 🇮🇹",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/giulia-ciaramella-ab01341b4/",
      },
      {
        name: "Email",
        link: "giulia.ciaramella@icloud.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Estelle Duhem ",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988460/vivadrive/image_16.png",
    role: "Marketing Growth Hacker",
    nationality: "France 🇫🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/estelle-duhem-imt-student/",
      },
      {
        name: "Email",
        link: "estelle.dhm@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Jan Lennartz",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620999079/vivadrive/image_19.png",
    role: "Data Scientist",
    nationality: "Germany 🇩🇪",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/jan-lennartz/" },
      {
        name: "Email",
        link: "lennartz.jan04@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Farshad Zare",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621002490/vivadrive/image_34.png",
    role: "Civil engineer",
    nationality: "Iran 🇮🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/farshad-zare-81592a18a/",
      },
      {
        name: "Email",
        link: "farshadzare94@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "David Melo",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988459/vivadrive/Davi_melo.png",
    role: "Data Scientist",
    nationality: "Portugal 🇵🇹",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/davidqmelo/" },
      {
        name: "Email",
        link: "david.melo@live.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Fabian Rządkowski",
    img: "https://res.cloudinary.com/efer/image/upload/v1665771661/VivaDrive/Team/IMG_20220917_113134_300_btyckz.jpg",
    role: "Head of Marketing & PR",
    nationality: "Polish  🇵🇱",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/-fabian-/",
      },
      {
        name: "Email",
        link: "fabian@vivadrive.io",
      },
    ],
  },
  {
    level: "alumni",
    name: "João Duarte",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988461/vivadrive/image_23.png",
    role: "Game developer, Full stack",
    nationality: "Portugal 🇵🇹",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/joaoavduarte/" },
      {
        name: "Email",
        link: "jaduarte1998@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Panagiotis Stavropoulos ",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988460/vivadrive/image_13.png",
    role: "Front end developer",
    nationality: "Greece 🇬🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/panagiotisstavropoulos/",
      },
      {
        name: "Email",
        link: "stayropoulospanagiotis@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Khanh Dung Dao ",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988460/vivadrive/image_14.png",
    role: "Data Engineer",
    nationality: "Vietnam 🇻🇳",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/khanh-dung-dao/" },
      {
        name: "Email",
        link: "daokhanhdung.bg@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Narjisse Lasri",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988459/vivadrive/image_15.png",
    role: "Business Growth Hacker",
    nationality: "France 🇫🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/narjisse-lasri-65a457151/",
      },
      {
        name: "Email",
        link: "narjisse.lasri@gmail.com",
      },
    ],
  },

  {
    level: "alumni",
    name: "Adrien Schoen",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620998785/vivadrive/image_17.png",
    role: "Data Scientist",
    nationality: "France 🇫🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/adrien-schoen-5b2a9a180/",
      },
      {
        name: "Email",
        link: "adrien.schoen@telecom-sudparis.eu",
      },
    ],
  },
  {
    level: "alumni",
    name: "Daniel Turoczy",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988460/vivadrive/image_18.png",
    role: "Data Scientist",
    nationality: "Hungary 🇭🇺",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/d%C3%A1niel-tur%C3%B3czy-a36096168/",
      },
      {
        name: "Email",
        link: "turoczyd6@gmail.com",
      },
    ],
  },

  {
    level: "alumni",
    name: "Josipa Rendulić",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621000410/vivadrive/image_20.png",
    role: "Data Scientist",
    nationality: "Croatia 🇭🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/josipa-renduli%C4%87/",
      },
      {
        name: "Email",
        link: "josipa.rendulic1@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Samuel Cucchi",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988461/vivadrive/image_21.png",
    role: "Data Scientist",
    nationality: "Italy 🇮🇹",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/samuele-cucchi-68724311a/",
      },
      {
        name: "Email",
        link: "s.cucchi@outlook.com",
      },
    ],
  },
  {
    level: "intern",
    name: "Marie Lannelucq",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621000299/vivadrive/marie.png",
    role: "levelational Marketing",
    nationality: "France 🇫🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/marie-lannelucq/",
      },
      {
        name: "Email",
        link: "marie.lannelucq@kedgebs.com",
      },
    ],
  },
  {
    level: "intern",
    name: "Agata Rapiej",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621000299/vivadrive/agata.png",
    role: "Business Development",
    nationality: "Polish  🇵🇱",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/agata-rapiej-828552109/",
      },
      {
        name: "Email",
        link: "agatarapiej@gmail.com",
      },
    ],
  },
  {
    level: "intern",
    name: "Mustafa Dogan",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621000299/vivadrive/mustafa.png",
    role: "Business Development",
    nationality: "Turkey 🇹🇷",
    socials: [
      { name: "Linkedin", link: "https://www.linkedin.com/in/dogan-mustafa/" },
      {
        name: "Email",
        link: "Mustafa.Dogan@ogr.gidatarim.edu.tr",
      },
    ],
  },
  {
    level: "intern",
    name: "Kutay Acar",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621000299/vivadrive/kutay.png",
    role: "Data Science",
    nationality: "Turkey 🇹🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/kutayacar/",
      },
      {
        name: "Email",
        link: "kutayacar@gmail.com",
      },
    ],
  },
  {
    level: "alumni",
    name: "Alessandro Galeano",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621002490/vivadrive/image_32.png",
    role: "Investment Analist",
    nationality: "Italy 🇮🇹",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/alessandro-galeano-990bb9199/",
      },
      {
        name: "Email",
        link: "alexgaleano@hotmail.it",
      },
    ],
  },
  {
    level: "intern",
    name: "Benoit Durand",
    img: "https://res.cloudinary.com/joaocostajs/image/upload/v1621002490/vivadrive/image_33.png",
    role: "Data Science",
    nationality: "France 🇫🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/beno%C3%AEt-durand/",
      },
      {
        name: "Email",
        link: "benoit.durand.mail@gmail.com",
      },
    ],
  },

  {
    level: "intern",
    name: "Paul Dargelos",
    img: "https://res.cloudinary.com/efer/image/upload/v1645017777/VivaDrive/20220216_142038_lmjoyf.jpg",
    role: "Business Development",
    nationality: "France 🇫🇷",
    socials: [
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/in/paul-dargelos-9aaa291b2/",
      },
      {
        name: "Email",
        link: "paul.dargelos@icloud.com",
      },
    ],
  },
];
