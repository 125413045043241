const LandingBar = ({ image, textSmall, textBig }) => {
  return (
    <div
      className='aboutLanding'
      style={{ backgroundColor: "var(--lightGrey)" }}
    >
      <img src={image} alt='top page image' style={{ position: "relative" }} />
      <div
        style={{
          backgroundColor: "var(--black)",
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "0.6",
        }}
      ></div>
      <div className='aboutLandingText'>
        <h2 style={{ color: "var(--white)" }}>{textBig}</h2>
        <h4 style={{ color: "var(--white)", padding: "1em 0" }}>{textSmall}</h4>
      </div>
    </div>
  );
};

export default LandingBar;
