import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../Components/Buttons/Buttons";
import { Trans } from "react-i18next";

const ProductNav = ({ color, logo, tabs, product }) => {
  return (
    <div className='nav productNav' style={{ padding: "8em 0em 5em 6em" }}>
      {/* <div className='productLogo'>
        <img src={logo} alt='logo' />
      </div> */}
      <div className='links'>
        {tabs.map((tab) => {
          return (
            <a href={tab.link}>
              <p>{tab.name}</p>
            </a>
          );
        })}
        <Link to={`/letswork/${product}`}>
          <SecondaryButton
            text={<Trans i18nKey='description.dfJoin' />}
            color={color}
            textColor={color}
            iconColor={color}
          />
        </Link>
      </div>
    </div>
  );
};

export default ProductNav;
