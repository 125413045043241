const jobsObj = [{
    position:"Python/Django Developer (Smart City/AI)",
    slug:"python-django-developer-smart-city-ai",
    description:`We are looking for an experienced Python/Django developer with at least 4 years Python experience.

&nbsp; 

We are VivaDrive - #mobility #energy startup that helps companies introduce Electric Cars in a car park and manage them.  

We work with global brands across the globe (Europe & Asia). 

As we are growing and getting new exciting projects, we are looking for an experienced Python/Django Developer who will strengthen our team. 

&nbsp; 

We are building our own products, so you will be able to have an impact on the product strategy, use cutting-edge technologies and participate in every aspect of product development.

&nbsp; 

We are a versatile and international team with the main office in Warsaw and other offices in Belgium and in Portugal.

&nbsp; 

We were selected as among the best EU deep-tech and Big Data Startups by The Next Web.

We were also incubated by the European Space Agency 🚀🌌👾 

&nbsp; 

If you enjoy a good startup atmosphere, challenging tasks, intercultural experience and the possibility to grow your skills, **JOIN US!**`,
               
               
},
{
    position:"Front-end Developer",
    slug:"front-end-developer",
    description:"We are looking for an experienced Python/Django developer with at least 4 years Python experience.",

},
{
    position:"Data Scientist",
    slug:"data-scientist",
    description:"We are looking for a Data Scientist with at least 3 years experience",

},
{
    position:"Digital Marketer",
    slug:"digital-marketer",
    description:"We are looking for a Digital Marketer with at least 2 years experience",

},
{
    position:"Business Developer",
    slug:"business-developer",
    description:"We are looking for a Business Developer with at least 5 years experience",

},
{
    position:"UX/UI",
    slug:"ux-ui",
    description:"We are looking for a UX/UI designer with at least 2 years experience",

    },
{
    position:"Different position?",
    slug:"different",
    description:"Is your skillset not on our list? Don't worry, we are always open for talents.",

}]

export default jobsObj