const ArrowIcon = ({ color }) => {
  const colour = color ? color : "var(--black)";
  return (
    <svg
      style={{ paddingLeft: ".2em" }}
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
    >
      <title>Arrow Icon</title>
      <g
        id='Artboard'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
      >
        <polyline
          id='arrowTip'
          stroke={colour}
          strokeWidth='2'
          strokeLinejoin='round'
          points='14 7 19.172087 11.7408396 14 16.3963533'
        ></polyline>
        <line
          x1='16.9237849'
          y1='11.7480177'
          x2='8.99999993'
          y2='11.75'
          id='arrowLine'
          strokeLinejoin='round'
          stroke={colour}
          strokeWidth='2'
        ></line>
      </g>
    </svg>
  );
};

export default ArrowIcon;
