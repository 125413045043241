const CarMoney = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        strokeLinejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <path
          d='M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z'
          fill='#10b070'
        ></path>
        <g fill='#ffffff'>
          <path d='M44.72,35.54667c-5.06827,0 -9.17333,4.10507 -9.17333,9.17333v82.56c0,5.06827 4.10507,9.17333 9.17333,9.17333h7.13979c-1.57781,-2.71072 -2.55312,-5.81589 -2.55312,-9.17333v-82.56c0,-3.35744 0.97531,-6.46261 2.55312,-9.17333zM67.65333,35.54667c-5.06827,0 -9.17333,4.10507 -9.17333,9.17333v82.56c0,5.06827 4.10507,9.17333 9.17333,9.17333h59.62667c5.06827,0 9.17333,-4.10507 9.17333,-9.17333v-61.92c0,-1.21547 -0.48146,-2.38062 -1.34375,-3.24292l-25.22667,-25.22667c-0.86229,-0.86229 -2.02745,-1.34375 -3.24292,-1.34375zM104.34667,44.28104l23.37229,23.37229h-18.78562c-2.53184,0 -4.58667,-2.05483 -4.58667,-4.58667zM81.41333,86h32.10667c2.53184,0 4.58667,2.05483 4.58667,4.58667c0,2.53184 -2.05483,4.58667 -4.58667,4.58667h-32.10667c-2.53184,0 -4.58667,-2.05483 -4.58667,-4.58667c0,-2.53184 2.05483,-4.58667 4.58667,-4.58667zM81.41333,104.34667h22.93333c2.53184,0 4.58667,2.05483 4.58667,4.58667c0,2.53184 -2.05483,4.58667 -4.58667,4.58667h-22.93333c-2.53184,0 -4.58667,-2.05483 -4.58667,-4.58667c0,-2.53184 2.05483,-4.58667 4.58667,-4.58667z'></path>
        </g>
      </g>
    </svg>
  );
};

export default CarMoney;
