import Nav from "../../Components/Nav";
import ApplyForm from "../../Components/Form/ApplyForm";
import Footer from "../../Parts/Footer/Footer";
import { FeatureColored } from "../../Parts/PricingGrid/PricingGrid";
import { Trans } from "react-i18next";

export default function Apply() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Nav />
      <div
        className='section reverseAngle'
        style={{ backgroundColor: "var(--backgroundGreenDark)" }}
      >
        <h1 style={{ padding: "1em 0" }}>
          <Trans i18nKey='description.apply' />
        </h1>
      </div>
      <div className='section WorkWithUsGrid' style={{ marginBottom: "-15em" }}>
        {GreatPoints}
        <div className='form withUsForm'>
          <ApplyForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Points = [
  <Trans i18nKey='description.interns17' />,
  <Trans i18nKey='description.interns19' />,
  <Trans i18nKey='description.interns21' />,
  <Trans i18nKey='description.interns23' />,
  <Trans i18nKey='description.interns25' />,
  <Trans i18nKey='description.interns27' />,
];

const GreatPoints = (
  <div className='benefits' style={{ marginTop: "4vh" }}>
    {Points.map((point) => {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            margin: "2.5em 0",
          }}
        >
          <div style={{ marginRight: "1em" }}>
            <FeatureColored color='var(--primaryGreen)' />
          </div>
          <h3 style={{ margin: "0" }}>{point}</h3>
        </div>
      );
    })}
  </div>
);
