import Nav from "../../Components/Nav";
import ApplyForm from "../../Components/Form/ApplyForm";
import Footer from "../../Parts/Footer/Footer";
import { useParams } from "react-router-dom";
import "./Apply.css";
import jobsObj from "../../HelperObjects/Jobs";
import { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
export default function ApplyForJob() {
  let { role } = useParams();
  window.scrollTo(0, 0);
  const [job, setJob] = useState(null);

  useEffect(() => {
    setJob(jobsObj.find((el) => el.slug === role));
  }, []);

  return (
    <div>
      <Nav />
      <div className=' section' style={{ backgroundColor: "var(--white)" }}>
        <div className='jobApplyGrid'>
          <div class='details'>
            <Link to='/careers#openPositions' class='greenTitle uppercase'>
              {" "}
              back to Jobs{" "}
            </Link>
            <p class='detail'>warsaw/remote</p>

            <p class='detail'>Full Time</p>
          </div>

          <div class='jobDescript'>
            {job ? (
              <>
                <h1>{job.position}</h1>
                {/* <p>{job.description}</p> */}
                <h3>Requirements</h3>

                <ReactMarkdown plugins={[gfm]} children={job.description} />
                <div style={{ marginTop: "6em" }}>
                  <ApplyForm inputs='var(--white)' role={role} />
                </div>
                <p
                  style={{
                    marginTop: "2em",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Please be advised that the administrator of personal data is
                  VivaDrive Polska Sp. z.o.o. ul. Żurawia 47/219, 00-680 Warsaw.
                  The purpose of collecting and storing personal data is to
                  conduct recruitment processes by VivaDrive Polska sp. z.o.o.
                  You have the right to access your data and correct it.
                  Providing data is voluntary. Your consent is voluntary and
                  does not affect the possibility of participating in
                  recruitment. You can withdraw it at any time by sending an
                  e-mail to the following address: contact@vivadrive.io.
                </p>
              </>
            ) : (
              <h3>No job found</h3>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
