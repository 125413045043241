const PrivacyPolicyEnglish = {
  MainTitle: "PRIVACY POLICY",
  title: "Information on the processing of personal data",
  mainText:
    'We kindly inform you that the company under the name VivaDrive Polska sp. z o.o., ul. Dobra 56/66, 00-312 Warszawa NIP: 7010929600, KRS 0000789919 (hereinafter: the "Company" or "Administrator") processes your personal data within the meaning of the Regulation of the European Parliament and the Council of the European Union 2016/679 with on 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46 / EC (hereinafter: "GDPR")',
  listTitle:
    "In order to fulfill the information obligation specified in Art. 13 and art. 14 of the GDPR, we would like to inform you that:",
  list: [
    {
      id: 1,
      text: "The Administrator of personal data is a company under the name VivaDrive.io",
    },
    {
      id: 2,
      text: "the Administrator can be contacted:",
      subtext: [
        {
          id: "a",
          text: "in writing, to the following address: VivaDrive Polska sp. z o.o., ul. Dobra 56/66, 00-312 Warszawa",
        },
        {
          id: "a",
          text: "by e-mail to the following address:",
          email: "bok@vivadrive.io",
        },
      ],
    },
    {
      id: 3,
      text: "The administrator has not appointed a Data Protection Inspector,",
    },
    {
      id: 4,
      text: 'Your personal data is processed for the purpose of providing the service by electronic means ("Service"), in particular when browsing the VivaDrive website (<a href="" target:"_blank"> VivaDrive Website </a>, <a href="" target:"_blank"> Digital Fleet Website </a>) ("Website", "Website") , maintenance of an account in the Digital Fleet (System Digital Fleet) and EVRecommend (System EVRecommend) services ("Services") and maintenance of an account in applications ("Applications") Digital Fleet <a href="" target:"_blank">  (Digital Fleet Application) </a> ("DF Application"), Digital Fleet Mobile ("DFM Application"), and Lafarge iVMS ("iVMS Application"), performance of concluded contracts, including payment for Services, sending notifications about order fulfillment, complaint process and ensuring proper customer service, adjusting the advertising offer to customer needs, presenting advertisements and sending commercial information, handling notifications and complaints, as well as enabling contact between buyers and sellers,',
    },
    {
      id: 5,
      text: "The legal basis for the processing of personal data may be your consent (Article 6 (1) (a) of the GDPR), the need to take action at your request (Article 6 (1) (b) of the GDPR) or also purposes related to the legitimate interest pursued by the administrator or by a third party (Article 6 (1) (f) of the GDPR). The legitimate interest of the administrator is the need to offer customers the best possible quality of Service, adjust the Service offer to the needs and expectations of buyers, as well as conduct marketing, promotional and advertising activities,",
    },
    {
      id: 6,
      text: "The source of data origin is the form filled in by you and your activity on the website in the Services and in the Applications, also resulting from the use of the Services provided",
    },
    {
      id: 7,
      text: "The recipients of your personal data may be entities cooperating with the Administrator in the performance of the tasks set out in point 4, in particular companies that are subcontractors, support the processes necessary to send notifications about the implementation of orders, service providers providing advertising services and marketing, as well as in the field of receivables settlements",
    },
    {
      id: 8,
      text: "Your personal data may be transferred to third countries, i.e. outside the European Economic Area (EEA) or to international organizations, but only to entities that provide adequate safeguards and guarantee the rights of data subjects and effective protection measures legal In particular, this includes the Administrator's use of services provided by Google and Facebook, to the extent that it is necessary to achieve the goals indicated in point 4",
    },
    {
      id: 9,
      text: "your personal data will be processed by the Administrator for the period necessary to provide services, i.e. until the contract is terminated or terminated, or for other purposes indicated in point 4 above",
    },
    {
      id: 10,
      text: "you have the right to:",
      subtext: [
        {
          id: "a",
          text: "requests from the Administrator to access their personal data",
        },
        {
          id: "b",
          text: "requests from the Administrator to correct their personal data",
        },
        {
          id: "c",
          text: "requests from the Administrator to delete or limit the processing of their personal data,",
        },
        {
          id: "d",
          text: "objecting to the Administrator to the processing of personal data for the purpose of direct marketing,",
        },
        {
          id: "e",
          text: "objecting to the Administrator to profiling to the extent that the processing is related to direct marketing,",
        },
        {
          id: "f",
          text: "withdrawal of consent to the processing of your personal data,",
        },
        {
          id: "g",
          text: "lodging a complaint to the supervisory body,",
        },
      ],
    },

    {
      id: 11,
      text: "we would like to inform you that you do not have a statutory obligation to provide personal data, however, this obligation may result from specific provisions. The consequence of not providing personal data may be, in particular, the inability to provide Services to you, in particular the inability to conclude a contract for the sale of goods in the online store, or accessing Applications and Services,",
    },

    {
      id: 12,
      text: "to the extent that the processed data has not been obtained from you, you have the right to lodge a complaint with the supervisory body - i.e. the President of the Office for Personal Data Protection, ul. Stawki 2, 00-193 Warszawa",
    },
  ],
  personalDataCollector: {
    title: "Personal data controller",
    text: "The administrator of personal data provided via the Website is VivaDrive Spółka z ograniczoną odpowiedzialnością with its registered office in ul. Dobra 56/66, 00-312 Warszawa, entered into the Register of Entrepreneurs of the National Court Register kept by the District Court for the capital city of Warsaw in Warsaw, 13th Commercial Division of the National Court Register, under the number KRS 0000789919, REGON 383580710, NIP 7010929600, with the share capital paid up in full in the amount of PLN 5.000,00 ('Administrator'). Administrator's contact details: bok@VivaDrive.io",
  },
  cookies: {
    link: "cookies",
    title: "Cookies",
    text: "The Websites automatically collect only information contained in cookies. Files (cookies) are text files that are stored on the website user's end device. They are intended for using the pages of the Websites. First of all, they contain the name of the website of their origin, their unique number, and the storage time on the end device.",
    listText: "The administrator uses cookies to:",
    list: [
      "matching the content of the website to the individual preferences of the user, first of all, these files recognize his device to display the page according to his preferences;",
      "preparing statistics that help to learn about the preferences and behaviors of users, the analysis of these statistics is anonymous and allows you to adjust the content and appearance of the website to the prevailing trends, statistics are also used to assess the popularity of the Websites;",
      "the ability to log into the Websites and Services;",
      "maintaining the user's login on each subsequent page of the Websites and Services.",
    ],
    endText: [
      "The Websites and Services uses two basic types of files (cookies) - session and permanent. Session files are temporary, they are stored until you leave the website (by entering another page, logging out or turning off the browser). Permanent files are stored on the user's end device until they are deleted by the user or for the time specified in their settings",
      "The user may at any time change the settings of his browser to block the use of files (cookies) or obtain information about their placement in his device each time. Other available options can be checked in the settings of your web browser. It should be remembered that most browsers are set by default to accept saving files (cookies) on the end device.",
      "The Websites and Services Administrator informs that changes to the settings in the user's web browser may limit access to some functions of the website's website.",
      "Cookies used by the Website (placed on the user's end device) may be made available to its partners and the advertiser cooperating with it",
      "Information on the settings of web browsers is available in its menu (help) or on the website of its manufacturer.",
    ],
  },
  PersonalData: {
    link: "personal_data",
    title: "Personal data",
    text: "The User may be asked to provide his personal data using the Websites and Applications. The Administrator declares that he will use this data only for purposes related to the functioning of the Websites or to provide the Services. The services are primarily the services provided by the Administrator for the management of the fleet of combustion and electric vehicles, as well as access to the Application available in the Administrator's domains (https://digitalfleet.eu/, https://vivadrive.io).",
  },
  purpose: {
    link: "purpose",
    title: "Purposes of data processing",
    listText:
      "The Administrator may collect and process the User's personal data only when:",
    list: [
      "The User wants to use the contact form on the Websites,",
      "The User wants the Administrator to contact him for direct marketing regarding the offered products and services, performed with the use of digital marketing tools,",
      "The User uses the Administrator's Services via the Websites and Applications.",
      "The user agrees to receive marketing information by electronic means.",
    ],
  },
  dataProcessing: {
    link: "data_processing",
    title: "Data processing",
    text: "The administrator uses organizational and technical measures in accordance with the applicable law (especially with the GDPR), as well as by encrypting the connection with an SSL certificate.",
    listText: "The Administrator processes the following User data:",
    list: [
      "name, surname, e-mail address, IP address and telephone number if the User uses the contact form on the Website or for direct marketing regarding the offered products and services, performed with the use of telecommunications end devices and automatic calling systems",
      "name, surname, e-mail address, IP address, telephone number and data of his company, in particular: list and data of contractors, employees, billing information, sales and purchase invoices, if the User uses the Administrator's Services.",
    ],
    endText:
      "The following information applies only to DFM Application and iVMS Application. When you use DFM Application and iVMS Application VivaDrive will collect information about your driving style and habits, such as location, speed, acceleration, braking and cornering and combine this with weather, traffic, time of day and other contextual data. This also includes your GPS coordinates. Such use is based upon you giving explicit consent to this profiling when registering to use the DFM Application and iVMS Application.",
  },
  legalBasis: {
    link: "legal",
    title: "Legal basis for the processing of personal data",
    listText:
      "The User's personal data are processed only if the Administrator has a legal basis for it, i.e .:",
    list: [
      "contract for the provision of Services or for taking action at the request of the data subject prior to concluding this contract (Article 6 (1) (b) of the GDPR).",
      "legal obligation related to accounting in the case of paid services (Article 6 (1) (c) of the GDPR).",
      "the Controller's legitimate interest in the processing of personal data to establish, investigate or defend any claims related to the Website, including the Services provided (Article 6 (1) (f) of the GDPR).",
      "legitimate interest in collecting data for statistical and analytical purposes, which allows us to optimize our Website (Article 6 (1) (f) of the GDPR).",
      "User's consent expressed on the Website to data processing (Article 6 (1) (a) of the GDPR). The User may at any time withdraw or limit the consent to the processing of personal data by the Administrator. The processing of data until the User withdraws the consent remains lawful.",
    ],
  },
  provindgPersonalData: {
    link: "provide_personal",
    title: "Providing your personal data",
    text: "the User provides personal data on the Websites, Services and Applications voluntarily. Providing data is necessary when the User intends or uses the Services and/or Applications or wants to receive marketing information by electronic means.",
  },
  userRight: {
    link: "user_right",
    title: "User rights",
    listText:
      "The User has the right to access, rectify, delete or request restriction of the processing of their personal data. The user also has the right to object to the processing if:",
    list: [
      "for reasons related to the User's particular situation - in relation to the processing of personal data based on art. 6 sec. 1 lit. f GDPR (i.e. on the legitimate interests pursued by the administrator), including profiling based on these provisions",
      "if personal data are processed for direct marketing purposes, including profiling, to the extent that the processing is related to such direct marketing.",
    ],
    endText:
      "If the User considers that his data is being processed unlawfully, he has the right to lodge a complaint with the supervisory authority. To exercise his rights, the User should notify the Administrator of this fact by e-mail by sending a message to the address bok@vivadrive.io",
  },
  dataRecipients: {
    link: "data_recipients",
    title: "Data recipients",
    listText: "The recipients of data processed by the Administrator are:",
    list: [
      "hosting companies that provide the Administrator with space on the servers",
      "internet service providers,",
      "software providers for customer service and potential customers in connection with the Services provided by the Administrator,",
      "providers of payment platforms through which the User can pay for access to the Services",
      "entities processing data on our behalf, i.e. companies providing legal, accounting and customer service services.",
    ],
    endText: [
      "Such entities will process data only on the basis of relevant contracts and only in accordance with our instructions. We require our partners to maintain confidentiality and to ensure appropriate security measures ",
      "Your data may also be made available to entities authorized to obtain it on the basis of applicable law,e.g. law enforcement authorities.",
      " If you have expressed your desire to use additional services by accepting the appropriate consent or the regulations of additional services as part of the Marketplace module, your personal data may be transferred to our Partners in connection with the subject of our cooperation. The list of our partners can be found at www.vivadrive.io/partners",
      "Due to the use of analytical tools on our Websites and Applications from suppliers operating under the Google and Facebook brands, these entities may have access to data characterizing the way of using services provided electronically (so-called operational data), which can be used for marketing purposes.",
    ],
  },
  dataProcessingPeriod: {
    link: "data_processing",
    title: "Data processing period",
    listText:
      "The User's data will be processed by the Administrator only for the period in which the Administrator will have a legal basis for it, i.e. until:",
    list: [
      "the Administrator will cease to have a legal obligation to process your data, or",
      "the possibility of pursuing any claims related to the cooperation by either party will cease or",
      "The user withdraws consent to the processing of data if it was its basis or",
      "the User's objection to the processing of personal data will be accepted if the basis for the processing of such data was the legitimate interest of the Administrator or the data were processed for the purpose of direct marketing,",
    ],
  },
  finalText:
    "depending on what is applicable in a given case and what will happen at the latest.",
  version: 1.1,
  verson_name: "Version",
  place: "Warszawa",
  date: "19/10/2021",
};

export default PrivacyPolicyEnglish;
