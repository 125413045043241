const Leaf = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        strokeLinejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <path
          d='M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z'
          fill='var(--backgroundGreenDark)'
        ></path>
        <g fill='var(--primaryGreen)'>
          <path d='M112.99799,33.91983l-2.06937,-2.34887l-3.05837,0.63425c-1.92425,0.4085 -47.31075,10.09425 -65.274,37.00687c-4.38062,7.36375 -4.97188,14.06638 -4.97188,19.66175c0,13.49662 8.78275,25.10663 19.10275,30.82025c-5.10087,4.085 -10.41675,7.4175 -15.72187,9.53525l3.98825,9.98137c28.77237,-11.50788 57.00725,-51.18075 57.09325,-80.16813l0.03763,-0.06988c0,43 -16.125,64.5 -16.125,64.5c0.04838,0.03763 0.086,0.05912 0.129,0.09675l-4.20863,4.43437c6.40163,6.06838 13.51813,6.17588 16.4905,6.2135c18.19438,-0.72563 36.36725,-15.35638 35.95337,-46.4185c-0.18812,-14.52862 -5.90712,-36.3135 -21.36562,-53.879z'></path>
        </g>
      </g>
    </svg>
  );
};

export default Leaf;
