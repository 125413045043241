import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";
import EUFundsSection from "./EUFundsSection";
import SingleProject from "../EUProjects/SingleProject";
import LandingBar from "../../Components/LandingBar";
import Fade from "react-reveal/Fade";
import { Trans } from "react-i18next";

const EUProjectsPage = () => {
  const projects = [
    {
      name: <Trans i18nKey="description.project-1" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1676467730/VivaDrive/Logos/Funds/logo-EIT-Urban-Mobility_x3iuqm.svg",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1676467730/VivaDrive/Logos/Funds/logo-EIT-Urban-Mobility_x3iuqm.svg",
      text: <Trans i18nKey="description.project-2" />,
      amount: "---",
      value: `${(<Trans i18nKey="description.project0" />)} "`,
      date: "12.2022-",
      link: "https://www.eiturbanmobility.eu/",
    },
    {
      name: <Trans i18nKey="description.project1" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1665315696/VivaDrive/Logos/Partners/Untitled_design__27_-removebg-preview_dq2pzq.png",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1665315696/VivaDrive/Logos/Partners/Untitled_design__27_-removebg-preview_dq2pzq.png",
      text: <Trans i18nKey="description.project2" />,
      amount: "896 000 PLN",
      value: `${(<Trans i18nKey="description.project3" />)} "1 120, 000 PLN`,
      date: "17.01.2022",
      link: "https://www.spacebridgefund.com/",
    },
    {
      name: <Trans i18nKey="description.project4" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1649328810/VivaDrive/Logos/Funds/logoidea.png_bjg2u2.png",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1649328810/VivaDrive/Logos/Funds/logoidea.png_bjg2u2.png",
      text: <Trans i18nKey="description.project5" />,
      amount: "43 46,15 EUR",
      date: "05.10.2021",
      link: "https://power.parp.gov.pl/component/grants/grants/idea-global-1",
    },
    {
      name: <Trans i18nKey="description.project6" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1649339254/VivaDrive/Logos/Funds/KPT_logo_ENG_3_linijki-removebg-preview_wby8w7.png",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1649339254/VivaDrive/Logos/Funds/KPT_logo_ENG_3_linijki-removebg-preview_wby8w7.png",
      text: <Trans i18nKey="description.project7" />,
      amount: "55 453,28 EUR",
      date: "05.07.2021",
      link: "https://media.kpt.krakow.pl/151447-blizej-przemyslu-40-dzieki-akceleracji-z-kpt-scaleup",
    },
    {
      name: <Trans i18nKey="description.project8" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1649344924/VivaDrive/Logos/Funds/PARP_Grupa_PFR_logo-RGB_umfdlv.svg",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1649344924/VivaDrive/Logos/Funds/PARP_Grupa_PFR_logo-RGB_umfdlv.svg",
      text: <Trans i18nKey="description.project9" />,
      amount: "3 848,23 EUR",
      date: "17.09.2020",
      link: "https://www.parp.gov.pl/component/grants/grants/wsparcie-prawne-dla-start-upow",
    },
    {
      name: <Trans i18nKey="description.project10" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1649340282/VivaDrive/Logos/Funds/49948119_2148365628561490_3794140428152864768_n-removebg-preview_xeybdm.png",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1649340282/VivaDrive/Logos/Funds/49948119_2148365628561490_3794140428152864768_n-removebg-preview_xeybdm.png",
      text: <Trans i18nKey="description.project11" />,
      amount: "37 500,26 EUR",
      date: "21.05.2020",
      link: "https://mitefcee.org/today-5-amazing-technology-startups-selected-in-the-spring-edition-of-the-mit-enterprise-forum-cee-program-start-their-american-dream/",
    },
    {
      name: <Trans i18nKey="description.project12" />,
      image:
        "https://res.cloudinary.com/efer/image/upload/v1649341604/VivaDrive/Logos/Funds/newlogos_kghxdd.png",
      image1:
        "https://res.cloudinary.com/efer/image/upload/v1649410077/VivaDrive/Logos/Funds/3logos_x3tszt.png",
      text: <Trans i18nKey="description.project13" />,
      amount: "105 227,25 EUR",
      date: "26.08.2019",
      link: "https://www.parp.gov.pl/component/grants/grants/elektro-scaleup",
    },
  ];

  return (
    <div style={{ backgroundColor: "var(--lightGrey)" }}>
      <Nav />
      <LandingBar
        image={
          "https://res.cloudinary.com/efer/image/upload/v1656329054/VivaDrive/Pictures/european-union-6405066_1920_u2gomf-min_thmced.jpg"
        }
        textBig={<Trans i18nKey="description.euTitle" />}
        textSmall={""}
      />
      <section className="section">
        <EUFundsSection />
        <div className="EUGrid">
          {projects.map((project, key) => (
            <Fade bottom delay={80 * key} key={key}>
              <SingleProject data={project} />
            </Fade>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default EUProjectsPage;
