import React from 'react';
import { useTranslation, Trans } from "react-i18next";
import { t } from "i18next";
import './Modal.css'; // Import your CSS for styling

const Model = ({ show, onClose, children }) => {
  const { t } = useTranslation();
  if (!show) {
    return null;
  }
 

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-body">
          <div>
          {children}
          </div>
         
        </div>
        <button className="close-button" onClick={onClose}>
        {t("description.close")}
        </button>
      </div>
    </div>
  );
};

export default Model;
