import { useTranslation, Trans } from "react-i18next";

const LanguageSwitch = () => {
  const Reload = () => {
    window.location.reload(true);
  };

  const lngs = {
    en: { nativeName: "EN" },
    pl: { nativeName: "PL" },
    // fr: { nativeName: "FR" },
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      {Object.keys(lngs).map((lng) => (
        <button
          className='languageSingleButton'
          key={lng}
          style={{
            fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
            color:
              i18n.resolvedLanguage === lng
                ? "var(--primaryGreen)"
                : "var(--darkGrey)",
          }}
          type='submit'
          onClick={() => i18n.changeLanguage(lng) && Reload()}
        >
          {lngs[lng].nativeName}
        </button>
      ))}
    </>
  );
};

export default LanguageSwitch;
