import axios from "axios";
const sendForm = (data) => {
  console.log("form data", data);
  return axios.post("https://digitalfleet.eu/api/1/tos/contact/", { ...data });
};

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const sendApplyForm = (data) => {
  return axios({
    method: "POST",
    url: "https://digitalfleet.eu/api/1/tos/job/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  })
    .then((res) => {
      if (res.status === 201) {
        console.log("Created", "Manufacturer Created successfully!", "success");
      }
    })
    .catch((err) => {
      console.log(err);
    });
  // return axios.post('https://digitalfleet.eu/api/1/tos/job/', data)
};

export default { sendForm: sendForm, sendApplyForm: sendApplyForm };
