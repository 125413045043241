import {
  PrimaryButton
} from "../../../Components/Buttons/Buttons";
import Nav from "../../../Components/Nav";
import ProductNav from "../../../Parts/Navs/ProductNav";
import SectionIntro from "../../../Parts/Sections/SectionIntro";
import Whats from "../ProductsWhats/Whats";
import Footer from "../../../Parts/Footer/Footer";
import ProductLanding from "../ProductPageParts/ProductLanding";
import SectionAngle from "../ProductPageParts/SectionAngle";
import ProductGraph from "../../Products/ProductGraph/ProductGraph";
import PricingGrid from "../../../Parts/PricingGrid/PricingGrid";
import SocialProofTab from "../../../Parts/SocialProof/socialProofTab";
import { Link, useParams } from "react-router-dom";
import Themes from "../ProductThemes/Themes";
import { useEffect } from "react";
import { Trans } from "react-i18next";

const ProductPage = () => {
  let { product } = useParams();
  const theme = Themes.find(
    (t) => t.product.toLowerCase() === product.toLowerCase()
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const colors = {
    squareDark: theme?.squareDark,
    squareLight: theme?.squareLight,
  };

  const companies = [
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684143976/geo_vxzoef.png",
      link: "https://www.geotab.com/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1656334858/VivaDrive/Logos/Partners/nissan2020_Black_qfjavj.png",
      link: "https://www.nissanusa.com/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684229430/ekoeene-removebg-preview_lgq6bv.png",
      link: "https://ekoenergetyka.com.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684227825/m_1_el7cde.png",
      link: "https://www.pzu.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684228475/alfenn-removebg-preview_ncwtot.png",
      link: "https://alfen.com/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684228296/poklo-removebg-preview_glcmbh.png",
      link: "https://www.pekaoleasing.com.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684228135/TSGlogg-removebg-preview_mbapib.png",
      link: "https://www.tsg-solutions.com/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684228930/Alior_Leasing_3_ut6gkg.jpg",
      link: "https://aliorleasing.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657545987/VivaDrive/Logos/Partners/OVHblack_tvdfup.png",
      link: "https://www.ovhcloud.com/pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684143479/eloc_cgx37a.png",
      link: "https://elo.city/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684143620/elep_letr90.png",
      link: "https://eleport.ee/en/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1657545321/VivaDrive/Logos/Partners/LogoPSPABlack_nmbcpk.png",
      link: "https://pspa.com.pl/",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1684228475/laff-removebg-preview_iyxsp1.png",
      link: "https://www.lafarge.pl/",
    },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657547052/VivaDrive/Logos/Partners/FRESHWORKSblack_vfyw9h.png",
    //   link: "https://www.freshworks.com/",
    // },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657546628/VivaDrive/Logos/Partners/KPTblack_uraxvs.png",
    //   link: "https://scaleup.kpt.krakow.pl/",
    // },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657546374/VivaDrive/Logos/Partners/XEuropeBlack_cwdbdz.png",
    //   link: "https://www.xeurope.eu/",
    // },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657548543/VivaDrive/Logos/Partners/ESAblack_lkxndp.png",
    //   link: "http://www.esa.int/",
    // },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657548126/VivaDrive/Logos/Partners/IMECblack_pl55wu.png",
    //   link: "https://www.imec-int.com/en",
    // },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657547738/VivaDrive/Logos/Partners/UHASSELTblack_n5ddcd.png",
    //   link: "https://www.uhasselt.be/",
    // },
    // {
    //   img: "https://res.cloudinary.com/efer/image/upload/v1657547320/VivaDrive/Logos/Partners/PILOTMAKERblack_xf2f7y.png",
    //   link: "https://www.tauron.pl/tauron/tauron-innowacje/wspolpraca-ze-startupami/pilot-maker",
    // },
  ];

  return (
    <div style={{ background: "var(--white)" }}>
      <Nav borderBottom={theme.mainColor} />
      <ProductNav
        product={theme.product}
        color={theme.mainColor}
        logo={theme.logo}
        tabs={theme.navTabs}
      />
      <ProductLanding data={theme.landingData} color={theme.mainColor} />
      <SocialProofTab
        companies={companies}
        id='partners'
        bg='var(--white)'
      />
      <SectionAngle
        id='why'
        intro={theme.introBenefits}
        columns={theme.benefits}
        colors={colors}
      />
      {/* <ProductGraph id='graph' data={theme.graph} intro={theme.introGraph} /> */}

      <Whats id='whats' intro={theme.introWhats} allWhats={theme.allWhats} />
      {theme.product === "EVRecommend" ? null : (
        <div className='section' style={{ background: theme.lightColor }}>
          <SectionIntro data={theme.introCosts} />
          <div className='costsGrid'>
            {theme.costs.map((item, key) => {
              return (
                <div style={{ textAlign: "center" }} key={key}>
                  <h1 style={{ color: theme.mainColor }}>{item.cost}</h1>
                  <h3 style={{ textAlign: "center" }}>{item.title}</h3>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div style={{ position: "relative" }}>
        <div className='squareWrapperTop'>
          <div
            className='square squareTop'
            style={{ backgroundColor: theme.squareLight }}
          ></div>
          <div
            className='square'
            style={{ backgroundColor: theme.squareDark }}
          ></div>
        </div>

        <div
          className='section reverseAngle'
          style={{ background: theme.mainColor, position: "relative" }}
        >
          <SectionIntro data={theme.contactUs} />
          <div style={{ padding: "1em 0", display: "flex" }}>
            <Link to={`/letswork/${theme.product}`}>
              <PrimaryButton
                text={<Trans i18nKey='description.start' />}
                color='var(--white)'
                textColor={theme.mainColor}
              />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProductPage;
