import Nav from "../../Components/Nav";
import { FeatureColored } from "../../Parts/PricingGrid/PricingGrid";
import Footer from "../../Parts/Footer/Footer";
import { useEffect } from "react";
import JoinTeamForm from "../../Components/Form/JoinTeamForm";
const JoinTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "var(--lightGrey)" }}>
      <Nav />
      <div className='section greenSection reverseAngle'>
        <h1 style={{ padding: "1em 0" }}>Join VivaDrive</h1>
      </div>
      <div className='section WorkWithUsGrid'>
        {GreatPoints}
        <div className='form withUsForm'>
          <JoinTeamForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JoinTeam;

const GreatPoints = (
  <div className='benefits'>
    <div style={{ display: "flex", margin: "2em 0" }}>
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <p>
        Have the know how to transform your fleet. Data driving with a green
        heart, we personalise a solution for you. We make it efficient and
        sustainable.
      </p>
    </div>
    <div style={{ display: "flex", margin: "2em 0" }}>
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <p>
        We work with you with the help of Big-Data, Ai power and our know how,
        to build the ideal solution for your needs and to you reach that goal.
      </p>
    </div>
    <div style={{ display: "flex", margin: "2em 0" }}>
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <p>
        Enjoy your emission-free journeys and upgrade your vehicles with more
        sustainable alternatives, keeping your drivers well-being and helping
        planet
      </p>
    </div>
    <div style={{ display: "flex", margin: "2em 0" }}>
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <p>
        Enhance your drivers’ safety on the road, use VivaDrive’s Ai solutions
        to ensure the efficient driving of your fleet and motivate everyone
        around it.
      </p>
    </div>
  </div>
);
