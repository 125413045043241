const Car = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <path
          d='M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z'
          fill='var(--backgroundGreenDark)'
        ></path>
        <g fill='var(--primaryGreen)'>
          <path d='M61.36522,50.74c-4.36815,0 -8.38212,2.19391 -10.70393,5.9029l-8.97241,14.40308l-7.87054,1.18058c-6.15869,0.88544 -10.78263,6.20789 -10.78263,12.43545v16.44942c0,4.16155 3.39417,7.55571 7.55571,7.55571h5.27326c1.2101,7.13267 7.3983,12.59286 14.87531,12.59286c7.47701,0 13.66522,-5.46018 14.87531,-12.59286h40.76937c1.2101,7.13267 7.3983,12.59286 14.87531,12.59286c7.47701,0 13.67506,-5.46018 14.87531,-12.59286h5.27326c4.16155,0 7.55571,-3.39417 7.55571,-7.55571v-14.16696c0,-6.17837 -4.44685,-11.34341 -10.54652,-12.35674l-20.77821,-3.46304l-11.49098,-15.34754c-2.36116,-3.15805 -6.12918,-5.03714 -10.07429,-5.03714zM61.36522,55.77714h14.56049v15.11143h-28.17652l7.24089,-11.56969c1.38718,-2.22343 3.75818,-3.54174 6.37513,-3.54174zM80.96286,55.77714h15.11143c2.36116,0 4.64362,1.10188 6.06031,2.9908l9.05112,12.12062h-30.22286zM50.74,96.07429c5.55857,0 10.07429,4.51572 10.07429,10.07429c0,5.55857 -4.51572,10.07429 -10.07429,10.07429c-5.55857,0 -10.07429,-4.51572 -10.07429,-10.07429c0,-5.55857 4.51572,-10.07429 10.07429,-10.07429zM121.26,96.07429c5.55857,0 10.07429,4.51572 10.07429,10.07429c0,5.55857 -4.51572,10.07429 -10.07429,10.07429c-5.55857,0 -10.07429,-4.51572 -10.07429,-10.07429c0,-5.55857 4.51572,-10.07429 10.07429,-10.07429z'></path>
        </g>
      </g>
    </svg>
  );
};

export default Car;
