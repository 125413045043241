const TeamMember = (data) => {
  const { intern, img, name, nationality, role, socials } = { ...data.data };
  return (
    <div className="singleTeamMember">
      <a
        href={socials[0].link}
        target="_blank"
        rel="noreferrer noopener"
        title="Team Member"
      >
        <div
          style={{
            width: "100%",
            height: "30vh",
            borderRadius: "var(--radius)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            className="memberImg"
            src={img}
            loading="lazy"
            alt={`Team Member:${name}, role: ${role}`}
          />
        </div>
        {intern && <p style={{ color: "var(--primaryGreen)" }}>Intern</p>}
        <div className="memberTextGrid">
          <h3
            style={{
              display: "block",
              fontWeight: "bolder",
              color: "var(--black)",
            }}
          >
            {name}
          </h3>
          <h5
            className="role"
            style={{ display: "block", color: "var(--textColor)" }}
          >
            {" "}
            {role}
          </h5>
        </div>
      </a>
    </div>
  );
};

export default TeamMember;
