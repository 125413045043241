import { Link } from "react-router-dom";
import { Button } from "../../Components/Buttons/Buttons";
import ButtonType from "../../Components/Buttons/ButtonTypes";
// import Icon from "../../Components/Icons/Icon"
import IconTypes from "../../Components/Icons/IconTypes";
import Nav from "../../Components/Nav";
import TeamSection from "../../Parts/Team/TeamSection";

const TeamPage = () => {
  return (
    <div>
      <Nav />
      <div className='section' style={{ paddingBottom: "0" }}>
        <center>
          <h1>Team</h1>
          <p style={{ marginBottom: "2em" }}>
            At VivaDrive we believe that collaboration is the best way to learn
            and do something meaningful.
          </p>
          <Link to='/join-team'>
            <Button
              type={ButtonType.SECONDARY}
              label='Join the team'
              color='var(--primaryGreen)'
              icon={IconTypes.ARROW}
            />
          </Link>
        </center>
      </div>
      <TeamSection />
    </div>
  );
};

export default TeamPage;
