const DigitalFleet = () =>{
    return <svg width="160" height="160" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
    <rect x="24" y="24" width="12" height="12" fill="#ED7576"/>
    <rect x="12" y="24" width="12" height="12" fill="#D75656"/>
    <rect x="12" y="12" width="12" height="12" fill="#C24A49"/>
    <rect y="24" width="12" height="12" fill="#C93833"/>
    <rect x="24" y="12" width="12" height="12" fill="#D75656"/>
    <rect x="24" width="12" height="12" fill="#C83C33"/>
    </g>
    </svg>
}

export default DigitalFleet;