import SectionIntro from "../../../Parts/Sections/SectionIntro";
import Column from "../../../Components/Column";
import Fade from 'react-reveal/Fade';

const SectionAngle = ({intro, columns, colors, id}) => {
    return  <div style={{position:"relative"}} id={id}>
                 <div className="squareWrapperTop">
                    <div className="square squareTop" style={{backgroundColor:colors.squareLight}}></div>
                    <div className="square" style={{backgroundColor:colors.squareDark}}></div>
                </div>
                <div className="section sectionAngle" style={{backgroundColor:intro.backgroundColor}}>
                    <SectionIntro data={intro} />
                    <div style={{display:"grid", gridTemplateColumns:" repeat( auto-fit, minmax(250px, 1fr) )",gap:"2em", marginTop: "2em"}}>
                        {columns.map((column, key) =>(
                            <Fade bottom delay={80 * key}>
                                <Column img={column.img} key={key} title={column.title} text={column.text} link={column.link} icon={column.icon} type={column.type} />
                            </Fade>
                       ))}
                    </div>
                </div>
            </div>
}

export default SectionAngle;