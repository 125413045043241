import { PrimaryButton } from "../../Components/Buttons/Buttons";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
// import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "react-i18next";
import SocialProofTab from "../../Parts/SocialProof/socialProofTab";

const HomepageLanding = () => {
  const lngs = {
    en: { nativeName: "English" },
    pl: { nativeName: "Polski" },
  };
  const { t, i18n } = useTranslation();

 const companies = [
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684143976/geo_vxzoef.png",
     link: "https://www.geotab.com/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1656334858/VivaDrive/Logos/Partners/nissan2020_Black_qfjavj.png",
     link: "https://www.nissanusa.com/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684229430/ekoeene-removebg-preview_lgq6bv.png",
     link: "https://ekoenergetyka.com.pl/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684227825/m_1_el7cde.png",
     link: "https://www.pzu.pl/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684228475/alfenn-removebg-preview_ncwtot.png",
     link: "https://alfen.com/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684228296/poklo-removebg-preview_glcmbh.png",
     link: "https://www.pekaoleasing.com.pl/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684228135/TSGlogg-removebg-preview_mbapib.png",
     link: "https://www.tsg-solutions.com/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684228930/Alior_Leasing_3_ut6gkg.jpg",
     link: "https://aliorleasing.pl/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1657545987/VivaDrive/Logos/Partners/OVHblack_tvdfup.png",
     link: "https://www.ovhcloud.com/pl/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684143479/eloc_cgx37a.png",
     link: "https://elo.city/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684143620/elep_letr90.png",
     link: "https://eleport.ee/en/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1657545321/VivaDrive/Logos/Partners/LogoPSPABlack_nmbcpk.png",
     link: "https://pspa.com.pl/",
   },
   {
     img: "https://res.cloudinary.com/efer/image/upload/v1684228475/laff-removebg-preview_iyxsp1.png",
     link: "https://www.lafarge.pl/",
   },
 ];

  return (
    <div>
      <div className='landingTextsWrapper'>
        <div className='landingTexts'>
          <Fade>
            <h4 className='bolder mainTitle textGreen'>
              <Trans i18nKey='description.homepage1' />
              <Trans i18nKey='description.homepage2' />
              <Trans i18nKey='description.homepage3' />
              <Trans i18nKey='description.homepage4' />
              <Trans i18nKey='description.homepage5' />
            </h4>
          </Fade>
          <Fade delay={100}>
            <h5>
              <span className='lighter'>
                <Trans i18nKey='description.homepage6' />
              </span>
            </h5>
          </Fade>
          <Fade delay={200}>
            <Link to='/work-with-us'>
              <PrimaryButton
                text={<Trans i18nKey='description.request' />}
                noPadding
                color='var(--primaryGreen)'
                textColor='var(--white)'
              />
            </Link>
          </Fade>
        </div>
        <div className='landingImageWrapper'>
          <img src='https://res.cloudinary.com/efer/image/upload/v1687879795/ecoem_xantsu-min_hyc40h_1_tzp4ie.png' />
        </div>
      </div>
      <SocialProofTab companies={companies} id='partners' bg='var(--white)' />
    </div>
  );
};

export default HomepageLanding;
