import Fade from "react-reveal/Fade";
import { Trans } from "react-i18next";

const Awards = () => {
  const awards = [
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1687885325/fd22_1_qzecdd.png",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1687884731/PTD_BLACK_1_1_hoatkk.png",
    },
    {
        img: "https://res.cloudinary.com/efer/image/upload/v1649340282/VivaDrive/Logos/Funds/49948119_2148365628561490_3794140428152864768_n-removebg-preview_xeybdm.png",
    },
    {
        img: "https://res.cloudinary.com/efer/image/upload/v1687885128/cesa-logotype_nm70rs.png",
    },
    {
      img: "https://res.cloudinary.com/efer/image/upload/v1687885325/fd23_1_ipa4dz.png",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "var(--lightGrey)",
        color: "var(--textColor)",
        textAlign: "center",
        margin: "4em 0",
      }}
    >
      <h4 style={{ margin: "1.5em" }}>
        <Trans i18nKey='description.awards' />
      </h4>
      <div className='awards'>
        {awards.map((award, key) => (
          <Fade bottom delay={80 * key} key={key}>
            <img src={award.img} />
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default Awards;
