import { PrimaryButton } from "../Buttons/Buttons";
import {
  handleApplicationsForm,
  handleChangeV2,
} from "../../Utils/HandleForm";
import { store } from "react-notifications-component";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import jobsObj from "../../HelperObjects/Jobs";
const ApplyForm = (props) => {
  const [form, setForm] = useState({
    first_name: {
      name: "First name",
      value: "",
      type: "text",
      required: true,
    },
    last_name: {
      name: "Last name",
      value: "",
      type: "text",
      required: true,
    },
    email: {
      name: "email",
      value: "",
      type: "email",
      required: true,
    },
    phone_number: {
      name: "Phone number",
      value: "",
      type: "number",
      required: false,
    },
    position: {
      name: "Position",
      value: props.role ? props.role : "",
      type: "select-one",
      required: true,
    },
    linkedin_link: {
      name: "Linkedin",
      value: "",
      type: "url",
      required: false,
    },
    githublink: {
      name: "Github",
      value: "",
      type: "url",
      required: false,
    },
    portfolio_link: {
      name: "Portfolio",
      value: "",
      type: "url",
      required: false,
    },
    cover_letter: {
      name: "Cover letter",
      value: null,
      type: "file",
      required: false,
    },
    cv: {
      name: "CV",
      value: null,
      type: "file",
      required: true,
    },
  });

  const [captcha, setCaptcha] = useState(false);
  const notification = (title, message, type) => {
    console.log(title, message, type);
    return {
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    };
  };

  function onChange(value) {
    setCaptcha(!captcha);
  }

  function captchaNForm(e, store, form) {
    handleApplicationsForm(e, store, form, captcha);
  }

  return (
    <form action=''>
      <label htmlFor='name'>First name *</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='text'
        required
        name='first_name'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='name'>Last name * </label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='text'
        required
        name='last_name'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='email'>Email * </label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='email'
        required
        name='email'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='name'>Phone number</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='number'
        required
        name='phone_number'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />

      <label htmlFor='company name'>Role * </label>
      <select
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        value={props.role ? props.role : ""}
        name='position'
        id=''
        onChange={(e) => handleChangeV2(e, setForm, form)}
      >
        <option value=''> </option>
        {jobsObj.map((job) => {
          return <option value={job.slug}>{job.position} </option>;
        })}
      </select>
      <label htmlFor='company name'>Linkedin</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='url'
        placeholder='https://vivadrive.io'
        pattern='https://.*'
        name='linkedin_link'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='company name'>Github</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='url'
        placeholder='https://vivadrive.io'
        pattern='https://.*'
        name='githublink'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='company name'>Portfolio</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='url'
        placeholder='https://vivadrive.io'
        pattern='https://.*'
        name='portfolio_link'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='company name'>Cover letter</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='file'
        id='cover_letter'
        name='cover_letter'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />
      <label htmlFor='company name'>CV *</label>
      <input
        style={{ backgroundColor: props.inputs ? props.inputs : "" }}
        type='file'
        id='cv'
        name='cv'
        onChange={(e) => handleChangeV2(e, setForm, form)}
      />

      {/* <label htmlFor="message">Any question?</label>
                <textarea name="textarea" onChange={(e) => handleChangeV2(e, setForm, form)}/> */}
      <ReCAPTCHA
        sitekey='6LemYDUbAAAAALQlr_DqeLeod97-5ipDDdUL3TCR'
        onChange={onChange}
      />
      <center style={{ paddingTop: "2em" }}>
        <PrimaryButton
          text='Send'
          textColor='var(--black)'
          color='var(--primaryGreen)'
          onClick={(e) => captchaNForm(e, store, form)}
        />
      </center>
    </form>
  );
};

export default ApplyForm;
