const Career = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40'
      height='40'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <g fill='var(--primaryGreen)'>
          <path d='M86,22.93333c-12.16095,0 -21.90285,4.40872 -28.12917,12.09375c-1.93852,-0.36707 -3.98278,-0.62708 -6.27083,-0.62708c-9.84413,0 -12.9,6.45546 -12.9,6.4612c-8.80067,0 -12.9,8.9096 -12.9,19.35c0,5.2116 2.27318,9.51823 2.27318,9.51823c-0.9116,0.52173 -2.41642,2.18691 -2.03802,5.15104c0.7052,5.51547 3.08847,6.90642 4.61354,7.02109c0.57907,5.14853 7.54677,11.74097 9.48464,12.69844v8.6c-4.3,12.9 -40.13333,4.3 -40.13333,34.4h22.93333c0,6.33533 5.13133,11.46667 11.46667,11.46667h103.2c6.33533,0 11.46667,-5.13133 11.46667,-11.46667h22.93333c0,-30.1 -35.83333,-21.5 -40.13333,-34.4v-8.6c1.93787,-0.95747 8.90557,-7.54991 9.48464,-12.69844c1.52507,-0.11467 3.90834,-1.50562 4.61354,-7.02109c0.3784,-2.96413 -1.12642,-4.62931 -2.03802,-5.15104c0,0 2.27317,-4.30663 2.27317,-9.51823c0,-10.4404 -4.09933,-19.35 -12.9,-19.35c0,-0.00573 -3.05587,-6.4612 -12.9,-6.4612c-2.61226,0 -4.9177,0.32135 -7.07708,0.79505c-2.55473,-2.51761 -5.57029,-4.4065 -9.04792,-5.375c-3.95027,-3.8012 -10.05913,-6.88672 -18.275,-6.88672zM86,34.4c9.26507,0 12.13854,6.06927 12.13854,6.06927c8.28467,0 12.14974,8.39208 12.14974,18.21901c0,4.902 -2.1388,8.95833 -2.1388,8.95833c0.86,0.49307 2.27031,2.05656 1.91485,4.8487c-0.66507,5.1944 -2.91146,6.50904 -4.34479,6.61797c-0.5504,4.83893 -6.42966,11.71992 -8.25286,12.62005v13.49349c4.04773,12.1432 40.13333,4.04477 40.13333,32.37317h-103.2c0,-28.3284 36.0856,-20.23571 40.13333,-32.37317v-13.49349c-1.8232,-0.90013 -7.7082,-7.77539 -8.25286,-12.62005c-1.43333,-0.10893 -3.67973,-1.4293 -4.34479,-6.61797c-0.35547,-2.7864 1.06604,-4.35563 1.92604,-4.8487c0,0 -2.1388,-4.50326 -2.1388,-8.96953c0,-13.26693 7.11148,-24.27708 24.27708,-24.27708z'></path>
        </g>
      </g>
    </svg>
  );
};

export default Career;
