import Fade from "react-reveal/Fade";
import { Trans } from "react-i18next";
import { t } from "i18next";

const EUFundsSection = () => {
  const companies = [
    {
      img: t("description.footer1"),
    },
    {
      img: t("description.footer2"),
    },
    {
      img: t("description.footer3"),
    },
    {
      img: t("description.footer4"),
    },
    {
      img: t("description.footer5"),
    },
    {
      img: t("description.footer6"),
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "var(--lightGrey)",
        color: "var(--textColor)",
        textAlign: "center",
        margin: "4em 0",
      }}
    >
      <h4 style={{ margin: "1.5em" }}>
        <Trans i18nKey="description.eu" />
      </h4>
      <div className="euCompanies">
        {companies.map((company, key) => (
          <Fade bottom delay={80 * key} key={key}>
            <img src={company.img} />
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default EUFundsSection;

//  className="section sectionAngle"
//       style={{ backgroundColor: 'var(--white)', paddingBottom: "0" }}
