const PrivacyPolicyPolish = {
  MainTitle: "POLITYKA PRYWATNOŚCI",
  onlyInPolish:
    "VivaDrive Polska sp.z o.o. szanuje prywatność wszystkich osób, z jakimi utrzymuje kontakty, niezależnie od tego, czy dane osobowe są przetwarzane przez VivaDrive Polska  sp. z o.o. na własny użytek (jako administratora danych osobowych), czy w imieniu innych podmiotów. Bardzo ważne jest dla nas, abyś zrozumiał jakie dane osobowe przetwarzamy, w jakim celu to robimy, na jakiej podstawie i jakie posiadasz prawa z tym związane.",
  title: "Informacja o przetwarzaniu danych osobowych",
  mainText:
    "Uprzejmie informujemy, że spółka VivaDrive Polska sp. z o.o., ul. Dobra 56/66, 00-312 Warszawa, NIP: 7010929600, KRS 0000789919 (dalej: „Spółka” lub „Administrator”) przetwarza Pana/Pani dane osobowe w rozumieniu Rozporządzenia Parlamentu Europejskiego i Rady Unii Europejskiej 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: „RODO”).",
  listTitle:
    "Celem wypełnienia obowiązku informacyjnego określonego art. 13 i art. 14 RODO informujemy, że:",
  list: [
    {
      id: 1,
      text: "Administratorem danych osobowych jest spółka pod firmą VivaDrive Polska sp. z o.o.",
    },
    {
      id: 2,
      text: "Z Administratorem można się kontaktować:",
      subtext: [
        {
          id: "a",
          text: "pisemnie, na adres: VivaDrive Polska spółka z ograniczoną odpowiedzialnością, ul. Dobra 56/66, 00-312 Warszawa",
        },
        {
          id: "a",
          text: "za pomocą poczty elektronicznej, na adres: ",
          email: "bok@vivadrive.io",
        },
      ],
    },
    {
      id: 3,
      text: "Administrator nie wyznaczył Inspektora Ochrony Danych,",
    },
    {
      id: 4,
      text: 'Pani/Pana dane osobowe są przetwarzane w celu świadczenia usługi drogą elektroniczną (“Usługa”lub “Usługi” ), w szczególności, podczas przeglądania strony internetowej VivaDrive (<a href="https://vivadrive.io/" target="_blank" >Strona VivaDrive</a>,  <a href="https://digitalfleet.eu/" target="_blank" >Strona Digital Fleet </a>) (“Strona Internetowa”, “Strona Internetowa”), utrzymania konta w serwisach Digital Fleet ( <a href="https://digitalfleet.eu/" target="_blank" >System Digital Fleet </a>) i  EVRecommend <a href="https://digitalfleet.eu/" target="_blank" >(System EVRecommend)</a> (“Serwisy”) oraz utrzymania konta w aplikacjach (“Aplikacje”) Digital Fleet <a href="https://play.google.com/store/apps/details?id=pl.digitalfleet" target="_blank"> (Aplikacja Digital Fleet) </a> (“Aplikacja DF”), Digital Fleet Mobile (“Aplikacja DFM”), oraz LafargeiVMS (“Aplikacja iVMS”), wykonywania zawartych umów, w tym uiszczania zapłaty za usługi, przesyłania powiadomień o realizacji zamówień, realizacji procesu reklamacji oraz zapewnienia prawidłowej obsługi klienta, dopasowania oferty reklamowej do potrzeb klientów,prezentacji reklam i przesyłania informacji handlowych, obsługi zgłoszeń i reklamacji, a także umożliwienia kontaktu pomiędzy kupującymi a sprzedającym',
    },
    {
      id: 5,
      text: "podstawą prawną przetwarzania danych osobowych może być wyrażona przez Pana/Panią zgoda (art. 6 ust. 1 lit. a RODO), konieczność podjęcia działań na Pana/Pani żądanie osoby (art. 6 ust. 1 lit. b RODO) lub też cele związane z prawnie uzasadnionym interesem realizowanym przez administratora lub przez stronę trzecią (art. 6 ust. 1 lit. f RODO). Prawnie uzasadnionym interesem administratora jest konieczność oferowania klientom możliwie najlepszej jakości obsługi, dopasowania oferty Usług do potrzeb i oczekiwań kupujących, a także prowadzenie działań marketingowych, promocyjnych i reklamowych,",
    },
    {
      id: 6,
      text: "źródłem pochodzenia danych jest wypełniony przez Państwa formularz oraz Państwa aktywność w Serwisach, na Stronach Internetowych oraz Aplikacji Digital Fleet, także wynikająca z korzystania ze świadczonych Usług,",
    },
    {
      id: 7,
      text: "odbiorcami Pana/Pani danych osobowych mogą być podmioty współpracujące z  Administratorem w wykonywaniu zadań określonych w pkt 4, w szczególności przedsiębiorstwa będące podwykonawcami, obsługujące procesy niezbędne do przesyłania powiadomień o realizacji zamówień, usługodawcy świadczący usługi reklamowe i marketingowe, a także w zakresie rozliczeń należnościs",
    },
    {
      id: 8,
      text: "Pana/Pani dane osobowe mogą być przekazywane do państw trzecich, tj. poza Europejski Obszar Gospodarczy (EOG) lub do organizacji międzynarodowych, jednak wyłącznie do podmiotów, które zapewniają odpowiednie zabezpieczenia oraz gwarantują prawa osób, których dane dotyczą, i skuteczne środki ochrony prawnej. W szczególności obejmuje to korzystanie przez Administratora z usług świadczonych przez Google oraz Facebook, w takim zakresie, w jakim jest to niezbędne dla realizacji celów wskazanych w pkt 4",
    },
    {
      id: 9,
      text: "Pani/Pana dane osobowe będą przetwarzane przez Administratora przez okres niezbędny do świadczenia usług, tj. do czasu rozwiązania lub wypowiedzenia umowy, lub w innych celach wskazanych w pkt 4 powyżej,",
    },
    {
      id: 10,
      text: "ma Pan/Pani prawo do:",
      subtext: [
        {
          id: "a",
          text: "żądania od Administratora dostępu do swoich danych osobowych",
        },
        {
          id: "b",
          text: "żądania od Administratora sprostowania swoich danych osobowych,",
        },
        {
          id: "c",
          text: "żądania od Administratora usunięcia lub ograniczenia przetwarzania swoich danych osobowych",
        },
        {
          id: "d",
          text: "wniesienia do Administratora sprzeciwu wobec przetwarzania danych osobowych w celu marketingu bezpośredniego,",
        },
        {
          id: "e",
          text: "wniesienia do Administratora sprzeciwu wobec profilowania w zakresie, w jakim przetwarzanie jest związane z marketingiem bezpośrednim",
        },
        {
          id: "f",
          text: "cofnięcia zgody na przetwarzanie swoich danych osobowych,",
        },
        {
          id: "g",
          text: "wniesienia skargi do organu nadzorczego,",
        },
      ],
    },

    {
      id: 11,
      text: "informujemy, że nie ma Pan/Pani ustawowego obowiązku podania danych osobowych, jednakże obowiązek ten może wynikać z przepisów szczególnych. Konsekwencją niepodania danych osobowych może być w szczególności brak możliwości świadczenia Usług na Pana/Pani rzecz, w szczególności brak możliwości zawarcia umowy sprzedaży towarów w sklepie internetowym lub korzystania z Serwisów i Aplikacji",
    },

    {
      id: 12,
      text: "w zakresie, w jakim przetwarzane dane nie zostały pozyskane od Państwa, mają Państwo prawo do wniesienia skargi do organu nadzorczego – tj. Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa",
    },
  ],
  personalDataCollector: {
    title: "Administrator danych osobowych",
    text: "Administratorem danych osobowych podawanych za pośrednictwem Stron Internetowych, Serwisów oraz Aplikacji jest VivaDrive Polska Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, ul. Dobra 56/66, 00-312 Warszawa, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000789919, REGON 383580710, NIP 7010929600, o kapitale zakładowym opłaconym w całości w wysokości 5.000,00 zł („Administrator”). Dane kontaktowe Administratora: bok@vivadrive.io",
  },
  cookies: {
    link: "cookies",
    title: "Pliki cookies",
    text: "Serwis zbiera w sposób automatyczny tylko informacje zawarte w plikach cookies. Pliki (cookies) są plikami tekstowymi, które przechowywane są w urządzeniu końcowym użytkownika serwisu. Przeznaczone są do korzystania ze Stron Internetowych. Przede wszystkim zawierają nazwę strony  internetowej swojego pochodzenia, swój unikalny numer, czas przechowywania na urządzeniu końcowym.",
    listText: "Administrator wykorzystuje pliki cookies w celu:",
    list: [
      "dopasowania zawartości Stron Internetowych do indywidualnych preferencji użytkownika, przede wszystkim pliki te rozpoznają jego urządzenie, aby zgodnie z jego preferencjami wyświetlić stronę;",
      "przygotowywania statystyk pomagających poznaniu preferencji i zachowań użytkowników, analiza tych statystyk jest anonimowa i umożliwia dostosowanie zawartości i wyglądu serwisu do panujących trendów, statystyki stosuje się też do oceny popularności strony;",
      "możliwości logowania do Serwisów",
      "utrzymania logowania użytkownika na każdej kolejnej stronie Serwisów",
    ],
    endText: [
      "Serwisy i Strony Internetowe stosują dwa zasadnicze rodzaje plików (cookies) – sesyjne i stałe. Pliki sesyjne są tymczasowe, przechowuje się je do momentu opuszczenia strony serwisu (poprzez wejście na inną stronę, wylogowanie lub wyłączenie przeglądarki). Pliki stałe przechowywane są w urządzeniu końcowym użytkownika do czasu ich usunięcia przez użytkownika lub przez czas wynikający z ich ustawień.",
      "Użytkownik może w każdej chwili dokonać zmiany ustawień swojej przeglądarki, aby zablokować obsługę plików (cookies) lub każdorazowo uzyskiwać informacje o ich umieszczeniu w swoim urządzeniu. Inne dostępne opcje można sprawdzić w ustawieniach swojej przeglądarki internetowej. Należy pamiętać, że większość przeglądarek domyślnie jest ustawione na akceptację zapisu plików (cookies) w urządzeniu końcowym.",
      "Administrator Serwisów i Stron Internetowych informuje, że zmiany ustawień w przeglądarce internetowej użytkownika mogą ograniczyć dostęp do niektórych funkcji strony internetowej serwisu.",
      "Pliki cookies, z których korzystają Serwisy i Strony Internetowe (zamieszczane w urządzeniu końcowym użytkownika) mogą być udostępnione jego partnerom oraz współpracującym z nim reklamodawcą.",
      "Informacje dotyczące ustawień przeglądarek internetowych dostępne są w jej menu (pomoc) lub na stronie jej producenta.",
    ],
  },
  PersonalData: {
    link: "personal_data",
    title: "Dane osobowe",
    text: "Użytkownik może zostać poproszony o podanie swoich danych osobowych korzystając z Serwisów oraz Aplikacji. Administrator oświadcza, że będzie wykorzystywał te dane tylko i wyłącznie w celach związanych z funkcjonowaniem Serwisów lub w celu świadczenia Usług. Usługami są przede wszystkim świadczone przez Administratora usługi zarządzania flotą pojazdów spalinowych i elektrycznych, a także dostęp do Aplikacji dostępnych w domenach Administratora (https://digitalfleet.eu/, https://vivadrive.io).",
  },
  purpose: {
    link: "purpose",
    title: "Cele przetwarzania danych",
    listText:
      "Użytkownik chce skorzystać z formularza kontaktowego zamieszczonego w Serwisie,",
    list: [
      "The User wants to use the contact form on the Websites,",
      "Użytkownik chce, aby Administrator skontaktował się z nim w celu marketingu bezpośredniego dotyczącego oferowanych produktów i usług, wykonywanego przy użyciu telekomunikacyjnych urządzeń końcowych oraz automatycznych systemów wywołujących,",
      "Użytkownik korzysta z Usług Administratora za pośrednictwem Serwisów i Aplikacji.",
      "Użytkownik wyrazi zgodę na otrzymywanie informacji marketingowych drogą elektroniczną.",
    ],
  },
  dataProcessing: {
    link: "data_processing",
    title: "Przetwarzanie danych",
    text: "Administrator stosuje środki organizacyjne i techniczne zgodne z właściwymi przepisami prawa (zwłaszcza z RODO), a także poprzez szyfrowanie połączenia za pomocą certyfikatu SSL.",
    listText: "Administrator przetwarza następujące dane Użytkownika:",
    list: [
      "imię, nazwisko, adres e-mail, adres IP oraz numer telefonu w przypadku skorzystania przez Użytkownika z formularza kontaktowego w Serwisie lub w celu marketingu bezpośredniego dotyczącego oferowanych produktów i usług, wykonywanego przy użyciu telekomunikacyjnych urządzeń końcowych oraz automatycznych systemów wywołujących,",
      "imię, nazwisko, adres e-mail, adres IP, numer telefonu oraz dane jego firmy, a w szczególności: liczba pojazdów we flocie, informacje o pojazdach i kierowcach, informacje o rozliczeniach, faktury zakupowe dotyczące kosztów we flocie pojazdów, w przypadku gdy Użytkownik korzysta z Serwisów Administratora,.",
    ],
    endText:
      "Poniższe informacje dotyczą tylko Aplikacji DFM i Aplikacji iVMS. Gdy korzystasz z aplikacji DFM i aplikacji iVMS, VivaDrive będzie gromadzić informacje o Twoim stylu jazdy i informacjach, takich jak lokalizacja, prędkość, przyspieszenie, hamowanie i pokonywanie zakrętów, i połączy je informacjami o pogodzie, natężeniu ruchu, porach dnia i innymi danymi kontekstowymi. Zbierane dane obejmują również współrzędne GPS. Korzystanie z tych danych opiera się na wyrażeniu przez Państwa wyraźnej zgody na takie profilowanie podczas rejestracji w  Aplikacji DFM i Aplikacji iVMS",
  },
  legalBasis: {
    link: "legal",
    title: "Podstawa prawna przetwarzania danych osobowych",
    listText:
      "Dane osobowe Użytkownika przetwarzane są tylko i wyłącznie wtedy, gdy Administrator posiada ku temu podstawę prawną, tj.:",
    list: [
      "umowa o świadczenie Usług lub podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem tej umowy (art. 6 ust. 1 lit. b RODO).",
      "obowiązek prawny związany z rachunkowością w przypadku odpłatnych usług (art. 6 ust. 1 lit. c RODO).",
      "prawnie uzasadniony interes Administratora, polegający na przetwarzaniu danych osobowych w celu ustalenia, dochodzenia lub obrony ewentualnych roszczeń związanych z Serwisem, w tym świadczonymi Usługami (art. 6 ust. 1 lit. f RODO).",
      "prawnie uzasadniony interes, polegający na zbieraniu danych w celach statystycznych oraz analitycznych co pozwala nam na optymalizację naszego Serwisu (art. 6 ust. 1 lit. f RODO).",
      "zgoda Użytkownika wyrażona w Serwisie na przetwarzanie danych (art. 6 ust. 1 lit. a RODO). Użytkownik może w każdej chwili wycofać lub ograniczyć zgodę na przetwarzanie danych osobowych przez Administratora. Przetwarzanie danych do momentu cofnięcia przez Użytkownika zgody pozostaje zgodne z prawem.",
    ],
  },
  provindgPersonalData: {
    link: "provide_personal",
    title: "Podanie danych",
    text: "Użytkownik podaje dane osobowe na Stronach Internetowych, w Serwisach i w Aplikacjach dobrowolnie. Podanie danych jest konieczne w sytuacji, gdy Użytkownik ma zamiar lub korzysta z Usług lub chce otrzymywać informacje marketingowe drogą elektroniczną.",
  },
  userRight: {
    link: "user_right",
    title: "Prawa Użytkownika",
    listText:
      "Użytkownik ma prawo do dostępu, sprostowania, żądania usunięcia lub żądania ograniczenia przetwarzania danych swoich danych osobowych. Użytkownik ma także prawo do wniesienia sprzeciwu wobec przetwarzania, w sytuacji gdy:",
    list: [
      "z przyczyn związanych ze szczególną sytuacją Użytkownika – wobec przetwarzania dotyczących danych osobowych opartego na art. 6 ust. 1 lit. f RODO (tj. na prawnie uzasadnionych interesach realizowanych przez administratora), w tym profilowania na podstawie tych przepisów,",
      "eżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.",
    ],
    endText:
      "Jeżeli Użytkownik uzna, że jego dane przetwarzane są niezgodnie z prawem, ma prawo złożyć skargę do organu nadzorczego. Aby skorzystać ze swoich praw, Użytkownik powinien powiadomić Administratora o tym fakcie pocztą elektroniczną wysyłając wiadomość na adres bok@vivadrive.io",
  },
  dataRecipients: {
    link: "data_recipients",
    title: "Odbiorcy danych",
    listText: "Odbiorcami danych przetwarzanych przez Administratora są:",
    list: [
      "firmy hostingowe zapewniające Administratorowi miejsce na serwerach",
      "dostawcy usług internetowych,",
      "dostawcy oprogramowania do obsługi klientów oraz potencjalnych klientów w związku ze świadczonymi przez Administratora Usługami,",
      "dostawcy platform płatniczych za pośrednictwem, których Użytkownik może opłacić dostęp do Usług",
      "podmioty dostarczające oprogramowanie do wysyłania wiadomości marketingowych",
      "podmioty przetwarzające dane w naszym imieniu t.j. firmy świadczące usługi prawne, księgowe czy obsługi klienta.",
    ],
    endText: [
      "Takie podmioty będą przetwarzać dane wyłącznie na podstawie stosownych umów i jedynie zgodnie znaszymi instrukcjami. Od naszych partnerów wymagamy zachowania poufności oraz zapewnienia odpowiednich środków bezpieczeństwa.",
      "Twoje dane mogą być również udostępniane podmiotom uprawnionym do ich uzyskania na podstawie obowiązującego prawa, np. organom ścigania.",
      "eżeli wyraziłeś chęć skorzystania z dodatkowych usług akceptując odpowiednią zgodę lub regulamin dodatkowych usług w ramach modułu Marketplace, Twoje dane osobowe mogą zostać przekazane naszym Partnerom w związku z przedmiotem naszej współpracy. Lista naszych partnerów znajduje się pod adresem www.vivadrive.io/partners.",
      "Ze względu na wykorzystywanie w naszych Serwisach i Aplikacjach narzędzi analitycznych od dostawców działających pod markami Google oraz Facebook, podmioty te mogą mieć dostęp do danych charakteryzujących sposób korzystania z usług świadczonych drogą elektroniczną (tzw. dane eksploatacyjne), które mogą być wykorzystywane w celach marketingowych.",
    ],
  },
  dataProcessingPeriod: {
    link: "data_processing",
    title: "Okres przetwarzania danych",
    listText:
      "Dane Użytkownika będą przetwarzane przez Administratora tylko przez okres, w którym Administratora będzie mieć ku temu podstawę prawną, a więc do momentu, w którym:",
    list: [
      "Dane Użytkownika będą przetwarzane przez Administratora tylko przez okres, w którym Administratora będzie mieć ku temu podstawę prawną, a więc do momentu, w którym:",
      "ustanie możliwość dochodzenia ewentualnych roszczeń związanych w związku z współpracą przez każdą ze stron lub",
      "Użytkownik cofnie zgodę na przetwarzanie danych, jeśli to ona była jego podstawą lub",
      "zostanie przyjęty sprzeciw Użytkownika wobec przetwarzania danych osobowych, w przypadku gdy podstawą przetwarzania tych danych był uzasadniony interes Administratoraclub dane były przetwarzane w celu marketingu bezpośredniego,cw zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi najpóźniej",
    ],
  },
  finalText:
    "depending on what is applicable in a given case and what will happen at the latest.",
  version: 1.1,
  verson_name: "Wersja",
  place: "Warszawa",
  date: "19/10/2021",
};

export default PrivacyPolicyPolish;
