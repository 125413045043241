import SectionIntro from "../Sections/SectionIntro";
import TeamMember from "./TeamMember";
import Member from "./NewMemberClass";
import { useState, useEffect } from "react";
import { TeamList } from "../../Parts/Team/TeamList";
import Fade from "react-reveal/Fade";

const TeamSection = ({ intro, id }) => {
  const [members, setMembers] = useState(null);
  const [coreTeam, setCoreTeam] = useState();

  useEffect(() => {
    const core = TeamList.filter((member) => {
      if (member.level === "coreTeam") return member;
      return null;
    });
    setCoreTeam(core);
  }, []);
  useEffect(() => {
    setMembers(coreTeam);
  }, [coreTeam]);

  return (
    <section className="section" id={id}>
      <SectionIntro data={intro} />
      <div className="teamGrid">
        {members &&
          members?.map((member, key) => {
            const newMember = new Member(
              member.intern,
              member.img,
              member.name,
              member.role,
              member.nationality,
              member.socials
            );
            return (
              <Fade delay={20 * key} key={key}>
                <TeamMember data={newMember} />
              </Fade>
            );
          })}
      </div>
      <center style={{ marginTop: "2em" }}></center>
    </section>
  );
};

export default TeamSection;
