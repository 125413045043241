const EVRLogo = () => {
    return <svg width="150" height="150" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<rect width="12" height="12" transform="matrix(-1 0 0 1 12 24)" fill="#C3FB7F"/>
<rect width="12" height="12" transform="matrix(-1 0 0 1 24 24)" fill="#7DF21C"/>
<rect width="12" height="12" transform="matrix(-1 0 0 1 24 12)" fill="#62E40A"/>
<rect width="12" height="12" transform="matrix(-1 0 0 1 36 24)" fill="#52CA00"/>
<rect width="12" height="12" transform="matrix(-1 0 0 1 12 12)" fill="#89F224"/>
<rect width="12" height="12" transform="matrix(-1 0 0 1 12 0)" fill="#6BD61A"/>
</g>
</svg>
}

export default EVRLogo;