import IsEmail from "./IsEmail";
import service from "../Service/Service";

const notification = (title, message, type) => {
  console.log(title, message, type);
  return {
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  };
};

const removeEmptySpace = (string) => {
  return string.replace(/\s/g, "").length;
};

function handleForm(e, store, form) {
  e.preventDefault();
  const email = IsEmail(form.email);
  console.log(form);
  if (form.name === "" || removeEmptySpace(form.name) === 0)
    return store.addNotification(
      notification(
        "Name required!",
        "fill the name field before sending",
        "warning"
      )
    );
  if (email == null)
    return store.addNotification(
      notification(
        "Email required!",
        "fill the email field before sending",
        "warning"
      )
    );

  let message = "MESSAGE: " + form.message;
  if (form.service) {
    message += ", SERVICE: " + form.service;
  }
  if (form.cars) {
    message += ", CARS AMOUNT: " + form.cars;
  }

  const data = {
    name: form.name,
    email_id: form.email,
    company_name: form.company,
    phone_number: "",
    message: message,
    project: "VivaDrive website ",
  };

  //   form is valid and can be sended
  service
    .sendForm(data)
    .then((res) => {
      store.addNotification(
        notification("Success", "Submission succesfull", "success")
      );
    })
    .catch((err) => {
      store.addNotification(notification("Error", err.message, "danger"));
    });
}

function handleChange(e, setState, state) {
  const target = e.target;
  const name = target.name;

  setState({ ...state, [name]: target.value });
  console.log(state);
}
function handleChangeV2(e, setState, state) {
  const target = e.target;
  const name = target.name;

  setState({
    ...state,

    [name]: {
      name: state[name].name,
      value: target.type === "file" ? target.files[0] : target.value,
      type: target.type,
    },
  });
  console.log(state);
}

function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}

function validations(item, store) {
  const type = item.type;
  console.log("itemmmmm ", item);

  switch (type) {
    case "text":
      if (item.value === "" || removeEmptySpace(item.value) === 0)
        return store.addNotification(
          notification(
            `${item.name} required!`,
            "Fill your first name before sending",
            "warning"
          )
        );
      return true;
    case "textarea":
      if (item.value === "" || removeEmptySpace(item.value) === 0)
        return store.addNotification(
          notification(
            `${item.name} required!`,
            "Fill your first name before sending",
            "warning"
          )
        );
      return true;
    case "email":
      const email = IsEmail(item.value);
      if (email == null || email == false)
        return store.addNotification(
          notification(
            "Email required!",
            "Fill the email field before sending",
            "warning"
          )
        );
      return true;
    case "number":
      // if( item.value === ""  || removeEmptySpace(item.value) === 0 ) return store.addNotification(notification("Phone number required!", "Fill the phone number field with a valid number before sending", "warning"));
      return true;
    case "select-one":
      if (item.value === "" || removeEmptySpace(item.value) === 0)
        return store.addNotification(
          notification(
            `${item.name} required!`,
            "Fill your first name before sending",
            "warning"
          )
        );
      return true;
    case "url":
      if (!item.value === "" || removeEmptySpace(item.value) !== 0) {
        const urlFormat = RegExp(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        );
        if (item.value.match(urlFormat) === null)
          return store.addNotification(
            notification(
              `${item.name} url invalid!`,
              "Fill with a valid url before sending",
              "warning"
            )
          );
      }
      return true;
    case "file":
      if (item.value !== null) {
        const size = fileSize(item.value, store);
        if (size > 20)
          return store.addNotification(
            notification(
              `File size too large!`,
              "Fill your first name before sending",
              "warning"
            )
          );
        // verify the type of file PDF and Word only accepted
        console.log("type:", item.value.type.split("/")[1]);
        if (
          !item.value.type.split("/")[1] === "pdf" ||
          !item.value.type.split("/")[1] === "word"
        )
          return store.addNotification(
            notification(
              `File type is not recognized!`,
              "Send a PDF or word document",
              "warning"
            )
          );
      } else {
        if (item.required === true)
          return store.addNotification(
            notification(
              `File for ${item.name} Missing!`,
              "Send a PDF or word document",
              "warning"
            )
          );
      }

      return true;
    default:
      console.log(`Sorry, we dont know the type ${type}.`);
  }
}

function handleApplicationsForm(e, store, form, captcha) {
  e.preventDefault();
  let validated = true;
  Object.keys(form).map((item) => {
    const val = validations(form[item], store);
    if (val !== true) {
      validated = false;
    }
  });
  if (validated) {
    //   form is valid and can be sended
    if (!captcha)
      return store.addNotification(
        notification("Are you a robot?", "Check the captcha box", "warning")
      );
    // let data = {
    //     first_name: "",
    //     last_name: "",
    //     email: "",
    //     phone_number: "",
    //     position: "",
    //     linkedin_link: "",
    //     githublink: "",
    //     portfolio_link: "",
    //     cover_letter: null,
    //     cv: null
    // }
    let FD = new FormData();

    Object.keys(form).map((item) => {
      console.log("itemzinho", form[item]);
      if (
        item === "linkedin_link" ||
        item === "githublink" ||
        item === "portfolio_link"
      ) {
        const urlFormat = RegExp(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        );
        if (
          form[item].value === "" ||
          removeEmptySpace(form[item].value) === 0 ||
          form[item].value.match(urlFormat) === null
        ) {
          // console.log("vazio")
          // console.log("fds",item, form[item].value)
          // FD.append(item, form[item].value)
          // FD.append('name', 'ABC');
          FD.append(item, "https://vivadrive.io/");
        }
        // FD.append(item, form[item].value);
      } else {
        if (item === "cv" || item === "cover_letter") {
          if (form[item].value === null) {
            FD.append(item, '');
          } else {
            console.log("itme", form[item]);
            FD.append(item, form[item].value);
          }
        } else {
          FD.append(item, form[item].value);
        }
      }
    });
    // for (var key of FD.keys()) {
    //     console.log("FD key:", key)
    //  }
    for (var value of FD) {
      console.log("FD value:", value);
    }

    service
      .sendApplyForm(FD)
      .then((res) => {
        console.log(res);
        store.addNotification(
          notification("Success", "Submission succesfull", "success")
        );
      })
      .catch((err) => {
        console.log("erro: ", err);
        store.addNotification(notification("Error", err.message, "danger"));
      });
  }
  // const formData = new FormData();
  //   formData.append("type", "Test data");
  //   formData.append("model_image", preview[i]);
  //   formData.append("appvehicle", props.pk);
  //   axios({
  //     method: "POST",
  //     url: config.auth._testData,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     data: formData,

  // const data = {
  //     "first_name": "",
  //     "last_name": "",
  //     "email": "",
  //     "phone_number": "",
  //     // "university": "",
  //     "postion": "",
  //     "linkedin_link": "",
  //     "githublink": "",
  //     "portfolio_link": "",
  //     "cover_letter": null,
  //     "cv": null
  // }
}

function fileSize(input) {
  const fileSize = input.size / 1024 / 1024; // in MiB
  return fileSize;
}

export { handleChange, handleForm, handleChangeV2, handleApplicationsForm };
