import Icon from "../Icons/Icon";
import "./buttons.css";

const Button = (props) => {
  const {
    icon,
    // theme,
    type,
    color,
    label,
    textColor,
    iconColor,
    onClick,
  } = props;
  // const themes = theme ? theme : "clear"
  const types = type ? type : "primary";
  const colors = color ? color : "var(--white)";
  const colorOfText = textColor ? textColor : "var(--white)";
  return (
    <button
      className={`button button--${types}`}
      style={{
        backgroundColor: colors,
        color: colorOfText,
        border: `2px solid ${color}`,
      }}
      onClick={onClick}
    >
      {label}

      {icon && <Icon icon={icon} color={iconColor} />}
    </button>
  );
};

const PrimaryButton = ({ text, color, textColor, onClick }) => {
  return (
    <button
      style={{ backgroundColor: color, color: textColor, textWeight: "900" }}
      onClick={onClick}
    >
      {" "}
      {text}
      <svg
        style={{ paddingLeft: ".2em" }}
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
      >
        <g
          id='Artboard'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='round'
        >
          <polyline
            id='arrowTip'
            stroke={textColor}
            strokeWidth='2'
            strokeLinejoin='round'
            points='14 7 19.172087 11.7408396 14 16.3963533'
          ></polyline>
          <line
            x1='16.9237849'
            y1='11.7480177'
            x2='8.99999993'
            y2='11.75'
            id='arrowLine'
            strokeLinejoin='round'
            stroke={textColor}
            strokeWidth='2'
          ></line>
        </g>
      </svg>
    </button>
  );
};
const SecondaryButton = ({ text, color, textColor, onClick }) => {
  return (
    <button
      className='secondary'
      style={{ borderColor: color, color: textColor, textWeight: "900" }}
      onClick={onClick}
    >
      {" "}
      {text}
      <svg
        style={{ paddingLeft: ".2em" }}
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
      >
        <g
          id='Artboard'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='round'
        >
          <polyline
            id='arrowTip'
            stroke={textColor ? textColor : "var(--white)"}
            strokeWidth='2'
            strokeLinejoin='round'
            points='14 7 19.172087 11.7408396 14 16.3963533'
          ></polyline>
          <line
            x1='16.9237849'
            y1='11.7480177'
            x2='8.99999993'
            y2='11.75'
            id='arrowLine'
            strokeLinejoin='round'
            stroke={textColor ? textColor : "var(--white)"}
            strokeWidth='2'
          ></line>
        </g>
      </svg>
    </button>
  );
};

const ThirdButton = ({ text, noPadding, color, textColor }) => {
  return (
    <button
      className='thirdLevel'
      style={{
        padding: noPadding ? "0" : "2em",
        color: textColor,
        textWeight: "900",
      }}
    >
      {" "}
      {text}
      <svg
        style={{ paddingLeft: ".2em" }}
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
      >
        <g
          id='Artboard'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='round'
        >
          <polyline
            id='arrowTip'
            stroke={color}
            strokeWidth='2'
            strokeLinejoin='round'
            points='14 7 19.172087 11.7408396 14 16.3963533'
          ></polyline>
          <line
            x1='16.9237849'
            y1='11.7480177'
            x2='8.99999993'
            y2='11.75'
            id='arrowLine'
            strokeLinejoin='round'
            stroke={color}
            strokeWidth='2'
          ></line>
        </g>
      </svg>
    </button>
  );
};

export { PrimaryButton, SecondaryButton, ThirdButton, Button };
