import Nav from "../../Components/Nav";
import { FeatureColored } from "../../Parts/PricingGrid/PricingGrid";
import Footer from "../../Parts/Footer/Footer";
import PacksForm from "../../Components/Form/PacksForm";
import { useEffect, useState } from "react";
import Themes from "../Products/ProductThemes/Themes";
import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";

const LetsWork = () => {
  const { product } = useParams();
  const [theme, setTheme] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTheme(
      Themes.find((t) => t.product.toLowerCase() === product.toLowerCase())
    );
  }, []);

  return (
    <div style={{ backgroundColor: "var(--lightGrey)" }}>
      <Nav />
      <div
        className='section  reverseAngle'
        style={{ backgroundColor: theme && theme.mainColor }}
      >
        <h1 style={{ paddingTop: "1em", color: "var(--white)" }}>
          <Trans i18nKey='description.getintouch' />
        </h1>
        <p
          className='workP'
          style={{
            color: "var(--white)",
            width: "40%",
            paddingBottom: "5em",
          }}
        >
          <Trans i18nKey='description.getintouchText' />
        </p>
      </div>
      <div className='section WorkWithUsGrid'>
        <GreatPoints theme={theme} />
        <div style={{ position: "relative" }}>
          <div className='form withUsForm'>
            <PacksForm setTheme={setTheme} theme={theme} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LetsWork;

const GreatPoints = ({ theme }) => {
  console.log(theme);

  return (
    <div className='benefits'>
      {theme &&
        theme.benefits.map((item, key) => {
          return (
            <div style={{ display: "flex", margin: "2em 0" }}>
              <div style={{ marginRight: "1em" }}>
                <FeatureColored color={theme.mainColor} />
              </div>
              <p>{item.text}</p>
            </div>
          );
        })}
    </div>
  );
};
