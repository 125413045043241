import { useState, useEffect } from "react";
import { TeamList } from "../../Parts/Team/TeamList";
import Member from "../../Parts/Team/NewMemberClass";
import TeamMember from "../../Parts/Team/TeamMember";
import Nav from "../../Components/Nav";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import SocialProofTab from "../../Parts/SocialProof/socialProofTab";
import Footer from "../../Parts/Footer/Footer";
import { Link } from "react-router-dom";
import "./interns.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Trans } from "react-i18next";
import { SecondaryButton } from "../../Components/Buttons/Buttons";
import Fade from "react-reveal/Fade";

const Interns = (props) => {
  const [interns, setInterns] = useState();
  const [all, setAll] = useState(false);
  useEffect(() => {
    const i = TeamList.filter((member, key) => {
      if (member.level === "alumni") return member;
      return null;
    });
    setInterns(i);
  }, []);

  const ReasonsWhy = [
    {
      title: <Trans i18nKey="description.interns17" />,
      text: <Trans i18nKey="description.interns18" />,
    },
    {
      title: <Trans i18nKey="description.interns19" />,
      text: <Trans i18nKey="description.interns20" />,
    },
    {
      title: <Trans i18nKey="description.interns21" />,
      text: <Trans i18nKey="description.interns22" />,
    },
    {
      title: <Trans i18nKey="description.interns23" />,
      text: <Trans i18nKey="description.interns24" />,
    },
    {
      title: <Trans i18nKey="description.interns25" />,
      text: <Trans i18nKey="description.interns26" />,
    },
    {
      title: <Trans i18nKey="description.interns27" />,
      text: <Trans i18nKey="description.interns28" />,
    },
  ];

  const unisLogos = [
    {
      img: "/images/logo Uniwersytet Warszawski.png",
      link: false,
    },
    {
      img: "/images/logotipo-lusofona.svg",
      link: false,
    },
    {
      img: "https://res.cloudinary.com/joaocostajs/image/upload/v1624531498/vivadrive/images/Akademia_Finans%C3%B3w_i_Biznesu_Vistula.png",
      link: false,
    },
    {
      img: "https://www.uma.pt/wp-content/themes/uma/images/Logo_UMa_site-01.png",
      link: false,
    },
    {
      img: "/images/logotipo-ubi-2016.svg",
      link: false,
    },
    {
      img: "/images/Polito_Logo_2021_BLU-1024x724.png",
      link: false,
    },
    {
      img: "/images/kbs-bleu-anthracite1581946382.png",
      link: false,
    },
    {
      img: "/images/nationalandkapodistrianuniversityofathens_0.png",
      link: false,
    },
    {
      img: "/images/bme_logo_kicsi.png",
      link: false,
    },
    {
      img: "/images/Logo_ESIEE_Paris.svg.png",
      link: false,
    },
    ,
    {
      img: "/images/cropped-logo2.png",
      link: false,
    },
    {
      img: "/images/397-3979199_tu-dortmund-university-logo-hd-png-download.png",
      link: false,
    },
    {
      img: "/images/1200px-University_of_Zagreb_logo.svg.png",
      link: false,
    },
    {
      img: "/images/ML_1col_202.2.png",
      link: false,
    },
    {
      img: "/images/white_logo.png",
      link: false,
    },
  ];

  const contactUs = {
    smallTitle: <Trans i18nKey="description.smallTitleContact" />,
    title: <Trans i18nKey="description.titleContact" />,
  };

  const Testimonials = [
    {
      img: "https://res.cloudinary.com/joaocostajs/image/upload/v1623084134/vivadrive/P1014uu403.png",
      name: "Dinesh Kumar",
      role: "Head of Marketing",
      text: <Trans i18nKey="description.interns30" />,
    },
    {
      img: "https://res.cloudinary.com/joaocostajs/image/upload/v1620988461/vivadrive/IMG_20190428_204417_1.png",
      name: "Firmino Coutinho",
      role: "UX/CX Lead",
      text: <Trans i18nKey="description.interns29" />,
    },

    {
      img: "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Gautam.jpeg",
      name: "Gautam Narayan",
      role: "Frontend Developer",
      text: <Trans i18nKey="description.interns31" />,
    },
    {
      img: "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Rahul.png",
      name: "Rahul Raj",
      role: "Frontend Lead, full stack",
      text: <Trans i18nKey="description.interns32" />,
    },
  ];
  const [c, setC] = useState({
    red: 255,
    green: 255,
    blue: 255,
  });

  // function getRandomArbitrary(min, max) {
  //     return Math.floor(Math.random() * (max - min) + min);
  //   }
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //         setC({
  //             red: getRandomArbitrary(230, 255),
  //             green:getRandomArbitrary(230, 255),
  //             blue:getRandomArbitrary(230, 255)
  //         })

  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }, []);

  const colors = ["var(--primaryGreen)", "var(--secondaryBlue)"];
  // console.log(colors[Math.floor(Math.random() * (3 - 1) + 1)])
  // return "2px solid " + colors[Math.floor(Math.random() * (3 - 1) + 1)]

  return (
    <div>
      <Nav />
      <div className="internsLanding">
        <div>
          <h1>
            <Trans i18nKey="description.interns1" />
          </h1>
          <h2 style={{ margin: "1em 0" }}>
            <Trans i18nKey="description.interns2" />
          </h2>
          <h5>
            <Trans i18nKey="description.interns3" />
          </h5>
          <div style={{ margin: "1.5em 0" }}>
            <a
              href="https://erasmusintern.org/traineeships?search_api_views_fulltext=vivadrive"
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: "2em", marginBottom: "2em" }}
            >
              <PrimaryButton
                text={<Trans i18nKey="description.interns16" />}
                color="var(--primaryGreen)"
                textColor="var(--white)"
              />
            </a>
          </div>
        </div>
        <div className="internsLandingImageWrapper">
          <img
            src="https://res.cloudinary.com/joaocostajs/image/upload/v1624274428/vivadrive/pexels-photo-3183150.png"
            alt="group of people working"
          />
        </div>
      </div>

      <SocialProofTab companies={unisLogos} bg="var(--primaryGreen)" />

      <section
        className="internsSection interns internsSectionWhat "
        style={{ marginBottom: "-15em" }}
      >
        <div>
          <h1>
            <Trans i18nKey="description.interns4" />
          </h1>
          <h4 style={{ margin: "2em" }}>
            <Trans i18nKey="description.interns5" />
          </h4>
          <h4 style={{ margin: "2em" }}>
            <Trans i18nKey="description.interns6" />
          </h4>
          <h4 style={{ margin: "2em" }}>
            <Trans i18nKey="description.interns7" />
          </h4>
          <h3 style={{ margin: "2em", color: "var(--primaryGreen)" }}>
            <Trans i18nKey="description.interns8" />
          </h3>
          <div style={{ margin: "2em" }}>
            <a
              href="https://www.youtube.com/watch?v=uRlqZAimyzM"
              target="_blank"
              rel="noreferrer"
            >
              <SecondaryButton
                text={<Trans i18nKey="description.interns9" />}
                color="var(--primaryGreen)"
                textColor="var(--primaryGreen)"
              />
            </a>
          </div>
        </div>
        <div>
          <Carousel
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={true}
          >
            {Testimonials.map((person, key) => {
              return (
                <div className="CCCWraper" key={key}>
                  <div className="carrouselCardContent">
                    <img src={person.img} alt="person" />
                    <div className="aboutPerson">
                      <h2>{person.name}</h2>
                      <div>
                        <h4>{person.role}</h4>
                      </div>
                    </div>
                  </div>
                  <span
                    style={{
                      color: "var(--primaryGreen)",
                      fontSize: "6em",
                      textAlign: "left",
                      float: "left",
                    }}
                  >
                    "
                  </span>
                  <p style={{ marginTop: "2em" }}>
                    <span style={{ fontStyle: "italic" }}> {person.text} </span>
                    <span
                      style={{
                        color: "var(--primaryGreen)",
                        fontSize: "6em",
                        textAlign: "left",
                        float: "right",
                        position: "relative",
                        top: "-.3em",
                      }}
                    >
                      "
                    </span>
                  </p>
                </div>
              );
            })}
          </Carousel>
        </div>
      </section>

      <section className="AngleTop internsSection">
        <h1 style={{ margin: "0em 0 .3em 0" }}>
          <Trans i18nKey="description.interns10" />
        </h1>
        <p>
          <Trans i18nKey="description.interns11" />

          <Trans i18nKey="description.interns12" />
        </p>
        <div className="reasonsWhyGrid">
          {ReasonsWhy.map((item, key) => {
            return (
              <div
                key={key}
                className="WhyCards"
                style={{
                  borderBottom:
                    "5px solid " +
                    colors[Math.floor(Math.random() * (3 - 0) + 0)],
                  background: `linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(${c.red},${c.green},${c.blue},0.75) 100%)`,
                }}
              >
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </section>

      <ContactUs contactUs={contactUs} color="var(--primaryGreen)" />
      <center style={{ padding: "4em 0 0 0" }}>
        <h1>
          <Trans i18nKey="description.interns13" />
        </h1>
      </center>
      <div
        className="teamGrid section"
        style={{ backgroundColor: "var(--white)" }}
      >
        {interns && all
          ? interns.map((member, key) => {
              const newMember = new Member(
                member.intern,
                member.img,
                member.name,
                member.role,
                member.nationality,
                member.socials
              );
              return (
                <Fade delay={20 * key} key={key}>
                  <TeamMember data={newMember} />
                </Fade>
              );
            })
          : interns
          ? interns.slice(0, 4).map((member, key) => {
              const newMember = new Member(
                member.intern,
                member.img,
                member.name,
                member.role,
                member.nationality,
                member.socials
              );
              return (
                <Fade delay={20 * key} key={key}>
                  <TeamMember data={newMember} />
                </Fade>
              );
            })
          : null}
      </div>
      <center
        style={{
          backgroundColor: "var(--white)",
          paddingBottom: "8em",
        }}
      >
        <PrimaryButton
          text={
            all ? (
              <Trans i18nKey="description.interns14" />
            ) : (
              <Trans i18nKey="description.interns15" />
            )
          }
          color="var(--primaryGreen)"
          textColor="var(--white)"
          onClick={() => setAll(!all)}
        />
      </center>

      <div style={{ marginTop: "-5em" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Interns;

const ContactUs = ({ contactUs, isReversed, color }) => {
  const { smallTitle, title, text } = { ...contactUs };

  return (
    <section style={{ backgroundColor: "var(--white)" }}>
      <div
        className={`greenSection ${
          isReversed ? "reverseAngle" : "sectionAngle"
        }`}
      >
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr",
            height: "100%",
          }}
        >
          <div className="section">
            <h4 className="smallTitle" style={{ color: `${color && color}` }}>
              {" "}
              {smallTitle}
            </h4>
            <h1 style={{ width: "70%", marginBottom: ".5em" }}>{title}</h1>
            <p style={{ color: "var(--white)", marginBottom: "3em" }}>{text}</p>
            <Link to="/apply">
              <PrimaryButton
                text={<Trans i18nKey="description.apply" />}
                color="var(--primaryGreen)"
                textColor="var(--white)"
              />
            </Link>
            <a
              href="https://erasmusintern.org/traineeships?search_api_views_fulltext=vivadrive"
              target="_blank"
              className="buttonMargin"
            >
              <SecondaryButton
                text={<Trans i18nKey="description.interns16" />}
                color="var(--primaryGreen)"
                textColor="var(--primaryGreen)"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
