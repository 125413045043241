const MoneyGraph = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        strokeLinejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <path
          d='M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z'
          fill='#10b070'
        ></path>
        <g fill='#ffffff'>
          <path d='M46.05737,124.48607h-16.94024c-2.30697,0 -4.17713,-1.87042 -4.17713,-4.17791v-52.38791c0,-2.30749 1.87016,-4.17817 4.17687,-4.17817h16.94024c2.30671,0 4.17687,1.87068 4.17687,4.17817v52.38791c0.00026,2.30749 -1.8699,4.17791 -4.17661,4.17791zM33.294,116.12998h8.58624v-44.03157h-8.58624z'></path>
          <path d='M76.89945,124.48607h-16.94024c-2.30671,0 -4.17687,-1.87068 -4.17687,-4.17817v-35.30468c0,-2.30749 1.87016,-4.17817 4.17687,-4.17817h16.94024c2.30671,0 4.17687,1.87068 4.17687,4.17817v35.30468c0.00026,2.30775 -1.8699,4.17817 -4.17687,4.17817zM64.13608,116.12998h8.5865v-26.9486h-8.5865z'></path>
          <path d='M104.97557,24.94h-13.53914v8.35635h3.41988l-26.14777,26.02696l-30.49164,-16.72026l-4.01587,7.32746l33.23125,18.2225c1.56929,0.86058 3.69335,0.55293 4.95421,-0.70219l28.41221,-28.2807v3.61454h8.354v-13.66648c0,-2.30749 -1.87016,-4.17817 -4.17713,-4.17817z'></path>
          <path d='M147.06,120.40548c0,-4.00961 -2.99612,-7.33999 -8.43437,-9.58381c2.63289,-3.57279 4.192,-7.96781 4.192,-12.71848c0,-11.93018 -9.80796,-21.63612 -21.86339,-21.63612c-3.22131,0 -6.27926,0.69854 -9.03531,1.94192v-20.25052c0,-2.30749 -1.8699,-4.17817 -4.17687,-4.17817h-16.94076c-2.30697,0 -4.17687,1.87068 -4.17687,4.17817v62.14943c0,2.30749 1.8699,4.17817 4.17687,4.17817h4.04666v9.99349c0,8.64364 13.53262,12.58045 26.10602,12.58045c12.57314,0 26.10576,-3.9368 26.10576,-12.58045v-14.06363c0,-0.00365 0.00026,-0.00705 0.00026,-0.01044zM110.76244,117.23663c3.04543,1.59513 6.51281,2.50294 10.19154,2.50294c3.67873,0 7.14611,-0.90781 10.19154,-2.50294c4.36344,0.9874 6.69807,2.40326 7.40887,3.17329c-1.27574,1.41691 -7.36373,4.21992 -17.60068,4.21992c-10.23694,0 -16.32494,-2.80302 -17.60068,-4.21992c0.71106,-0.7703 3.04595,-2.18616 7.4094,-3.17329zM120.95398,84.82342c7.44906,0 13.50966,5.95726 13.50966,13.27977c0,7.3225 -6.06034,13.27977 -13.50966,13.27977c-7.44906,0 -13.50966,-5.95726 -13.50966,-13.27977c0.00026,-7.3225 6.0606,-13.27977 13.50966,-13.27977zM94.97843,62.3369h8.5865v22.67205c-2.80432,3.63803 -4.47434,8.17604 -4.47434,13.0945c0,4.75068 1.55886,9.14569 4.192,12.71848c-3.36039,1.38638 -5.78583,3.18817 -7.15054,5.30831h-1.15362zM120.95398,138.70365c-10.73247,0 -16.90475,-3.08118 -17.75202,-4.41563v-4.34465c4.95291,2.06012 11.47197,3.0423 17.75202,3.0423c6.28005,0 12.79885,-0.98218 17.75176,-3.0423v4.34439c-0.84701,1.33497 -7.01929,4.41589 -17.75176,4.41589z'></path>
          <path d='M122.04497,96.11796c-1.89521,-0.77369 -1.89521,-1.06464 -1.89521,-1.3214c0,-0.24607 0.14117,-0.65887 1.08551,-0.65887c1.12674,0 2.32211,0.5545 3.2307,1.20607l1.33367,-3.47833c-0.89163,-0.56494 -1.98732,-1.07586 -2.98881,-1.24521v-2.11544h-3.57123v2.33594c-1.95888,0.65183 -3.16259,2.23939 -3.16259,4.20349c0,2.65976 2.22791,3.86531 4.16539,4.61708c1.65827,0.66227 1.65827,1.13744 1.65827,1.42108c0,0.55084 -0.53258,0.90677 -1.35663,0.90677c-1.29244,0 -2.6154,-0.67844 -3.59654,-1.4944l-1.28356,3.52974c0.99601,0.79326 2.16789,1.30731 3.42849,1.5111v2.18929h3.59158v-2.39934c2.0202,-0.67923 3.30924,-2.37377 3.30924,-4.38771c0.00052,-2.898 -2.2527,-4.13877 -3.94829,-4.81983c-1.89521,-0.77369 1.69559,0.68105 0,0c-1.89521,-0.77369 1.69559,0.68105 0,0c-1.89521,-0.77369 1.69559,0.68105 0,0c-1.89521,-0.77369 1.69559,0.68105 0,0z'></path>
        </g>
      </g>
    </svg>
  );
};

export default MoneyGraph;
