const About = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40'
      height='40'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill=''></path>
        <g fill='var(--primaryGreen)'>
          <path d='M86,17.2c-37.92928,0 -68.8,30.87072 -68.8,68.8c0,37.92928 30.87072,68.8 68.8,68.8c37.92928,0 68.8,-30.87072 68.8,-68.8c0,-37.92928 -30.87072,-68.8 -68.8,-68.8zM86,28.66667c31.73223,0 57.33333,25.6011 57.33333,57.33333c0,31.73223 -25.6011,57.33333 -57.33333,57.33333c-31.73223,0 -57.33333,-25.6011 -57.33333,-57.33333c0,-31.73223 25.6011,-57.33333 57.33333,-57.33333zM86,57.33333c-15.83216,0 -28.66667,12.8345 -28.66667,28.66667c0,15.83216 12.8345,28.66667 28.66667,28.66667c15.83216,0 28.66667,-12.8345 28.66667,-28.66667c0,-15.83216 -12.8345,-28.66667 -28.66667,-28.66667z'></path>
        </g>
      </g>
    </svg>
  );
};
export default About;
