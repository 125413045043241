import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";
import HomepageLanding from "../../Parts/Landings/Homepage";
import ColumnsSection from "../../Parts/Sections/ColumnsSection";
import Advantages from "../../Parts/Sections/Advantages";
import ColumnsSectionProducts from "../../Parts/Sections/ColumnsSectionProducts";
import Model3D from "../../Parts/Sections/Model3D";
import ContactUs from "../../Parts/Sections/ContactUs";
import Awards from "../../Parts/Sections/Awards";
import Ecosystem from "../../Parts/Sections/Ecosystem";
import { Trans } from "react-i18next";

const language = window.localStorage.getItem("i18nextLng");

const fleet_saver =
  language === "en"
    ? "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/website/EN_FleetSaver_Leaflet_Final.pdf"
    : "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Leaflet.pdf";

const accentColor = "var(--primaryGreen)";
const introBenefits = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.introBenefitsSmallTitle' />,
  title: <Trans i18nKey='description.introBenefitsTitle' />,
};

const columnsBenefits = [
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1688552334/VivaDrive/icons/car-service_tn9hnl.png",
    type: "icon",
    title: <Trans i18nKey='description.carBenefitTitle' />,
    text: <Trans i18nKey='description.carBenefitText' />,
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1688552137/VivaDrive/icons/electric-charge_bicstk.png",
    type: "icon",
    title: <Trans i18nKey='description.controllerBenefitTitle' />,
    text: <Trans i18nKey='description.controllerBenefitText' />,
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1688552137/VivaDrive/icons/planning_1_jttoxs.png",
    type: "icon",
    title: <Trans i18nKey='description.clockBenefitTitle' />,
    text: <Trans i18nKey='description.clockBenefitText' />,
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1688553888/VivaDrive/icons/erp_gqviku.png",
    type: "icon",
    title: <Trans i18nKey='description.leafBenefitTitle' />,
    text: <Trans i18nKey='description.leafBenefitText' />,
  },
];

const introAdvantages = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.introAdvantagesSmallTitle' />,
  title: <Trans i18nKey='description.introAdvantagesTitle' />,
};

const columnsAdvantages = [
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1688566277/esgframe_blshsl.png",
    type: "icon",
    title: <Trans i18nKey='description.Benefit1' />,
    text: <Trans i18nKey='description.Benefit1Text' />,
    textColor: "var(--white)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1688563833/coin_lwtwku.png",
    type: "icon",
    title: <Trans i18nKey='description.Benefit2' />,
    text: <Trans i18nKey='description.Benefit2Text' />,
    textColor: "var(--white)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1689170725/co2-cloud_si8jxr.png",
    type: "icon",
    title: <Trans i18nKey='description.Benefit3' />,
    text: <Trans i18nKey='description.Benefit3Text' />,
    textColor: "var(--white)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1689170987/opinions_ym7nkb.png",
    type: "icon",
    title: <Trans i18nKey='description.Benefit4' />,
    text: <Trans i18nKey='description.Benefit4Text' />,
    textColor: "var(--white)",
  },
];

const introProducts = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.smallTitleProducts' />,
  title: <Trans i18nKey='description.titleProducts' />,
};

const columnsProducts = [
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/ecoem_xantsu-min_hyc40h.png",
    link: "/products/evrecommend",
    title: <Trans i18nKey='description.evrTitle' />,
    text: <Trans i18nKey='description.evrText' />,
    text1: <Trans i18nKey='description.evrText1' />,
    text2: <Trans i18nKey='description.evrText2' />,
    text3: <Trans i18nKey='description.evrText3' />,
    text4: <Trans i18nKey='description.evrText4' />,
    text5: <Trans i18nKey='description.evrText5' />,
    color: "var(--primaryGreen)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/chargers_adrvzs-min_qcyb3l.png",
    link: "/products/digitalfleet",
    title: <Trans i18nKey='description.dfTitle' />,
    text: <Trans i18nKey='description.dfText' />,
    text1: <Trans i18nKey='description.dfText1' />,
    text2: <Trans i18nKey='description.dfText2' />,
    text3: <Trans i18nKey='description.dfText3' />,
    text4: <Trans i18nKey='description.dfText4' />,
    color: "var(--primaryGreen)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1689258068/VivaDrive/icons/Screenshot_2023-07-13_at_4.20.56_PM_g3hnws.png",
    link: "",
    title: <Trans i18nKey='description.step3Title' />,
    text: <Trans i18nKey='description.step3Text' />,
    text1: <Trans i18nKey='description.step3Text1' />,
    text2: <Trans i18nKey='description.step3Text2' />,
    text3: <Trans i18nKey='description.step3Text3' />,
    text4: <Trans i18nKey='description.step3Text4' />,
    color: "var(--primaryGreen)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/vivalaptop_lnc9jo-min_exdocn.png",
    link: "",
    title: <Trans i18nKey='description.step4Title' />,
    text: <Trans i18nKey='description.step4Text' />,
    text1: <Trans i18nKey='description.step4Text1' />,
    text2: <Trans i18nKey='description.step4Text2' />,
    text3: <Trans i18nKey='description.step4Text3' />,
    text4: <Trans i18nKey='description.step4Text4' />,
    color: "var(--primaryGreen)",
  },
  {
    img: "https://res.cloudinary.com/efer/image/upload/v1689175021/repo_rxxelx.png",
    link: "",
    title: <Trans i18nKey='description.step5Title' />,
    text: <Trans i18nKey='description.step5Text' />,
    text1: <Trans i18nKey='description.step5Text1' />,
    text2: <Trans i18nKey='description.step5Text2' />,
    text3: <Trans i18nKey='description.step5Text3' />,
    text4: <Trans i18nKey='description.step5Text4' />,
    color: "var(--primaryGreen)",
  },
];

const contactUs = {
  smallTitle: <Trans i18nKey='description.smallTitleContact' />,
  title: <Trans i18nKey='description.titleContact' />,
};

const introBlog = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.smallTitleBlog' />,
  title: <Trans i18nKey='description.titleBlog' />,
  text: <Trans i18nKey='description.textBlog' />,
};

const introEcosystem = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.smallTitleEcosystem' />,
  title: <Trans i18nKey='description.textEcosystem' />,
};

const introMoreProducts = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.smallTitleOtherProducts' />,
  title: <Trans i18nKey='description.titleOtherProducts' />,
};

const Landing = () => {
  return (
    <div style={{ background: "var(--lightGrey" }}>
      <Nav borderBottom='var(--primaryGreen)' />
      <HomepageLanding />
      <Awards />
      <ColumnsSection
        intro={introBenefits}
        columns={columnsBenefits}
        color={"var(--white)"}
      />
      <ContactUs contactUs={contactUs} color='var(--primaryGreen)' />
      {/* <Model3D /> */}
      <Ecosystem
        id='ecosystem'
        intro={introEcosystem}
        title={<Trans i18nKey='description.ecosystemTitle' />}
        text={<Trans i18nKey='description.ecosystemText' />}
      />
      <Advantages
        intro={introAdvantages}
        columns={columnsAdvantages}
        color={accentColor}
        textColor={accentColor}
      />
      <ColumnsSectionProducts
        id='products'
        intro={introProducts}
        columns={columnsProducts}
      />
      <ContactUs contactUs={contactUs} color='var(--primaryGreen)' />
      {/* <BlogSection id='blog' intro={introBlog} /> */}
      <Footer />
    </div>
  );
};

export default Landing;
