export default function WidgetCards(props) {
  const { item, key, background } = { ...props };
  const colors = [
    "var(--primaryGreen)",
    "var(--secondaryBlue)",
    "var(--secondaryBlue)",
  ];
  return (
    <div
      key={key}
      className='WhyCards'
      style={{
        borderBottom:
          "5px solid " + colors[Math.floor(Math.random() * (3 - 0) + 0)],
        background: background ? background : "var(--white)",
      }}
    >
      <h4>{item.title}</h4>
      <p>{item.text}</p>
    </div>
  );
}
