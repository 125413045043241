import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./Pages/Landing/landing";
import ProductPage from "./Pages/Products/ProductPage/ProductPage";
import "./Pages/Products/productPage.css";
import NoMatch from "./Pages/404/NoMatch";
import WorkWithUs from "./Pages/WorkWithUs/WorkWithUs";
import ReactNotification from "react-notifications-component";
import Interns from "./Pages/Interns/Interns";
import LetsWork from "./Pages/Lets_Work/LetsWork";
import TeamPage from "./Pages/Team/TeamPage";
import JoinTeam from "./Pages/JoinTeam/JoinTeam";
import Apply from "./Pages/Apply/Apply";
import Career from "./Pages/Career/Career";
import ApplyForJob from "./Pages/Apply/ApplyForJob";
import CookieConsent from "react-cookie-consent";
import Privacy from "./Pages/PrivacyPage/Privacy";
import AboutUs from "./Pages/AboutUs/AboutUs";
import EUProjectsPage from "./Pages/EUProjects/EUProjectsPage";
import MediaMentions from "./Pages/Media/MediaMentions";
import { Trans } from "react-i18next";
import BlogPage from "./Pages/Media/BlogPage";
import SinglePost from "./Pages/Media/SinglePost";
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
function App() {
  return (
    <div>
      <CookieConsent
        location='bottom'
        buttonText={<Trans i18nKey='description.accept' />}
        cookieName='VivaDriveCookies'
        buttonClasses='btn-accept'
        style={{ background: "#2B373B" }}
        expires={150}
        buttonStyle={{
          background: "var(--primaryGreen)",
          color: "var(--black)",
          fontWeight: "bolder",
          borderRadius: "3px",
          marginRight: "2em",
          height: "20px",
          padding: "20px",
        }}
      >
        <Trans i18nKey='description.cookies' />
        <a
          href='https://vivadrive.io/privacy/policy/'
          target='_blank'
          rel='noreferrer'
          style={{ color: "var(--primaryGreen)" }}
        >
          {" "}
          <Trans i18nKey='description.privacy' />
        </a>
      </CookieConsent>
      <Router>
        {/* <ReactNotification /> */}
        <Switch>
          <Route path='/products/:product'>
            <ProductPage />
          </Route>
          <Route path='/letswork/:product'>
            <LetsWork />
          </Route>
          <Route path='/apply/:role'>
            <ApplyForJob />
          </Route>
          <Route path='/join-team'>
            <JoinTeam />
          </Route>
          <Route path='/careers'>
            <Career />
          </Route>
          <Route path='/euprojects'>
            <EUProjectsPage />
          </Route>
          <Route path='/apply'>
            <Apply />
          </Route>
          <Route path='/privacy/policy/'>
            <Privacy />
          </Route>
          <Route path='/team'>
            <TeamPage />
          </Route>
          <Route path='/internships'>
            <Interns />
          </Route>
          {/* <Route path='/usecases'>
            <UseCasesPage />
          </Route>
          <Route path='/features'>
            <FeaturesPage />
          </Route> 
          <Route path='/help'>
            <HelpCenter />
          </Route>
         
          <Route path='/login'>
            <LoginPage />
          </Route>
          <Route path='/register'>
            <CreateAccountAndLogin />
          </Route>
           */}
          <Route path='/work-with-us'>
            <WorkWithUs />
          </Route>
          <Route path='/about'>
            <AboutUs />
          </Route>
          <Route path='/newsroom'>
            <MediaMentions />
          </Route>
          {/* <Route path='/blog'>
            <BlogPage />
          </Route> */}
          <Route path='/post'>
            <SinglePost />
          </Route>
          <Route path='/' exact>
            <Landing />
          </Route>
          <Route path='*'>
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
