import { PrimaryButton } from "../Buttons/Buttons";
import { handleForm, handleChange } from "../../Utils/HandleForm";
import { store } from "react-notifications-component";
import { useState } from "react";
import Themes from "../../Pages/Products/ProductThemes/Themes";
import { useTranslation, Trans } from "react-i18next";
import { t } from "i18next";

const PacksForm = ({ setTheme, theme }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    company: "",
    service: "",
    cars: "",
    message: "",
  });

  function handler(e) {
    if (e.target.value !== "default") {
      const val = e.target.value.split(" ")[0].toLowerCase();

      setTheme(Themes.find((t) => t.product.toLowerCase() === val));
    }

    handleChange(e, setForm, form);
  }

  return (
    <form action=''>
      <label htmlFor='name'>{t("description.name")}</label>
      <input
        type='text'
        required
        name='name'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='email'>
        <Trans i18nKey='description.email' />
      </label>
      <input
        type='email'
        required
        name='email'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='company name'>
        <Trans i18nKey='description.company' />
      </label>
      <input
        type='text'
        name='company'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='Amount of Cars'>
        <Trans i18nKey='description.amount' />
      </label>
      <input
        type='text'
        name='cars'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='message'>
        <Trans i18nKey='description.form6' />
      </label>
      <textarea
        name='message'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <center style={{ paddingTop: "2em" }}>
        <PrimaryButton
          text={<Trans i18nKey='description.send' />}
          textColor='var(--white)'
          color='var(--primaryGreen)'
          onClick={(e) => handleForm(e, store, form)}
        />
      </center>
    </form>
  );
};

export default PacksForm;
