import { useState } from "react";
import { PrimaryButton } from "../../../Components/Buttons/Buttons";
import IsEmail from "../../../Utils/IsEmail";
import { Trans } from "react-i18next";
import { t } from "i18next";

const ProductLanding = ({ data, color }) => {
  const [input, setInput] = useState();
  const { title, text, img } = { ...data };
  const [modal, setModal] = useState(false);
  const PDF = () => {
    if (input === undefined)
      return alert(" Provide your email to get access to the PDF");
    if (IsEmail(input)) {
      setModal(true);
    } else {
      alert(" Provide your email to get access to the PDF");
    }
  };
  const openPDF = (lang) => {
    setModal(false);
    window.open(
      `https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/${lang}_DigitalFleet-Leaflet.pdf`,
      "_blank"
    );
  };

  return (
    <div className='productLanding'>
      <div className='Texts'>
        <h1 className='bolder'>{title} </h1>
        <p style={{ width: "80%", margin: "2em 0 3em" }}>{text}</p>
        <input
          onChange={(e) => setInput(e.target.value)}
          placeHolder={t("description.address")}
          style={{
            paddingLeft: "1em",
            marginBottom: "1em",
            fontSize: "1em",
            height: "46px",
            borderColor: color,
            borderRadius: "var(--radius)",
            width: "25vw",
            minWidth: "200px",
          }}
          type='text'
        />
        <PrimaryButton
          text={<Trans i18nKey='description.request' />}
          color={color}
          textColor='var(--white)'
          onClick={PDF}
        />
      </div>
      <div className='img'>
        <img src={img} alt='Product interface' />
      </div>
      {modal && (
        <center
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "calc(100vw - 14vw)",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.2)",
            padding: "7vw",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "3em",
              height: "100%",
              width: "100%",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                padding: "3em",
                height: "auto",
                display: "inline-block",
                borderRadius: "var(--radius)",
                backgroundColor: "var(--white)",
                color: color,
              }}
              className='selectLangBTN'
              onClick={(e) => openPDF("EN")}
            >
              English
            </div>
            <div
              style={{
                padding: "3em",
                height: "auto",
                display: "inline-block",
                borderRadius: "var(--radius)",
                backgroundColor: "var(--white)",
                color: color,
              }}
              className='selectLangBTN'
              onClick={(e) => openPDF("PL")}
            >
              Polski
            </div>
            <div
              style={{
                padding: "3em",
                height: "auto",
                display: "inline-block",
                borderRadius: "var(--radius)",
                backgroundColor: "var(--white)",
                color: color,
              }}
              className='selectLangBTN'
              onClick={(e) => openPDF("PT")}
            >
              Português
            </div>
          </div>
        </center>
      )}
    </div>
  );
};

export default ProductLanding;
