import ArrowIcon from './ArrowIcon.js'
import CarCharge from './CarCharge.js';
import CarMoney from './CarMoney.js';
import Mind from './Mind.js';
import MoneyGraph from './MoneyGraph.js';
import CarWithPlug from './CarWithPlug.js';
import Charger from './Charger.js';
import Desktop from './Desktop.js';
import File from './File.js';
import Controller from './Controller.js';
import Planet from './Planet.js';
import Clock from './Clock.js';
import Modern from './Modern.js';
import IconTypes from './IconTypes'
import Car from './Car.js';
import DigitalFleet from './DigitalFleet.js';
import EVRLogo from './EVRLogo.js';
import Mail from './Mail.js';
import Person from './Person.js';
import Leaf from './Leaf.js';
import Circle from './Circle.js';
import AI from './AI.js';
import Career from './Career.js'
import Bulb from './Bulb.js';
import Student from './Student.js';
import About from './About.js';
import Media from './Media.js';


const Icon = (props) =>{
const {icon, color} = props
switch(icon){
    case IconTypes.ARROW:
        return <ArrowIcon  color={color}/>;
    case IconTypes.CAR_CHARGE:
        return <CarCharge color={color}/>
    case IconTypes.CAR_MONEY:
        return <CarMoney color={color}/>
    case IconTypes.CAR:
        return <Car color={color}/>
    case IconTypes.MIND:
        return <Mind color={color}/>
    case IconTypes.MONEY_GRAPH:
        return <MoneyGraph color={color}/>
    case IconTypes.CAR_WITH_PLUG:
        return <CarWithPlug color={color}/>
    case IconTypes.CHARGER:
        return <Charger color={color}/>
    case IconTypes.DESKTOP:
        return <Desktop color={color}/>
    case IconTypes.FILE:
        return <File color={color}/>
    case IconTypes.CONTROLLER:
        return <Controller color={color}/>
    case IconTypes.PLANET:
        return <Planet color={color}/>
    case IconTypes.CLOCK:
        return <Clock color={color}/>
    case IconTypes.DIGITALFLEET:
            return <DigitalFleet color={color}/>
    case IconTypes.EVRLOGO:
        return <EVRLogo color={color} />
    case IconTypes.MAIL:
        return <Mail color={color} />
    case IconTypes.MODERN:
        return <Modern color={color} />
    case IconTypes.PERSON:
        return <Person color={color} />
      case IconTypes.LEAF:
        return <Leaf color={color} />
    case IconTypes.CIRCLE:
        return <Circle color={color} />
     case IconTypes.AI:
        return <AI color={color} />
       case IconTypes.STUDENT:
        return <Student color={color} />
       case IconTypes.BULB:
        return <Bulb color={color} />
       case IconTypes.CAREER:
        return <Career color={color} />
       case IconTypes.ABOUT:
        return <About color={color} />
        case IconTypes.MEDIA:
        return <Media color={color}/>
    default:
        return null;
}

}

export default Icon;