import SectionIntro from "../../../Parts/Sections/SectionIntro";

const Whats = ({intro, allWhats, id}) =>{

    return   <div className="whatsSection" id={id}>
                 <SectionIntro data={intro}/>
                <ul>
                    {allWhats.map((item, key) => (
                    <li>
                        <WhatsItem title={item.title} key={key} text={item.text} img={item.img} link={item.link} color={item.color}/>
                     </li>
                     ))}
                </ul>
             </div>
}
const WhatsItem = ({title, text, img, link, color}) =>{
    return  <div className="singleWhat">
                <div className="singleWhatText">
                    <h4 style={{fontSize:"2.5em"}}>{title}</h4>
                    <p style={{margin:"1.5em 0"}}>{text} </p>
                    {/* {link && 
                    <Link to={link.linkTo}>
                        <ThirdButton noPadding color={color} textColor={color} text={link.title} ></ThirdButton>
                    </Link>
                    } */}
                </div>
                <div className="img">
                    <img src={img} alt="Descripted functionality interface"/>
                </div>
            </div>
}



export default Whats;