import React from "react";
import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";
import { Trans } from "react-i18next";
import MediaAssets from "../../Pages/Media/MediaAssets";
import { SecondaryButton } from "../../Components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const accentColor = "var(--primaryGreen)";

const introBlog = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.smallTitleBlog' />,
  title: <Trans i18nKey='description.titleBlog' />,
  text: <Trans i18nKey='description.textBlog' />,
};

const introMedia = {
  accent: accentColor,
  color: accentColor,
  title: <Trans i18nKey='description.recentMedia' />,
};

const MediaMentions = () => {
  const [all, setAll] = useState(false);

  return (
    <>
      <Nav />
      <section className='section'>
        <div className='jumpTo'>
          <h2>
            <Trans i18nKey='description.jumpOn' />
          </h2>
          <div className='jumpToText'>
            <a href='#assets'>
              <h3>{<Trans i18nKey='description.logotype' />}</h3>
            </a>
            <a href='#aboutUs'>
              <h3>{<Trans i18nKey='description.about' />}</h3>
            </a>
            <a href='#dib-posts'>
              <h3>{<Trans i18nKey='description.pressrelease' />}</h3>
            </a>
          </div>
        </div>
        <div className='mediaAssetsAbout'>
          <MediaAssets />
          <div id='aboutUs' className='mediaAssetAboutText'>
            <h2 style={{ color: accentColor, margin: "0" }}>
              {<Trans i18nKey='description.about' />}
            </h2>
            <h3 style={{ marginBottom: "2em", fontSize: "1.3em" }}>
              {<Trans i18nKey='description.aboutUs' />}
            </h3>
            <Link to='/about'>
              <SecondaryButton
                color='var(--primaryGreen)'
                textColor='var(--primaryGreen)'
                text={<Trans i18nKey='description.moreViva' />}
                style={{ padding: "0", fontSize: "3em" }}
              />
            </Link>
          </div>
        </div>

        <div id='dib-posts'></div>
      </section>
      <Footer />
    </>
  );
};

export default MediaMentions;
