import IconTypes from "../../../Components/Icons/IconTypes";
import { useTranslation, Trans } from "react-i18next";
import { t } from "i18next";

const red = "var(--primaryRed)";
const DarkRed = "var(--primaryGreen)";
const opaqueRed = "var(--opaqueRed)";
const green = "var(--EVRGreen)";
const thirdGreen = "var(--backgroundGreenDark)";
const blue = "var(--primaryBlue)";
const thirdBlue = "var(--thirdBlue)";
const LafargeGreen = "var(--LafargeGreen)";
const opaqueLafargeGreen = "var(--opaqueLafargeGreen)";

const language = window.localStorage.i18nextLng;
const Themes = [
  {
    product: "DigitalFleet",
    logo: "https://res.cloudinary.com/efer/image/upload/v1644240900/VivaDrive/Logos/Products/DFLogo_havoxe_esmf9a.svg",
    mainColor: DarkRed,
    lightColor: opaqueRed,
    squareLight: "#13CD83",
    squareDark: "#10B070",
    navTabs: [
      {
        link: "#why",
        name: <Trans i18nKey='description.dfBenefits' />,
      },
      {
        link: "#whats",
        name: <Trans i18nKey='description.dfFeatures' />,
      },
      // {
      //   link: "#pricing",
      //   name: <Trans i18nKey='description.dfPricing' />,
      // },
    ],
    landingData: {
      title: <Trans i18nKey='description.dfLandingTitle' />,
      text: <Trans i18nKey='description.dfLandingText' />,
      img: "https://res.cloudinary.com/efer/image/upload/v1681455799/VivaDrive/Screens/vivalaptop_lnc9jo.png",
    },
    introBenefits: {
      backgroundColor: opaqueRed,
      accent: red,
      smallTitle: <Trans i18nKey='description.dfSmallTitle' />,
      title: <Trans i18nKey='description.dfProductTitle' />,
    },
    benefits: [
      {
        icon: IconTypes.MONEY_GRAPH,
        type: "icon",
        title: <Trans i18nKey='description.dfBenefitTitle1' />,
        text: <Trans i18nKey='description.dfBenefitText1' />,
      },
      {
        icon: IconTypes.CAR_MONEY,
        type: "icon",
        title: <Trans i18nKey='description.dfBenefitTitle2' />,
        text: <Trans i18nKey='description.dfBenefitText2' />,
      },
      {
        icon: IconTypes.MIND,
        type: "icon",
        title: <Trans i18nKey='description.dfBenefitTitle3' />,
        text: <Trans i18nKey='description.dfBenefitText3' />,
      },
      {
        icon: IconTypes.CAR_CHARGE,
        type: "icon",
        title: <Trans i18nKey='description.dfBenefitTitle4' />,
        text: <Trans i18nKey='description.dfBenefitText4' />,
      },
    ],
    companies: [
      "/images/mec.png",
      "/images/aereco.png",
      "/images/synerise.png",
      "/images/uha.png",
    ],
    introGraph: {
      accent: red,
      smallTitle: <Trans i18nKey='description.dfSmallTitleEcosystem' />,
      title: <Trans i18nKey='description.dfTitleEcosystem' />,
      text: "",
    },
    graph: {
      title: "",
      text: "",
      img:
        language === "en"
          ? "https://res.cloudinary.com/efer/image/upload/v1649082812/VivaDrive/Graphs/Content2_dhzbeg.svg"
          : "https://res.cloudinary.com/efer/image/upload/v1653660004/VivaDrive/Graphs/dfpll_ythz6y.png",
      imgMobile:
        language === "en"
          ? "https://res.cloudinary.com/efer/image/upload/v1650633379/VivaDrive/Graphs/DfSystemMobile_eih8dv.svg"
          : "https://res.cloudinary.com/efer/image/upload/v1653659962/VivaDrive/Graphs/dfPL_jctst5.png",
    },
    introWhats: {
      accent: red,
      smallTitle: <Trans i18nKey='description.dfWhatsSmallTitle' />,
      title: <Trans i18nKey='description.dfWhatsTitle' />,
      text: <Trans i18nKey='description.dfWhatsText' />,
    },
    allWhats: [
      {
        title: <Trans i18nKey='description.dfWhatsTitle3' />,
        text: <Trans i18nKey='description.dfWhatsText3' />,
        link: {
          title: <Trans i18nKey='description.dfWhatsLink3' />,
          linkTo: "/",
        },
        color: red,
        img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/evrecommend_screen_1_dbpn0m-min_zq4v0i.png",
      },
      {
        title: <Trans i18nKey='description.dfWhatsTitle1' />,
        text: <Trans i18nKey='description.dfWhatsText1' />,
        link: {
          title: <Trans i18nKey='description.dfWhatsLink1' />,
          linkTo: "/",
        },
        color: red,
        img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/vivalaptop_lnc9jo-min_exdocn.png",
      },
      {
        title: <Trans i18nKey='description.dfWhatsTitle4' />,
        text: <Trans i18nKey='description.dfWhatsText4' />,
        link: {
          title: <Trans i18nKey='description.dfWhatsLink4' />,
          linkTo: "/",
        },
        color: red,
        img: "https://res.cloudinary.com/efer/image/upload/v1681747846/mobiles_small_mgma19.png",
      },
    ],
    introCosts: {
      accent: red,
      smallTitle: <Trans i18nKey='description.dfCostsSmallTitle' />,
      title: <Trans i18nKey='description.dfCostsTitle' />,
      text: "",
    },
    costs: [
      {
        cost: <Trans i18nKey='description.dfCostsNumber1' />,
        title: <Trans i18nKey='description.dfCostsTitle1' />,
      },
      {
        cost: <Trans i18nKey='description.dfCostsNumber2' />,
        title: <Trans i18nKey='description.dfCostsTitle2' />,
      },
      {
        cost: <Trans i18nKey='description.dfCostsNumber3' />,
        title: <Trans i18nKey='description.dfCostsTitle3' />,
      },
      {
        cost: <Trans i18nKey='description.dfCostsNumber4' />,
        title: <Trans i18nKey='description.dfCostsTitle4' />,
      },
    ],
    introPricing: {
      accent: red,
      smallTitle: <Trans i18nKey='description.dfPricing' />,
      title: <Trans i18nKey='description.dfPricingTitle' />,
      text: <Trans i18nKey='description.dfPricingText' />,
    },
    contactUs: {
      color: "var(--white)",
      smallTitle: <Trans i18nKey='description.dfContactSmallTitle' />,
      title: <Trans i18nKey='description.dfContactTitle' />,
      text: "",
    },
  },

  {
    product: "EVRecommend",
    logo: "https://res.cloudinary.com/efer/image/upload/v1643811385/VivaDrive/Logos/Products/EVRLogo_hci8ou.svg",
    mainColor: green,
    lightColor: thirdGreen,
    squareLight: "#13CD83",
    squareDark: "#10b070",
    navTabs: [
      {
        link: "#why",
        name: <Trans i18nKey='description.dfBenefits' />,
      },
      {
        link: "#whats",
        name: <Trans i18nKey='description.dfFeatures' />,
      },
      // {
      //   link: "#pricing",
      //   name: <Trans i18nKey='description.dfPricing' />,
      // },
    ],
    landingData: {
      title: <Trans i18nKey='description.evrLandingTitle' />,
      text: <Trans i18nKey='description.evrLandingText' />,
      img: "https://res.cloudinary.com/efer/image/upload/v1668799679/VivaDrive/Others/vdlanding_cysk8k.png",
    },
    introBenefits: {
      backgroundColor: thirdGreen,
      accent: green,
      smallTitle: <Trans i18nKey='description.evrSmallTitle' />,
      title: <Trans i18nKey='description.evrBenefitsTitle' />,
    },
    benefits: [
      {
        icon: IconTypes.CAR_WITH_PLUG,
        type: "icon",
        title: <Trans i18nKey='description.evrBenefitTitle1' />,
        text: <Trans i18nKey='description.evrBenefitText1' />,
      },
      {
        icon: IconTypes.CHARGER,
        type: "icon",
        title: <Trans i18nKey='description.evrBenefitTitle2' />,
        text: <Trans i18nKey='description.evrBenefitText2' />,
      },
      {
        icon: IconTypes.FILE,
        type: "icon",
        title: <Trans i18nKey='description.evrBenefitTitle3' />,
        text: <Trans i18nKey='description.evrBenefitText3' />,
      },
      {
        icon: IconTypes.DESKTOP,
        type: "icon",
        title: <Trans i18nKey='description.evrBenefitTitle4' />,
        text: <Trans i18nKey='description.evrBenefitText4' />,
      },
    ],
    companies: [
      "/images/mec.png",
      "/images/aereco.png",
      "/images/synerise.png",
      "/images/uha.png",
    ],
    introWhats: {
      accent: green,
      smallTitle: <Trans i18nKey='description.dfWhatsSmallTitle' />,
      title: <Trans i18nKey='description.evrWhatsTitle' />,
      text: <Trans i18nKey='description.evrWhatsText' />,
    },
    allWhats: [
      {
        title: <Trans i18nKey='description.evrWhatsTitle1' />,
        text: <Trans i18nKey='description.evrWhatsText1' />,
        link: {
          title: <Trans i18nKey='description.evrWhatsLink1' />,
          linkTo: "/",
        },
        color: green,
        img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/evrecommend_screen_1_dbpn0m-min_zq4v0i.png",
      },
      {
        title: <Trans i18nKey='description.evrWhatsTitle2' />,
        text: <Trans i18nKey='description.evrWhatsText2' />,
        link: {
          title: <Trans i18nKey='description.evrWhatsLink2' />,
          linkTo: "/",
        },
        color: green,
        img: "https://res.cloudinary.com/efer/image/upload/v1686668836/VivaDrive/Screens/chargers_adrvzs-min_qcyb3l.png",
      },
      {
        title: <Trans i18nKey='description.evrWhatsTitle3' />,
        text: <Trans i18nKey='description.evrWhatsText3' />,
        link: {
          title: <Trans i18nKey='description.evrWhatsLink3' />,
          linkTo: "/",
        },
        color: green,
        img: "https://res.cloudinary.com/efer/image/upload/v1681455799/VivaDrive/Screens/vivalaptop_lnc9jo.png",
      },
    ],
    introCosts: {
      accent: green,
      smallTitle: "Electrify",
      title: "Save up to 40% per year",
      text: "We make sure you make the best selection of your EVs respecting your needs and start saving from the beggining, your wallet and the world.",
    },
    introGraph: {
      accent: green,
      smallTitle: <Trans i18nKey='description.dfSmallTitleEcosystem' />,
      title: <Trans i18nKey='description.evrTitleEcosystem' />,
    },
    graph: {
      title: "",
      text: "",
      img:
        language === "en"
          ? "https://res.cloudinary.com/efer/image/upload/v1651523925/VivaDrive/Graphs/EVRgraph_ud2hfk.svg"
          : "https://res.cloudinary.com/efer/image/upload/v1654520254/VivaDrive/Graphs/EVR_PL_aamns6.svg",
      imgMobile:
        language === "en"
          ? "https://res.cloudinary.com/efer/image/upload/v1651523925/VivaDrive/Graphs/EVRgraphMobile_ikikic.svg"
          : "https://res.cloudinary.com/efer/image/upload/v1656331684/VivaDrive/Graphs/Group_891_oahilj.png",
    },
    introPricing: {
      accent: green,
      smallTitle: <Trans i18nKey='description.pricing32' />,
      title: <Trans i18nKey='description.evrTitleEcosystem' />,
      text: <Trans i18nKey='description.pricing33' />,
      link: {
        title: "More about pricing",
        linkTo: "/",
      },
    },
    costs: [
      {
        cost: "-40%",
        title: "Less maintenance",
      },
      {
        cost: "-10%",
        title: "Lower insurance fees",
      },
      {
        cost: "-20%",
        title: "Cost of fuel to energy saving",
      },
    ],
    contactUs: {
      color: "var(--white)",
      smallTitle: <Trans i18nKey='description.getintouch' />,
      title: <Trans i18nKey='description.evrContactTitle' />,
      text: "",
    },
  },

  // {
  //   product: "IFlota",
  //   logo: "",
  //   mainColor: blue,
  //   lightColor: thirdBlue,
  //   squareLight: "#13CD83",
  //   squareDark: "#10B070",
  // },
  // {
  //   product: "Lafarge",
  //   logo: "",
  //   mainColor: LafargeGreen,
  //   lightColor: opaqueLafargeGreen,
  //   squareLight: "#13CD83",
  //   squareDark: "#10B070",
  // },
];

export default Themes;
