import Nav from "../../Components/Nav";
import { FeatureColored } from "../../Parts/PricingGrid/PricingGrid";
import Footer from "../../Parts/Footer/Footer";
import Form from "../../Components/Form/Form";
import { useEffect } from "react";
import { Trans } from "react-i18next";

const WorkWithUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "var(--lightGrey)" }}>
      <Nav />
      <div
        className='section reverseAngle'
        style={{ backgroundColor: "var(--backgroundGreenLight)" }}
      >
        <h1 style={{ padding: "1em 0" }}>
          <Trans i18nKey='description.stayInTouch' />
        </h1>
      </div>
      <div className='section WorkWithUsGrid'>
        {GreatPoints}
        <div className='form withUsForm'>
          <Form />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WorkWithUs;

const GreatPoints = (
  <div className='benefits'>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        margin: "2.5em 0",
      }}
    >
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <h3 style={{ margin: "0" }}>
        <Trans i18nKey='description.contact1' />
      </h3>
    </div>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        margin: "2.5em 0",
      }}
    >
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <h3 style={{ margin: "0" }}>
        <Trans i18nKey='description.contact2' />
      </h3>
    </div>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        margin: "2.5em 0",
      }}
    >
      <div style={{ marginRight: "1em" }}>
        <FeatureColored color='var(--primaryGreen)' />
      </div>
      <h3 style={{ margin: "0" }}>
        <Trans i18nKey='description.contact3' />
      </h3>
    </div>
  </div>
);
