import { PrimaryButton } from "../Buttons/Buttons";
import { handleForm, handleChange } from "../../Utils/HandleForm";
import { store } from "react-notifications-component";
import { useState } from "react";
const JoinTeamForm = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    company: "Position: ",
    message: "I want to work at vivadrive!",
  });

  return (
    <form action=''>
      <label htmlFor='name'>Name</label>
      <input
        type='text'
        required
        name='name'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='email'>Email</label>
      <input
        type='email'
        required
        name='email'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='company name'>Position</label>
      <input
        type='text'
        name='company'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <label htmlFor='message'>Any question?</label>
      <textarea
        name='textarea'
        onChange={(e) => handleChange(e, setForm, form)}
      />
      <center style={{ paddingTop: "2em" }}>
        <PrimaryButton
          text='Send'
          textColor='var(--black)'
          color='var(--primaryGreen)'
          onClick={(e) => handleForm(e, store, form)}
        />
      </center>
    </form>
  );
};

export default JoinTeamForm;
