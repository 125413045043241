import SectionIntro from "./SectionIntro";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import { Trans } from "react-i18next";

const ColumnsSection = ({ intro, columns, id }) => {
  return (
    <div
      className='columnSection'
      id={id}
      style={{ backgroundColor: "var(--white)" }}
    >
      <SectionIntro data={intro} />
      <ul>
        {columns.slice(0, 5).map((column, key) => (
          <li>
            <Fade bottom delay={80 * key} key={key}>
              <Column
                img={column.img}
                title={column.title}
                text={column.text}
                text1={column.text1}
                text2={column.text2}
                text3={column.text3}
                text4={column.text4}
                text5={column.text5}
                link={column.link}
                icon={column.icon}
                color={column.color}
                head={column.head}
              />
            </Fade>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Column = ({
  title,
  text,
  text1,
  text2,
  text3,
  text4,
  text5,
  img,
  link,
  icon,
  color,
  head,
}) => {
  return (
    <div>
      <a
        href={link}
        rel='noreferrer'
        target='_blank'
        style={{ position: "relative", height: "0px" }}
      >
        {/* {img && <img src={img} className="columnImg" style={{margin:"0"}} alt=""/>} */}
        <div className='productGrid' style={{ background: { color } }}>
          <div className='productContent'>
            <div style={{ margin: "1em 0" }}>
              <img className='productIcon' src={icon} />
            </div>
            <h1>{title}</h1>
            <h2>{text}</h2>
            <h5 style={{ margin: ".5em 0" }}>{text1}</h5>
            <h5 style={{ margin: ".5em 0" }}>{text2}</h5>
            <h5 style={{ margin: ".5em 0" }}>{text3}</h5>
            <h5 style={{ margin: ".5em 0" }}>{text4}</h5>
            <h5 style={{ margin: ".5em 0" }}>{text5}</h5>
            {/* <div className='buttonContainer'>
              <Link to={`/letswork/${head}`}>
                <PrimaryButton
                  text={<Trans i18nKey='description.learnButton' />}
                  noPadding
                  color={color}
                  textColor='var(--white)'
                  onClick={link}
                />
              </Link>
            </div> */}
            {/* <div className='buttonContainer'>
              <SecondaryButton
                text={<Trans i18nKey='description.learnButton' />}
                color={color}
                noPadding
                textColor={color}
                onClick={link}
              />
            </div> */}
          </div>
          <div className='img'>
            <img src={img} alt='' />
          </div>
        </div>
      </a>
    </div>
  );
};

export default ColumnsSection;
