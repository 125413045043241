const Mind = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g
        fill='none'
        fill-rule='nonzero'
        stroke='none'
        stroke-width='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        stroke-miterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,172v-172h172v172z' fill='none'></path>
        <path
          d='M43,172c-23.74824,0 -43,-19.25176 -43,-43v-86c0,-23.74824 19.25176,-43 43,-43h86c23.74824,0 43,19.25176 43,43v86c0,23.74824 -19.25176,43 -43,43z'
          fill='#10b070'
        ></path>
        <g fill='#ffffff'>
          <path d='M86,30.96c-10.922,0 -19.8144,9.8728 -19.8144,22.016c0,12.1432 8.8924,22.016 19.8144,22.016c10.9306,0 19.8144,-9.8728 19.8144,-22.016c0,-12.1432 -8.8838,-22.016 -19.8144,-22.016zM86,77.3312c-11.3778,0 -19.7456,1.4018 -28.0016,4.6096c-3.4142,1.3244 -5.504,4.6956 -7.912,8.6c-0.5504,0.8858 -1.1094,1.806 -1.72,2.752c-0.9202,1.4104 -1.9608,3.0186 -3.096,4.7472c-4.6698,7.1208 -10.4576,15.953 -12.4528,19.8144c-2.1328,4.1366 -2.2532,7.4562 -0.4128,11.008c1.462,2.838 3.5002,6.5446 6.0544,11.008l0.688,1.1696h13.8288v-2.2016c0,-15.8068 14.7834,-28.6208 33.024,-28.6208c18.2406,0 33.024,12.814 33.024,28.6208v2.2016h13.8288l0.688,-1.1696c2.537,-4.429 4.5666,-8.1356 6.0544,-11.008c1.8404,-3.5518 1.72,-6.8714 -0.4128,-11.008c-1.419,-2.7434 -4.8332,-7.9292 -8.4624,-13.4848c-2.623,-4.0248 -5.3406,-8.1872 -7.0176,-11.008l-0.9632,-1.5824c-2.6746,-4.558 -4.9278,-8.5398 -8.7376,-9.9072c-7.6024,-2.7348 -15.308,-4.5408 -28.0016,-4.5408zM86,119.024c-13.3558,0 -24.2176,8.9354 -24.2176,19.8144v2.2016h48.4352v-2.2016c0,-10.879 -10.8704,-19.8144 -24.2176,-19.8144z'></path>
        </g>
      </g>
    </svg>
  );
};

export default Mind;
