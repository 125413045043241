import { Trans } from "react-i18next";
import {
  PrimaryButton
} from "../../Components/Buttons/Buttons";
const MediaAssets = () => {
  return (
    <div className='mediaAssetContainer'>
      <h2>
        <Trans i18nKey='description.pressKit' />
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <a href='https://app.air.inc/a/bwv99q1PX' target='_blank'>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "1em 0",
              border: "0px",
              borderRadius: "var(--radius)",
            }}
          >
            <PrimaryButton
              style={{ margin: "1em 0 0 0"}}
              text=<Trans i18nKey='description.getLogos' />
              noPadding
              color='var(--primaryGreen)'
              textColor='white'
            />
          </div>
        </a>
      </div>
      <p style={{ margin: "1.5em 0", width: "100%" }}>
        <b>
          <Trans i18nKey='description.pressJob' />
        </b>
      </p>
      <p>
        <b>
          <Trans i18nKey='description.contactPerson' />
        </b>
      </p>
      <p>Fabian Rządkowski</p>
      <p style={{ color: "var(--black)" }}>fabian@vivadrive.io</p>
    </div>
  );
};
export default MediaAssets;
