const SectionIntro = ({data, id}) =>{

const {color, accent, text, smallTitle, title} = {...data}

        return <div id={id} className="sectionIntro" style={{color}} >
                    <h4 className="smallTitle" style={{color:accent, fontSize:"1.5em"}}>{smallTitle}</h4>
                    <h2 style={{ fontSize:"2.8em"}}>{title}</h2>
                    {text ? 
                    <p style={{color, marginBottom:"1em"}}>{text}</p>
                    : null}
               </div>
}

export default SectionIntro