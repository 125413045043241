import SectionIntro from "./SectionIntro";
import Fade from "react-reveal/Fade";
import Column from "../../Components/Column";

const ColumnsSection = ({ intro, columns, id, color, textColor }) => {
  return (
    <div className='section' id={id} style={{ backgroundColor: color }}>
      <SectionIntro data={intro} />
      <div className='benefitsColumns'>
        {columns.map((column, key) => (
          <Fade bottom delay={80 * key} key={key}>
            <Column
              textColor={column.textColor}
              icon={column.icon}
              type={column.type}
              img={column.img}
              title={column.title}
              text={column.text}
            />
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default ColumnsSection;
