import LandingBar from "../../Components/LandingBar";
import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";
import { Trans } from "react-i18next";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import React from "react";

const accentColor = "var(--primaryGreen)";

const introBlog = {
  accent: accentColor,
  smallTitle: <Trans i18nKey='description.smallTitleBlog' />,
  title: <Trans i18nKey='description.titleBlog' />,
  text: <Trans i18nKey='description.textBlog' />,
};

const introMedia = {
  accent: accentColor,
  color: accentColor,
  title: <Trans i18nKey='description.recentMedia' />,
};

const BlogPage = () => {
  const [all, setAll] = useState(false);
  const location = useLocation;

  return (
    <>
      <Nav />
      <LandingBar
        image={
          "https://res.cloudinary.com/efer/image/upload/v1656329055/VivaDrive/Pictures/vanilla-bear-films-JEwNQerg3Hs-unsplash_lwu5uy-min_iwm5ii-min_agiqrz.jpg"
        }
        textBig={<Trans i18nKey='description.mediaBig' />}
        textSmall={<Trans i18nKey='description.mediaSmall' />}
      />
      <section className='section' intro={introBlog}>
        <div id='dib_categories'></div>
      </section>
      <Footer />
    </>
  );
};

export default BlogPage;
