const IconTypes = {
    ARROW: "arrow",
    CAR_CHARGE:"CarCharge",
    CAR_MONEY:"CarMoney",
    CAR:"Car",
    MIND: "Mind",
    MONEY_GRAPH:"MoneyGraph",
    CAR_WITH_PLUG:"CarWithPlug",
    CHARGER:"Charger",
    DESKTOP:"Desktop",
    FILE:"File",
    CONTROLLER:"Controller",
    PLANET:"Planet",
    CLOCK:"Clock",
    // CAR:"Car",
    DIGITALFLEET:"DigitalFleet",
    EVRLOGO: "EVRLogo",
    MAIL: "Mail",
    MODERN: "Modern",
    PERSON: "Person",
    LEAF: "Leaf",
    CIRCLE: "Circle",
    AI: "Ai",
    STUDENT: "Student",
    BULB: "Bulb",
    ABOUT: "About",
    CAREER: "Career",
    MEDIA: "Media"
}
Object.freeze(IconTypes)

export default IconTypes