export default class Member {
    constructor(intern,img, name, role, nationality, socials ){
       this.intern = intern
       this.name = name
       this.img = img
       this.role = role
       this.nationality = nationality
       this.socials = socials
    }
    createMember (){
       return {
          intern: this.intern,
          name: this.name,
          img: this.img,
          role: this.role,
          nationality: this.nationality,
          socials: this.socials
       }
    }
 }