import { PrimaryButton } from "../Buttons/Buttons";
import { handleForm, handleChange } from "../../Utils/HandleForm";
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation, Trans } from "react-i18next";
import Model from "./Model";
import { t } from "i18next";
import axios from "axios";
import Swal from "sweetalert2";
import "./Form.css";

const Form = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    email_id: "",
    phone_number: "",
    company_name: "",
    project: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [error, setError] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    setForm({
      name: "",
      email_id: "",
      phone_number: "",
      company_name: "",
      project: "",
      message: "",
    });
    window.location.href = "/";
  };

  const language = window.localStorage.getItem("i18nextLng")
  const handler = (e) => {
    if (e.target.value !== "default") {
      const val = e.target.value.split(" ")[0].toLowerCase();
    }
    handleChange(e, setForm, form);
  };

  const onChange = (value) => {
    if (value) {
      setRecaptchaVerified(true);
      setError(false);
    } else {
      setRecaptchaVerified(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.name) newErrors.name = "Name is required";
    if (!form.phone_number) newErrors.phone_number = "Phone number is required";
    if (!form.email_id) newErrors.email_id = "Email is required";
    if (!form.company_name) newErrors.company_name = "Company name is required";
    if (!form.project) newErrors.project = "Project is required";
    if (!form.message) newErrors.message = "Message is required";
    if (!recaptchaVerified) newErrors.recaptcha = "Please verify reCAPTCHA";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const captchaNForm = (e, store, form) => {
    e.preventDefault();
    if (!validateForm()) return;

    axios({
      method: "POST",
      url: "https://digitalfleet.eu/api/1/tos/contact/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: form,
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log("error", error?.response);
        store.addNotification({
          title: "Submission Failed",
          message: "There was an issue submitting your form. Please try again.",
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  return (
    <>
      <form onSubmit={(e) => captchaNForm(e, store, form)}>
        <label htmlFor="name">{t("description.name")}</label>
        <input
          type="text"
          required
          name="name"
          onChange={(e) => handleChange(e, setForm, form)}
          className={errors.name ? "error" : ""}
        />
        {errors.name && <div className="error-message1">{errors.name}</div>}

        <label htmlFor="phone">
          <Trans i18nKey="description.phone" />
        </label>
        <input
          type="phone"
          required
          name="phone_number"
          onChange={(e) => handleChange(e, setForm, form)}
          className={errors.phone_number ? "error" : ""}
        />
        {errors.phone_number && (
          <div className="error-message1">{errors.phone_number}</div>
        )}

        <label htmlFor="email">
          <Trans i18nKey="description.email" />
        </label>
        <input
          type="email"
          required
          name="email_id"
          onChange={(e) => handleChange(e, setForm, form)}
          className={errors.email_id ? "error" : ""}
        />
        {errors.email_id && (
          <div className="error-message1">{errors.email_id}</div>
        )}

        <label htmlFor="company">
          <Trans i18nKey="description.company" />
        </label>
        <input
          type="text"
          required
          name="company_name"
          onChange={(e) => handleChange(e, setForm, form)}
          className={errors.company_name ? "error" : ""}
        />
        {errors.company_name && (
          <div className="error-message1">{errors.company_name}</div>
        )}

        <label htmlFor="project">{t("description.contact_reson")}</label>
        <select
          required
          name="project"
          onChange={(e) => handler(e)}
          className={errors.project ? "error" : ""}
        >
          <option value="default">{""}</option>
          <option value={t("description.form1")}>
            {t("description.form1")}
          </option>
          <option value={t("description.form2")}>
            {t("description.form2")}
          </option>
          <option value={t("description.form3")}>
            {t("description.form3")}
          </option>
          <option value={t("description.form4")}>
            {t("description.form4")}
          </option>
          <option value={t("description.form5")}>
            {t("description.form5")}
          </option>
        </select>
        {errors.project && (
          <div className="error-message1">{errors.project}</div>
        )}

        <label htmlFor="message">{t("description.form6")}</label>
        <textarea
          name="message"
          required
          onChange={(e) => handleChange(e, setForm, form)}
          className={errors.message ? "error" : ""}
        />
        {errors.textarea && (
          <div className="error-message1">{errors.textarea}</div>
        )}

        <div
          className={`recaptcha-container ${errors.recaptcha ? "error" : ""}`}
        >
          <ReCAPTCHA
            sitekey="6LemYDUbAAAAALQlr_DqeLeod97-5ipDDdUL3TCR"
            onChange={onChange}
          />
          {errors.recaptcha && (
            <div className="error-message">{errors.recaptcha}</div>
          )}
        </div>

        <center style={{ paddingTop: "2em" }}>
          <PrimaryButton
            text={<Trans i18nKey="description.send" />}
            textColor="var(--white)"
            color="var(--primaryGreen)"
            type="submit"
          />
        </center>
      </form>
      {showModal && (
        <Model show={showModal} onClose={closeModal}>
          <h4
            style={{ marginLeft: language=== "pl"? "110px": "50px", position: "relative", top: "-12px" }}
          >
            {t("description.model_text1")}
          </h4>
          <p>{t("description.model_text")}</p>
        </Model>
      )}
    </>
  );
};

export default Form;
