import SectionIntro from "./SectionIntro";
// import Fade from "react-reveal/Fade";
import { Fade } from "react-awesome-reveal";
import ColumnAdvantages from "../../Components/ColumnAdvantages";
import { Trans } from "react-i18next";


const Advantages = ({ intro, columns, id, color, textColor }) => {
  return (
    <div className='section' id={id} style={{ backgroundColor: color }}>
      <div style={{ textAlign:"center"}}>
        <h2 className='advantagesText'>
          <Trans i18nKey='description.advantagesTitle' />
        </h2>
      </div>
      <div className='benefitsColumns'>
        {columns.map((column, key) => (
          <Fade bottom delay={80 * key} key={key}>
            <ColumnAdvantages
              textColor={column.textColor}
              icon={column.icon}
              type={column.type}
              img={column.img}
              title={column.title}
              text={column.text}
            />
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default Advantages;
