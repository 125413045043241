const Planet = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='85'
      height='85'
      viewBox='0 0 172 172'
    >
      <g transform='translate(0.516,0.516) scale(0.994,0.994)'>
        <g
          fill='none'
          fill-rule='nonzero'
          stroke='none'
          stroke-width='1'
          stroke-linecap='butt'
          stroke-linejoin='none'
          stroke-miterlimit='10'
          stroke-dasharray=''
          stroke-dashoffset='0'
          font-family='none'
          font-weight='none'
          font-size='none'
          text-anchor='none'
        >
          <g stroke='var(--secondaryGreen)' stroke-linejoin='round'>
            <path
              d='M34.82527,86.10207c0,-27.95194 22.65953,-50.61147 50.61147,-50.61147c27.95194,0 50.61147,22.65953 50.61147,50.61147c0,27.95194 -22.65953,50.61147 -50.61147,50.61147c-27.95194,0 -50.61147,-22.65953 -50.61147,-50.61147z'
              fill='#cce4dc'
            ></path>
            <path
              d='M65.17477,104.49851c0,0 -13.69007,-15.2513 -1.59147,-35.80624c10.47499,-17.80295 31.62721,-7.77213 46.02229,-22.01222c0,0 18.27358,62.8367 -28.31574,64.30343'
              fill='#cce4dc'
            ></path>
            <path
              d='M81.28796,114.38c-1.82774,0 -3.33604,-1.45349 -3.39464,-3.29257c-0.05859,-1.87499 1.4138,-3.44 3.29068,-3.50048c10.00624,-0.31376 17.46084,-3.7462 22.1502,-10.20281c9.38818,-12.92457 6.2884,-34.79125 4.22818,-44.65763c-6.39424,4.28677 -13.49727,5.51723 -19.90664,6.6324c-8.74554,1.51587 -16.29653,2.82571 -21.13899,11.05903c-10.63187,18.05811 0.71068,31.27376 1.19833,31.82567c1.23613,1.40246 1.11138,3.54774 -0.28541,4.79143c-1.40057,1.24558 -3.53451,1.1284 -4.78198,-0.26084c-0.15499,-0.17389 -15.20026,-17.36633 -1.9884,-39.80382c6.43582,-10.94374 16.29842,-12.65429 25.83402,-14.30813c7.63226,-1.32308 14.83925,-2.57433 20.72505,-8.39587c0.85055,-0.84299 2.08479,-1.17565 3.24343,-0.87323c1.15864,0.30053 2.07534,1.19077 2.408,2.33996c0.42527,1.45916 10.23684,35.99147 -4.03916,55.64484c-5.98787,8.24277 -15.21916,12.62026 -27.43305,13.00396c-0.0378,-0.00189 -0.07371,-0.00189 -0.10963,-0.00189z'
              fill='var(--secondaryGreen)'
            ></path>
            <path
              d='M57.41398,131.89565c-0.14176,0 -0.2873,-0.01134 -0.43284,-0.02835c-1.85987,-0.23626 -3.18105,-1.93358 -2.94479,-3.79912c3.94844,-31.17547 36.42809,-56.56154 37.80976,-57.62756c1.48563,-1.1473 3.61767,-0.87134 4.76497,0.6124c1.1473,1.48563 0.87512,3.61956 -0.60862,4.76497c-0.31754,0.2476 -31.63099,24.75477 -35.2222,53.10642c-0.21736,1.71622 -1.67842,2.97125 -3.36629,2.97125z'
              fill='var(--secondaryGreen)'
            ></path>
            <g fill='var(--secondaryGreen)'>
              <path d='M58.81266,151.57736c-0.44418,0 -0.89591,-0.08884 -1.33064,-0.27407c-28.95459,-12.33108 -46.14136,-41.65802 -42.76563,-72.98092c2.03187,-18.86897 11.28963,-35.81569 26.07029,-47.72149c14.78066,-11.90391 33.31319,-17.34365 52.17837,-15.30611c1.86365,0.20035 3.21319,1.87688 3.01473,3.74242c-0.20035,1.86554 -1.88255,3.2302 -3.74242,3.01284c-17.0658,-1.83908 -33.82163,3.07899 -47.18848,13.84316c-13.36497,10.76607 -21.73815,26.09297 -23.57723,43.15688c-3.04875,28.32708 12.49174,54.85099 38.67354,66.00075c1.72756,0.73714 2.53086,2.73121 1.79371,4.45877c-0.54813,1.29095 -1.80505,2.06778 -3.12624,2.06778z'></path>
              <path d='M85.40462,156.98875c-2.53464,0 -5.08629,-0.13609 -7.66251,-0.41582c-1.86365,-0.19846 -3.21508,-1.87499 -3.01284,-3.74053c0.19846,-1.86554 1.87688,-3.22264 3.74053,-3.01662c35.21275,3.78967 66.97226,-21.77596 70.7676,-56.99815c3.03363,-28.19288 -13.12303,-55.4407 -39.29349,-66.26536c-1.73323,-0.71635 -2.55921,-2.70475 -1.84286,-4.43987c0.71824,-1.73323 2.70286,-2.55921 4.44176,-1.84097c28.93947,11.97385 46.80857,42.1022 43.45174,73.27389c-3.92009,36.37705 -34.8007,63.43965 -70.58993,63.44343z'></path>
              <path d='M77.70998,34.24879c-0.9942,0 -1.97895,-0.43284 -2.64804,-1.26637c-1.17754,-1.46105 -0.94695,-3.60066 0.51411,-4.7782l12.47095,-10.04404l-10.04215,-12.47284c-1.17754,-1.46105 -0.94695,-3.60066 0.51411,-4.7782c1.46295,-1.17754 3.60066,-0.94505 4.7782,0.516l12.17609,15.11899c0.56325,0.70123 0.82787,1.59903 0.72958,2.49495c-0.09451,0.89591 -0.54435,1.71622 -1.24558,2.28136l-15.12088,12.17609c-0.62752,0.50655 -1.37978,0.75226 -2.12637,0.75226z'></path>
              <path d='M90.05807,171.7127c-0.99231,0 -1.97516,-0.43473 -2.64804,-1.26826l-12.17987,-15.1171c-0.56325,-0.69934 -0.82787,-1.59903 -0.72958,-2.49305c0.0964,-0.89969 0.54435,-1.72 1.24558,-2.28325l15.12277,-12.17609c1.46484,-1.18321 3.60444,-0.94695 4.77631,0.51411c1.17754,1.45727 0.94884,3.60255 -0.51222,4.77631l-12.47662,10.04782l10.04782,12.46527c1.17754,1.46105 0.94695,3.60255 -0.516,4.78009c-0.62752,0.50655 -1.37978,0.75415 -2.13015,0.75415z'></path>
            </g>
          </g>
          <path
            d='M0,172v-172h172v172z'
            fill='none'
            stroke='none'
            stroke-linejoin='miter'
          ></path>
          <g stroke='none' stroke-linejoin='miter'>
            <circle
              cx='45.202'
              cy='45.554'
              transform='scale(1.89011,1.89011)'
              r='26.777'
              fill='#cce4dc'
            ></circle>
            <path
              d='M65.17477,104.49851c0,0 -13.69007,-15.2513 -1.59147,-35.80624c10.47499,-17.80295 31.62721,-7.77213 46.02229,-22.01222c0,0 18.27358,62.8367 -28.31574,64.30343'
              fill='#cce4dc'
            ></path>
            <path
              d='M81.28796,114.38c-1.82774,0 -3.33604,-1.45349 -3.39464,-3.29257c-0.05859,-1.87499 1.4138,-3.44 3.29068,-3.50048c10.00624,-0.31376 17.46084,-3.7462 22.1502,-10.20281c9.38818,-12.92457 6.2884,-34.79125 4.22818,-44.65763c-6.39424,4.28677 -13.49727,5.51723 -19.90664,6.6324c-8.74554,1.51587 -16.29653,2.82571 -21.13899,11.05903c-10.63187,18.05811 0.71068,31.27376 1.19833,31.82567c1.23613,1.40246 1.11138,3.54774 -0.28541,4.79143c-1.40057,1.24558 -3.53451,1.1284 -4.78198,-0.26084c-0.15499,-0.17389 -15.20026,-17.36633 -1.9884,-39.80382c6.43582,-10.94374 16.29842,-12.65429 25.83402,-14.30813c7.63226,-1.32308 14.83925,-2.57433 20.72505,-8.39587c0.85055,-0.84299 2.08479,-1.17565 3.24343,-0.87323c1.15864,0.30053 2.07534,1.19077 2.408,2.33996c0.42527,1.45916 10.23684,35.99147 -4.03916,55.64484c-5.98787,8.24277 -15.21916,12.62026 -27.43305,13.00396c-0.0378,-0.00189 -0.07371,-0.00189 -0.10963,-0.00189z'
              fill='var(--secondaryGreen)'
            ></path>
            <path
              d='M57.41398,131.89565c-0.14176,0 -0.2873,-0.01134 -0.43284,-0.02835c-1.85987,-0.23626 -3.18105,-1.93358 -2.94479,-3.79912c3.94844,-31.17547 36.42809,-56.56154 37.80976,-57.62756c1.48563,-1.1473 3.61767,-0.87134 4.76497,0.6124c1.1473,1.48563 0.87512,3.61956 -0.60862,4.76497c-0.31754,0.2476 -31.63099,24.75477 -35.2222,53.10642c-0.21736,1.71622 -1.67842,2.97125 -3.36629,2.97125z'
              fill='var(--secondaryGreen)'
            ></path>
            <g fill='var(--secondaryGreen)'>
              <path d='M58.81266,151.57736c-0.44418,0 -0.89591,-0.08884 -1.33064,-0.27407c-28.95459,-12.33108 -46.14136,-41.65802 -42.76563,-72.98092c2.03187,-18.86897 11.28963,-35.81569 26.07029,-47.72149c14.78066,-11.90391 33.31319,-17.34365 52.17837,-15.30611c1.86365,0.20035 3.21319,1.87688 3.01473,3.74242c-0.20035,1.86554 -1.88255,3.2302 -3.74242,3.01284c-17.0658,-1.83908 -33.82163,3.07899 -47.18848,13.84316c-13.36497,10.76607 -21.73815,26.09297 -23.57723,43.15688c-3.04875,28.32708 12.49174,54.85099 38.67354,66.00075c1.72756,0.73714 2.53086,2.73121 1.79371,4.45877c-0.54813,1.29095 -1.80505,2.06778 -3.12624,2.06778z'></path>
              <path d='M85.40462,156.98875c-2.53464,0 -5.08629,-0.13609 -7.66251,-0.41582c-1.86365,-0.19846 -3.21508,-1.87499 -3.01284,-3.74053c0.19846,-1.86554 1.87688,-3.22264 3.74053,-3.01662c35.21275,3.78967 66.97226,-21.77596 70.7676,-56.99815c3.03363,-28.19288 -13.12303,-55.4407 -39.29349,-66.26536c-1.73323,-0.71635 -2.55921,-2.70475 -1.84286,-4.43987c0.71824,-1.73323 2.70286,-2.55921 4.44176,-1.84097c28.93947,11.97385 46.80857,42.1022 43.45174,73.27389c-3.92009,36.37705 -34.8007,63.43965 -70.58993,63.44343z'></path>
              <path d='M77.70998,34.24879c-0.9942,0 -1.97895,-0.43284 -2.64804,-1.26637c-1.17754,-1.46105 -0.94695,-3.60066 0.51411,-4.7782l12.47095,-10.04404l-10.04215,-12.47284c-1.17754,-1.46105 -0.94695,-3.60066 0.51411,-4.7782c1.46295,-1.17754 3.60066,-0.94505 4.7782,0.516l12.17609,15.11899c0.56325,0.70123 0.82787,1.59903 0.72958,2.49495c-0.09451,0.89591 -0.54435,1.71622 -1.24558,2.28136l-15.12088,12.17609c-0.62752,0.50655 -1.37978,0.75226 -2.12637,0.75226z'></path>
              <path d='M90.05807,171.7127c-0.99231,0 -1.97516,-0.43473 -2.64804,-1.26826l-12.17987,-15.1171c-0.56325,-0.69934 -0.82787,-1.59903 -0.72958,-2.49305c0.0964,-0.89969 0.54435,-1.72 1.24558,-2.28325l15.12277,-12.17609c1.46484,-1.18321 3.60444,-0.94695 4.77631,0.51411c1.17754,1.45727 0.94884,3.60255 -0.51222,4.77631l-12.47662,10.04782l10.04782,12.46527c1.17754,1.46105 0.94695,3.60255 -0.516,4.78009c-0.62752,0.50655 -1.37978,0.75415 -2.13015,0.75415z'></path>
            </g>
          </g>
          <path d='' fill='none' stroke='none' stroke-linejoin='miter'></path>
          <path d='' fill='none' stroke='none' stroke-linejoin='miter'></path>
          <path d='' fill='none' stroke='none' stroke-linejoin='miter'></path>
          <path d='' fill='none' stroke='none' stroke-linejoin='miter'></path>
        </g>
      </g>
    </svg>
  );
};

export default Planet;
