import Nav from "../../Components/Nav";
import Footer from "../../Parts/Footer/Footer";

const SinglePost = () => {
  return (
    <>
      <Nav />
        <div id='dib-posts'></div>
      <Footer />
    </>
  );
};
export default SinglePost;
