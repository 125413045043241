import Nav from "../../Components/Nav";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import WidgetCards from "../../Components/Cards/WidgetCards";
import "./Career.css";
import Footer from "../../Parts/Footer/Footer";
import jobsObj from "../../HelperObjects/Jobs";
import ReactMarkdown from "react-markdown";
import LandingBar from "../../Components/LandingBar";
import gfm from "remark-gfm";
import { Trans } from "react-i18next";

export default function Career() {
  return (
    <div>
      <Nav />
      <LandingBar
        image={
          "https://res.cloudinary.com/efer/image/upload/v1684392988/cherrydeck-rMILC1PIwM0-unsplash_sue8xa.jpg"
        }
        textBig={<Trans i18nKey='description.careerBig' />}
        textSmall={<Trans i18nKey='description.careerSmall' />}
      />
      <section className='section' style={{ backgroundColor: "var(--white)" }}>
        <h1>
          <Trans i18nKey='description.careerPerks' />
        </h1>
        <h3>
          <Trans i18nKey='description.careerText' />
        </h3>
        <div className='reasonsWhyGrid'>
          {Perks.map((item, key) => {
            return (
              <WidgetCards item={item} key={key} background='var(--white)' />
            );
          })}
        </div>
        <div style={{ margin: "3em 0 5em 0" }}>
          <Link to='/apply'>
            <PrimaryButton
              text={<Trans i18nKey='description.apply' />}
              noPadding
              color='var(--primaryGreen)'
              textColor='var(--white)'
            />
          </Link>
        </div>
      </section>

      <section
        className=' section AngleTop'
        id='openPositions'
        style={{ backgroundColor: "var(--white)", paddingTop: "9em" }}
      >
        <h1 style={{ marginBottom: "1em" }}>
          <Trans i18nKey='description.careerPositions' />
        </h1>
        {jobsObj.map((item, key) => {
          const desc = item.description.substring(0, 80) + "...";
          console.log(desc);
          return (
            <div className='jobItem'>
              <p style={{ fontWeight: "700" }}> {item.position}</p>
              <div className='separator'></div>
              <div>
                <ReactMarkdown plugins={[gfm]} children={desc} />
              </div>

              <div className='separator'></div>
              <Link
                to={`apply/${item.slug}`}
                style={{ color: "var(--primaryGreen)", fontWeight: "700" }}
              >
                <Trans i18nKey='description.apply' />
              </Link>
            </div>
          );
        })}
      </section>

      <Footer />
    </div>
  );
}

const Perks = [
  {
    title: <Trans i18nKey='description.perk1Title' />,
    text: <Trans i18nKey='description.perk1Text' />,
  },
  {
    title: <Trans i18nKey='description.perk2Title' />,
    text: <Trans i18nKey='description.perk2Text' />,
  },
  {
    title: <Trans i18nKey='description.perk3Title' />,
    text: <Trans i18nKey='description.perk3Text' />,
  },
  {
    title: <Trans i18nKey='description.perk4Title' />,
    text: <Trans i18nKey='description.perk4Text' />,
  },
  {
    title: <Trans i18nKey='description.perk5Title' />,
    text: <Trans i18nKey='description.perk5Text' />,
  },
  {
    title: <Trans i18nKey='description.perk6Title' />,
    text: <Trans i18nKey='description.perk6Text' />,
  },
  {
    title: <Trans i18nKey='description.perk7Title' />,
    text: <Trans i18nKey='description.perk7Text' />,
  },
  {
    title: <Trans i18nKey='description.perk8Title' />,
    text: <Trans i18nKey='description.perk8Text' />,
  },
];
