import React, { useState } from "react";
import Nav from "../../Components/Nav";
import PrivacyPolicyPolish from "./PrivacyDataPolish";
import PrivacyPolicyEnglish from "./PrivacyData";
import "./Privacy.css";

function Privacy() {
  const [language, SetLangegue] = useState("polish");
  const CurrentPolicy =
    language === "polish" ? PrivacyPolicyPolish : PrivacyPolicyEnglish;
  return (
    <div>
      <Nav />
      <div className='policy_Section'>
        <div className='policy_button'>
          <div>
            <button onClick={() => SetLangegue("english")}>English</button>
          </div>
          <div>
            <button onClick={() => SetLangegue("polish")}>Polish</button>
          </div>
        </div>
        <div className='policy_header'>
          <h2>{CurrentPolicy.MainTitle} </h2>
        </div>
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.title} </h4>
            <p className='main_text'>{CurrentPolicy.mainText} </p>
            <h6 className='list_text'>{CurrentPolicy.listTitle} </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${c.id}. &nbsp; ${c.text}`,
                    }}
                  ></p>
                  {c.subtext &&
                    c.subtext.map((c, i) => (
                      <li className='extra_list'>
                        <p>
                          {c.id}. &nbsp; {c.text}
                        </p>
                      </li>
                    ))}
                  {c.email ? <a href={`mailto:${c.email}`}>{c.email}</a> : ""}{" "}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* PersonaDataCollector */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>
              {CurrentPolicy.personalDataCollector.title}{" "}
            </h4>
            <p className='main_text'>
              {CurrentPolicy.personalDataCollector.text}{" "}
            </p>
          </div>
        </div>

        {/* cookies */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.cookies.title} </h4>
            <p className='main_text'>{CurrentPolicy.cookies.text} </p>
            <h6 className='list_text'>{CurrentPolicy.cookies.listText} </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.cookies.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
            {CurrentPolicy.cookies.endText.map((c, i) => (
              <p className='main_text' key={i + 1}>
                {c}{" "}
              </p>
            ))}
          </div>
        </div>

        {/* PersonalData */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.PersonalData.title} </h4>
            <p className='main_text'>{CurrentPolicy.PersonalData.text} </p>
          </div>
        </div>

        {/* purpose */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.purpose.title} </h4>

            <h6 className='list_text'>{CurrentPolicy.purpose.listText} </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.cookies.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Data processing */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.dataProcessing.title} </h4>
            <p className='main_text'>{CurrentPolicy.dataProcessing.text} </p>
            <h6 className='list_text'>
              {CurrentPolicy.dataProcessing.listText}{" "}
            </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.dataProcessing.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
            <p className='main_text'>{CurrentPolicy.dataProcessing.endText}</p>
          </div>
        </div>

        {/* Legal Basisc */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.legalBasis.title} </h4>
            <h6 className='list_text'>{CurrentPolicy.legalBasis.listText} </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.legalBasis.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* provindgPersonalData */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>
              {CurrentPolicy.provindgPersonalData.title}{" "}
            </h4>
            <p className='main_text'>
              {CurrentPolicy.provindgPersonalData.text}{" "}
            </p>
          </div>
        </div>

        {/* User right */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.userRight.title} </h4>
            <h6 className='list_text'>{CurrentPolicy.userRight.listText} </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.userRight.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
            <p className='main_text'>{CurrentPolicy.userRight.endText}</p>
          </div>
        </div>

        {/* dataRecipients */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>{CurrentPolicy.dataRecipients.title} </h4>
            <h6 className='list_text'>
              {CurrentPolicy.dataRecipients.listText}
            </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.dataRecipients.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
            {CurrentPolicy.dataRecipients.endText.map((c, i) => (
              <p className='main_text' key={i + 1}>
                {c}{" "}
              </p>
            ))}
          </div>
        </div>

        {/* dataProcessingPeriod */}
        <div className='policy_section'>
          <div className='section_start'>
            <h4 className='title'>
              {CurrentPolicy.dataProcessingPeriod.title}
            </h4>
            <h6 className='list_text'>
              {CurrentPolicy.dataProcessingPeriod.listText}{" "}
            </h6>
            <ul className='privacy_list'>
              {CurrentPolicy.dataProcessingPeriod.list.map((c, i) => (
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${i + 1}. &nbsp; ${c}`,
                    }}
                  ></p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className='version'>
          <p>
            {CurrentPolicy.verson_name} {CurrentPolicy.version}
          </p>
          <p>
            {CurrentPolicy.place} {CurrentPolicy.date}
          </p>
        </div>
        <div className='Address'>
          <p>
            VivaDrive Polska sp.z.o.o. ul. Dobra 56/66, 00-312 Warszawa, Poland
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
